import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Layouts from "../../layouts/layouts";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEnvelope,
  faCopy,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

export default function VehicleHistory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [plateNumber, setPlateNumber] = useState("");
  const formatTime = (dateString) => {
    if (!dateString) {
      return "N/A";
    }
    const formattedTime = moment(dateString).format("HH:mm:ss");
    return formattedTime;
  };

  const fetchHistory = async (from, to) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const params = {};
      if (from) params.from = moment(from).format("YYYY-MM-DD");
      if (to) params.to = moment(to).format("YYYY-MM-DD");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicleHistory/vehicle/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      );

      const vehicleHistory = response.data.vehicleHistory;
      const plateNumber =
        response.data.vehicleHistory?.vehicleNumber || "Number Plate";
      setPlateNumber(plateNumber);

      // Filter the data based on fromDate and toDate
      const filteredHistory = vehicleHistory.filter((record) => {
        const recordDate = moment(record.lastDate);
        if (from && to) {
          return recordDate.isBetween(moment(from), moment(to), "days", "[]");
        } else if (from) {
          return recordDate.isSameOrAfter(moment(from), "day");
        } else if (to) {
          return recordDate.isSameOrBefore(moment(to), "day");
        }
        return true;
      });

      // Group the data by date and find the most recent record for each date
      const groupedByDate = filteredHistory.reduce((acc, record) => {
        const date = moment(record.lastDate).format("YYYY-MM-DD");
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(record);
        return acc;
      }, {});

      const latestRecords = Object.keys(groupedByDate).map((date) => {
        const records = groupedByDate[date];
        return records.reduce((latest, current) =>
          new Date(latest.updatedAt) > new Date(current.updatedAt)
            ? latest
            : current
        );
      });

      setHistory(latestRecords);
    } catch (error) {
      console.error("Error fetching vehicle history: ", error);
      setHistory([]);
    }
  };

  useEffect(() => {
    fetchHistory(fromDate, toDate);
  }, [id, fromDate, toDate]);

  const copyLink = () => {
    alert(46);
  };

  const columns = [
    {
      name: <b>Date</b>,
      selector: (row) => moment(row.lastDate).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>Location</b>,
      selector: (row) => row.location || "N/A",
      sortable: true,
    },
    {
      name: <b>Total Km</b>,
      selector: (row) => row.distanceTravel || "N/A",
      sortable: true,
    },
    {
      name: <b>Speed</b>,
      selector: (row) => row.speed || "N/A",
      sortable: true,
    },
    {
      name: <b>Vehicle Status</b>,
      selector: (row) => row.vehicleStatus || "N/A",
      sortable: true,
    },
    {
      name: <b>Updated At</b>,
      selector: (row) => row.updatedAt && formatTime(row.updatedAt),
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            className="btn btn-info btn-sm ms-1"
            onClick={() => {
              navigate("/mymapcomponent", {
                state: { location: { lat: row.latitude, lng: row.longitude } },
              });
            }}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                {/* <h2>Vehicle History</h2> */}
                {plateNumber ? `${plateNumber}` : "Vehicle History"}
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <label>From Date:</label>
                    <input
                      type="date"
                      value={
                        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
                      }
                      onChange={(e) => setFromDate(e.target.value)}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                  <div>
                    <label>To Date:</label>
                    <input
                      type="date"
                      value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
                      onChange={(e) => setToDate(e.target.value)}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={history}
                  pagination
                  fixedHeader
                  highlightOnHover
                  responsive
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
