// import React, { useState, useCallback } from "react";
// import { GoogleMap, LoadScript, Marker, Autocomplete } from "@react-google-maps/api";
// import Modal from "react-modal";

// const containerStyle = {
//   width: "100%",
//   height: "400px",
// };

// function MapPicker({ isOpen, onClose, onSelect, center }) {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [autocomplete, setAutocomplete] = useState(null);

//   const onLoad = (autoComp) => {
//     setAutocomplete(autoComp);
//   };

//   const onPlaceChanged = () => {
//     if (autocomplete !== null) {
//       const place = autocomplete.getPlace();
//       const lat = place.geometry.location.lat();
//       const lng = place.geometry.location.lng();
//       setMarkerPosition({ lat, lng });
//       onSelect({ lat, lng });
//     } else {
//       console.log("Autocomplete is not loaded yet!");
//     }
//   };

//   const onMapClick = useCallback(
//     (event) => {
//       const lat = event.latLng.lat();
//       const lng = event.latLng.lng();
//       setMarkerPosition({ lat, lng });
//       onSelect({ lat, lng });
//     },
//     [onSelect]
//   );

//   return (
//     <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Select Location">
//       <div style={{ width: "100%", height: "400px" }}>
//         <LoadScript googleMapsApiKey="AIzaSyAFD1eBkueEp2PcrbPLNJs-isxY8w-EUH0" libraries={["places"]}>
//           <GoogleMap
//             mapContainerStyle={containerStyle}
//             center={center}
//             zoom={10}
//             onClick={onMapClick}
//           >
//             <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
//               <input
//                 type="text"
//                 placeholder="Search places..."
//                 style={{
//                   boxSizing: `border-box`,
//                   border: `1px solid transparent`,
//                   width: `240px`,
//                   height: `32px`,
//                   padding: `0 12px`,
//                   borderRadius: `3px`,
//                   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//                   fontSize: `14px`,
//                   outline: `none`,
//                   textOverflow: `ellipses`,
//                   position: "absolute",
//                   left: "50%",
//                   marginLeft: "-120px",
//                 }}
//               />
//             </Autocomplete>
//             <Marker position={markerPosition} />
//           </GoogleMap>
//         </LoadScript>
//       </div>
//       <button onClick={onClose} style={{ marginTop: "10px" }}>
//         Close
//       </button>
//     </Modal>
//   );
// }

// export default MapPicker;

// import React, { useState } from "react";
// import { LoadScript, Autocomplete } from "@react-google-maps/api";
// import Modal from "react-modal";

// function MapPicker({ isOpen, onClose, onSelect }) {
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

//   const onLoad = (autoComp) => {
//     setAutocomplete(autoComp);
//   };

//   const onPlaceChanged = () => {
//     if (autocomplete !== null) {
//       const place = autocomplete.getPlace();
//       const lat = place.geometry.location.lat();
//       const lng = place.geometry.location.lng();
//       setCoordinates({ lat, lng });
//       onSelect({ lat, lng });
//     } else {
//       console.log("Autocomplete is not loaded yet!");
//     }
//   };

//   return (
//     <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Select Location">
//       <div style={{ width: "100%", height: "100px" }}>
//         <LoadScript googleMapsApiKey="AIzaSyAFD1eBkueEp2PcrbPLNJs-isxY8w-EUH0" libraries={["places"]}>
//           <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
//             <input
//               type="text"
//               placeholder="Search places..."
//               style={{
//                 boxSizing: `border-box`,
//                 border: `1px solid transparent`,
//                 width: `100%`,
//                 height: `32px`,
//                 padding: `0 12px`,
//                 borderRadius: `3px`,
//                 boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//                 fontSize: `14px`,
//                 outline: `none`,
//                 textOverflow: `ellipsis`,
//               }}
//             />
//           </Autocomplete>
//         </LoadScript>
//       </div>
//       {coordinates.lat && coordinates.lng && (
//         <div>
//           <p>Latitude: {coordinates.lat}</p>
//           <p>Longitude: {coordinates.lng}</p>
//         </div>
//       )}
//       <button onClick={onClose} style={{ marginTop: "10px" }}>
//         Close
//       </button>
//     </Modal>
//   );
// }

// export default MapPicker;

// LocationSearch.js

import React, { useState } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

const LocationSearch = ({ onLocationSelect }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  console.log("{process.env.REACT_APP_BASE_API}", `{process.env.REACT_APP_BASE_API}`)
  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      onLocationSelect(lat, lng);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_BASE_API}
      libraries={["places"]}
    >
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder="Search places..."
          className="form-control"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipsis`,
          }}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default LocationSearch;
