import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/DataTable/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import { Badge } from "react-bootstrap";

import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";

export default function Hau() {
  const title = "Hub Admins";

  const [apistate, setApiState] = useState([]); // Ensure this is an array initially
  const [apicol, setApiCol] = useState([]); // Ensure this is an array initially
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [hauCount, setHauCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showHauModal, setShowHauModal] = useState(false);
  const [selectedHau, setSelectedHau] = useState(null);
  const brad = [{ name: "Home" }, { name: title }];

  const columns = [
    {
      name: <b>User Name</b>,
      selector: (row) => (row.adminId ? row.adminId.email : "N/A"),
      sortable: true,
    },
    {
      name: <b>Hub Name</b>,
      cell: (row) => {
        // Sort hubs by name
        const sortedHubs = (row.hubId || []).sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        return (
          <div>
            {sortedHubs.length > 0 ? (
              <div>
                {sortedHubs.map((hub) => (
                  <Badge key={hub._id} pill bg="white" text="black">
                    {hub.name}
                  </Badge>
                ))}
              </div>
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        ` ${process.env.REACT_APP_BASE_URL}/api/v1/hubAdmins`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.hubAdmins)) {
        setApiState(response.data.hubAdmins);
        setHauCount(response.data.hubAdminsCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }

    const adminId =
      typeof row.adminId === "string" ? row.adminId : row.adminId?._id;

    if (!adminId) {
      Swal.fire("Error!", "Invalid hubId or adminId.", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/hubAdmin/delete/${adminId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "HUbAdmin has been deleted.", "success");
            fetchData(); // Refresh the data
          })
          .catch((error) => {
            Swal.fire("Error!", "HubAdmin could not be deleted.", "error");
          });
      }
    });
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <Link to="/create-hau">
                  <div className="btn btn-info btn-sm text-white">
                    Assign Hub
                  </div>
                </Link>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="email"
                    title_btn={true}
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
