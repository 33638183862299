import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../pages/dashboard/index";
// Auth
import Login from "../pages/Auth/login";
import Register from "../pages/Auth/register";
import ForgotPassword from "../pages/Auth/forgot_password";
// Profile setting
import Profile from "../pages/profileSetting/index";
// Payment
import PaymentsAllCom from "../pages/payment/index";
import PendingPayments from "../pages/payment/Pending";
import TrashPayment from "../pages/payment/Trash";
import RefundPayment from "../pages/payment/Refund";
// Bank payment
import BankPaymentsAllCom from "../pages/bankPayment/index";
import BankPendingPayments from "../pages/bankPayment/Pending";
import BankTrashPayment from "../pages/bankPayment/Trash";
import BankRefundPayment from "../pages/bankPayment/Refund";
// Stored data
import StoredDataAll from "../pages/storedData/index";
import StoredDataTrashAll from "../pages/storedData/Trash";
// Manage Invoice
import ManageInvoice from "../pages/manageInvoice/index";
import AddInvoice from "../pages/manageInvoice/add_invoice";
import InvoiceLink from "../pages/manageInvoice/invoice_link";
// Brand setting
import ManageBrand from "../pages/manageBrand/index";
import AddBrand from "../pages/manageBrand/add_brand";
import EditBrand from "../pages/manageBrand/edit_brand";
import ManageFAQ from "../pages/manageBrand/manage_faq";
import AddFAQ from "../pages/manageBrand/add_faq";
import EditFAQ from "../pages/manageBrand/edit_faq";
// User Setting
import UserList from "../pages/user/user_list";
// Payment setting
import BkashPaySetting from "../pages/paymentSetting/mobailBank/bkash";
import CellfinPaySetting from "../pages/paymentSetting/mobailBank/cellfin";
import IpayPaySetting from "../pages/paymentSetting/mobailBank/ipay";
import NagadPaySetting from "../pages/paymentSetting/mobailBank/nagad";
import OkwalletPaySetting from "../pages/paymentSetting/mobailBank/okWallet";
import RocketPaySetting from "../pages/paymentSetting/mobailBank/rocket";
import TapPaySetting from "../pages/paymentSetting/mobailBank/tap";
import UpayPaySetting from "../pages/paymentSetting/mobailBank/upay";
// Mobail bank API
import MobailBankApiMethod from "../pages/paymentSetting/mobailBank/apiMethod/index";
import AddMobailBankApi from "../pages/paymentSetting/mobailBank/apiMethod/add_api_method";
import EditMobailBankApi from "../pages/paymentSetting/mobailBank/apiMethod/edit_api_method";
// Bank Transfer
import ManageBankTransfer from "../pages/paymentSetting/bankTransfer/index";
import AddBankTransfer from "../pages/paymentSetting/bankTransfer/add_bank";
import EditBankTransfer from "../pages/paymentSetting/bankTransfer/edit_bank";
// International payment
import InternationalPayPaypal from "../pages/paymentSetting/international/paypal";
import InternationalPayPaddle from "../pages/paymentSetting/international/paddle";
import InternationalPayPaypalPersonal from "../pages/paymentSetting/international/paypal_personal";
import InternationalPayPerfectMoney from "../pages/paymentSetting/international/perfectMoney";
import InternationalPayStripe from "../pages/paymentSetting/international/stripe";
// International payment manual
import InternationalPayManual from "../pages/paymentSetting/international/manualMethod/index";
// Addons
import BulkSmsBd from "../pages/addons/sms_setting/bulkSmsBd";
import MimSms from "../pages/addons/sms_setting/mimSms";
import SmsQ from "../pages/addons/sms_setting/smsq";
import Alpha from "../pages/addons/sms_setting/alpha";
import SmsTransaction from "../pages/addons/sms_transaction/index";
// System Setting
import AppSetting from "../pages/systemSetting/appSetting/index";
import Templete from "../pages/systemSetting/templete/index";
import CustomCss from "../pages/systemSetting/customCss/index";
import Mail from "../pages/systemSetting/mail/index";
import ActiveLicense from "../pages/systemSetting/license/index";
// Notification
import OnSignal from "../pages/systemSetting/onSignal/index";
import NotifyEmail from "../pages/systemSetting/email/index";
// Role Management
import RollUser from "../pages/roleManagement/index";
import RollEdit from "../pages/roleManagement/editRoll";
import RollAccess from "../pages/roleManagement/RoleAccess";
// Theme market
import ThemeMarket from "../pages/Them_Market/index";
import ThemeLivePreview from "../pages/Them_Market/live_preview";
// Change Password
import ChangePassword from "../pages/changePassword/index";
// Activities
import Activities from "../pages/activities/index";
// General Setting
import GeneralSetting from "../pages/generalSetting/index";
// Vehicle Routes
import Vehicle from "../pages/vehicles/vehicle";
import CreateVehicle from "../pages/vehicles/create_vehicle";
import VehicleHistory from "../pages/vehicles/vehiclehistory";
import MapComponent from "../pages/vehicles/MapComponent";

// Driver Routes
import Driver from "../pages/drivers/driver";
import CreateDriver from "../pages/drivers/create_driver";
// Hub Routes
import Hub from "../pages/hubs/hub";
import CreateHub from "../pages/hubs/create_hub";
// Vendor Routes
import Vendor from "../pages/vendors/vendor";
import CreateVendor from "../pages/vendors/create_vendor";

//Client Routes
import Client from "../pages/clients/client";
import Create_client from "../pages/clients/create_client";

//User Routes
import User from "../pages/users/user";
import Create_user from "../pages/users/create_user";
import UserHistory from "../pages/users/userhistory";

import AllUsers from "../pages/allusers/allusers";

import Role from "../pages/roles/role";
import Create_role from "../pages/roles/create_role";

// Utilization Routes
import Utilization from "../pages/utilizations/utilization";
// General Staff Routes
import GeneralStaff from "../pages/generalstaffs/generalstaff";
import CreateGeneralstaffs from "../pages/generalstaffs/create_generalstaff";
// Vehicle Type
import VehicleType from "../pages/vehicleType/vehicletype";

import ProtectedRoute from "../components/protectedRoute";
import Layouts from "../layouts/layouts";
import { AuthProvider } from "../pages/Auth/AuthContext";
import Generalstafftype from "../pages/generalstaffstypes/genralstafftype";
import CreateGeneralstaffstype from "../pages/generalstaffstypes/create_generalstafftype";
import Leaves from "../pages/leaves/leaves";
import LeaveHistory from "../pages/leaves/leaveHistory";
import CreateHau from "../pages/hubsandusers/create_hau";
import Hau from "../pages/hubsandusers/hau";
import Defaulter from "../pages/defaulters/defaulters";
import DefaulterHistory from "../pages/defaulters/defaulterhistory";
import Backup from "../pages/backups/backup";
import Swap from "../pages/swap/swap";

export function AdminRouteq() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layouts />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/profileSetting" element={<Profile />} />
          <Route path="/payment" element={<PaymentsAllCom />} />
          <Route path="/pending-payment" element={<PendingPayments />} />
          <Route path="/trash-payment" element={<TrashPayment />} />
          <Route path="/refund-payment" element={<RefundPayment />} />
          <Route path="/bank-payment" element={<BankPaymentsAllCom />} />
          <Route
            path="/bank-pending-payment"
            element={<BankPendingPayments />}
          />
          <Route path="/bank-trash-payment" element={<BankTrashPayment />} />
          <Route path="/bank-refund-payment" element={<BankRefundPayment />} />
          <Route path="/stored-data" element={<StoredDataAll />} />
          <Route path="/stored-data-trash" element={<StoredDataTrashAll />} />
          <Route path="/manage-invoice" element={<ManageInvoice />} />
          <Route path="/add-invoice" element={<AddInvoice />} />
          <Route path="/invoice-link" element={<InvoiceLink />} />
          <Route path="/manage-brand" element={<ManageBrand />} />
          <Route path="/add-brand" element={<AddBrand />} />
          <Route path="/edit-brand/:id" element={<EditBrand />} />
          <Route path="/manageFaq" element={<ManageFAQ />} />
          <Route path="/addFaq" element={<AddFAQ />} />
          <Route path="/editFaq/:id" element={<EditFAQ />} />
          <Route path="/user-list" element={<UserList />} />
          <Route path="/mobailBank/bKash" element={<BkashPaySetting />} />
          <Route path="/mobailBank/cellfin" element={<CellfinPaySetting />} />
          <Route path="/mobailBank/ipay" element={<IpayPaySetting />} />
          <Route path="/mobailBank/nagad" element={<NagadPaySetting />} />
          <Route path="/mobailBank/okWallet" element={<OkwalletPaySetting />} />
          <Route path="/mobailBank/rocket" element={<RocketPaySetting />} />
          <Route path="/mobailBank/tap" element={<TapPaySetting />} />
          <Route path="/mobailBank/upay" element={<UpayPaySetting />} />
          <Route path="/mobail-bank-api" element={<MobailBankApiMethod />} />
          <Route path="/add-mobailBankApi" element={<AddMobailBankApi />} />
          <Route
            path="/edit-mobailBankApi/:id"
            element={<EditMobailBankApi />}
          />
          <Route path="/manage-bankTransfer" element={<ManageBankTransfer />} />
          <Route path="/bankTransfer/add-bank" element={<AddBankTransfer />} />
          <Route
            path="/bankTransfer/edit-bank/:id"
            element={<EditBankTransfer />}
          />
          <Route
            path="/international-payment/paypal"
            element={<InternationalPayPaypal />}
          />
          <Route
            path="/international-payment/paddle"
            element={<InternationalPayPaddle />}
          />
          <Route
            path="/international-payment/paypal_personal"
            element={<InternationalPayPaypalPersonal />}
          />
          <Route
            path="/international-payment/perfectMoney"
            element={<InternationalPayPerfectMoney />}
          />
          <Route
            path="/international-payment/stripe"
            element={<InternationalPayStripe />}
          />
          <Route
            path="/manage-international-method"
            element={<InternationalPayManual />}
          />
          <Route path="/sms-setting/bulksmsbd" element={<BulkSmsBd />} />
          <Route path="/sms-setting/mimsms" element={<MimSms />} />
          <Route path="/sms-setting/smsq" element={<SmsQ />} />
          <Route path="/sms-setting/alpha" element={<Alpha />} />
          <Route path="/sms-transaction" element={<SmsTransaction />} />
          <Route path="/system-setting/app-setting" element={<AppSetting />} />
          <Route path="/system-setting/templete" element={<Templete />} />
          <Route path="/system-setting/custom-css" element={<CustomCss />} />
          <Route path="/system-setting/mail" element={<Mail />} />
          <Route
            path="/system-setting/active-license"
            element={<ActiveLicense />}
          />
          <Route path="/notification/onSignal" element={<OnSignal />} />
          <Route path="/notification/email" element={<NotifyEmail />} />
          <Route path="/role-user" element={<RollUser />} />
          <Route path="/editRole/:id" element={<RollEdit />} />
          <Route path="/role-access" element={<RollAccess />} />
          <Route path="/theme-market" element={<ThemeMarket />} />
          <Route path="/live-preview/:id" element={<ThemeLivePreview />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/general-setting" element={<GeneralSetting />} />
          <Route path="/vehicle" element={<Vehicle />} />
          <Route path="/create-vehicle" element={<CreateVehicle />} />
          <Route path="/vehiclehistory/:id" element={<VehicleHistory />} />
          <Route path="/userhistory/:id" element={<UserHistory />} />

          <Route
            path="/defaulterhistory/:driverId"
            element={<DefaulterHistory />}
          />
          <Route path="/backup" element={<Backup />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/mymapcomponent" element={<MapComponent />} />
          <Route path="/defaulter" element={<Defaulter />} />

          {/* <Route path="/vehiclehistory" element={<VehicleHistory />} /> */}
          <Route path="/driver" element={<Driver />} />
          <Route path="/create-driver" element={<CreateDriver />} />
          <Route path="/hub" element={<Hub />} />
          <Route path="/create-hub" element={<CreateHub />} />
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/create-vendor" element={<CreateVendor />} />
          <Route path="/client" element={<Client />} />
          <Route path="/create-client" element={<Create_client />} />
          <Route path="/user" element={<User />} />
          <Route path="/allusers" element={<AllUsers />} />
          <Route path="/create-user" element={<Create_user />} />
          <Route path="/role" element={<Role />} />
          <Route path="/create-role" element={<Create_role />} />
          <Route path="/hau" element={<Hau />} />

          <Route path="/create-hau" element={<CreateHau />} />

          <Route path="/utilization" element={<Utilization />} />
          <Route path="/generalstaff" element={<GeneralStaff />} />
          <Route
            path="/create-generalstaffs"
            element={<CreateGeneralstaffs />}
          />
          <Route path="/generalstafftype" element={<Generalstafftype />} />
          <Route
            path="/create-generalstaffstype"
            element={<CreateGeneralstaffstype />}
          />

          <Route path="/vehicletype" element={<VehicleType />} />

          {/* LEAVES ROUTE */}
          <Route path="/leave" element={<Leaves />} />
          <Route path="/leavehistory/:id" element={<LeaveHistory />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
