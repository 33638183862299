import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { State, City } from "country-state-city";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateVendor,
}) {
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [users, setUsers] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [formData, setFormData] = useState(new FormData()); // Initialize FormData
  const [errors, setErrors] = useState({}); // For validation errors

  const countryCode = "IN";

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setSelectedStateCode(select_data.state || "");
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: select_data.id_user._id,
      }));
    }
  }, [select_data, users]);

  useEffect(() => {
    const stateData = State.getStatesOfCountry(countryCode).map((state) => ({
      name: state.name,
      code: state.isoCode,
    }));
    setStates(stateData);
    setCities([]); // Clear cities initially
  }, [countryCode]);

  useEffect(() => {
    if (selectedStateCode) {
      const cityData = City.getCitiesOfState(
        countryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, countryCode]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!update_modal_data.homenumber)
      newErrors.homenumber = "Home Number is required";
    if (!update_modal_data.tempaddress)
      newErrors.tempaddress = "Temporary Address is required";
    if (!update_modal_data.state) newErrors.state = "State is required";
    if (!update_modal_data.city) newErrors.city = "City is required";
    if (!update_modal_data.permanentaddress)
      newErrors.permanentaddress = "Permanent Address is required";
    if (!update_modal_data.coordinates?.latitude)
      newErrors.latitude = "Latitude is required";
    if (!update_modal_data.coordinates?.longitude)
      newErrors.longitude = "Longitude is required";
    if (!update_modal_data.id_user) newErrors.id_user = "User is required";
    if (!update_modal_data.status) newErrors.status = "Status is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };


  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setUpdate_modal_data((prevData) => ({
          ...prevData,
          coordinates: { latitude: lat, longitude: lng },
        }));
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "latitude" || name === "longitude") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        coordinates: { ...prevData.coordinates, [name]: value },
      }));
    } else if (name === "governmentdocument") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else if (name === "id_user") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: { ...prevData.id_user, [name]: value },
      }));
    } else {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
  const handleUpdate = async () => {
    if (!validateForm()) {
      return; // If validation fails, stop further execution
    }
  
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
  
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id, createdBy } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }
  
          const formData = new FormData();
  
          // Append all fields to FormData
          for (const key in update_modal_data) {
            if (key === "coordinates") {
              formData.append(
                "coordinates[latitude]",
                update_modal_data.coordinates?.latitude || ""
              );
              formData.append(
                "coordinates[longitude]",
                update_modal_data.coordinates?.longitude || ""
              );
            }  else if (key === "createdBy") {
              formData.append("createdBy._id", createdBy?._id || "");
              formData.append("createdBy.user_email", createdBy?.user_email || "");
              formData.append("createdBy.user_role", createdBy?.user_role || "");
            } 
           else if (key === "governmentdocument") {
            // Only append avatar if it's updated
            if (update_modal_data.governmentdocument && update_modal_data.governmentdocument[0]) {
              formData.append(key, update_modal_data.governmentdocument[0]);
            }
          }
          else {
              formData.append(key, update_modal_data[key]);
            }
          }
  
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/${_id}`,
            formData, // Use formData instead of update_modal_data
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data", // Set content type for FormData
              },
            }
          );
  
          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateVendor(update_modal_data); // Update the parent state
            handleClose();
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating Vendor:", error);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  
  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Vendor Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {Object.keys(errors).map((key) => (
              <Alert key={key} variant="danger">
                {errors[key]}
              </Alert>
            ))}
            <Form.Group className="mb-3">
              <Form.Label>
                Home Number <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="homenumber"
                value={update_modal_data.homenumber || ""}
                onChange={handleChange}
                isInvalid={!!errors.homenumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.homenumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Temporary Address <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="tempaddress"
                value={update_modal_data.tempaddress || ""}
                onChange={handleChange}
                isInvalid={!!errors.tempaddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tempaddress}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                State <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="state"
                value={update_modal_data.state || ""}
                onChange={handleChange}
                isInvalid={!!errors.state}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.state}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                City <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="city"
                value={update_modal_data.city || ""}
                onChange={handleChange}
                isInvalid={!!errors.city}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.code} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.city}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Permanent Address <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="permanentaddress"
                value={update_modal_data.permanentaddress || ""}
                onChange={handleChange}
                isInvalid={!!errors.permanentaddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.permanentaddress}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Longitude <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="longitude"
                value={update_modal_data.coordinates?.longitude || ""}
                onChange={handleChange}
                isInvalid={!!errors.longitude}
              />
              <Form.Control.Feedback type="invalid">
                {errors.longitude}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Latitude <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="latitude"
                value={update_modal_data.coordinates?.latitude || ""}
                onChange={handleChange}
                isInvalid={!!errors.latitude}
              />
              <Form.Control.Feedback type="invalid">
                {errors.latitude}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_BASE_API}
                libraries={["places"]}
              >
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <input
                    type="text"
                    placeholder="Search places..."
                    className="form-control"
                  />
                </Autocomplete>
              </LoadScript>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={update_modal_data.description || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                User <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.id_user}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.email}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_user}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Vehicle Status <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Government Document <span className="text-danger"></span>
              </Form.Label>
              <Form.Control
                type="file"
                name="governmentdocument"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Group>
         
            {(update_modal_data.governmentdocument &&
              update_modal_data.governmentdocument[0]) ||
            select_data.governmentdocument?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.governmentdocument &&
                      update_modal_data.governmentdocument[0]
                        ? URL.createObjectURL(
                            update_modal_data.governmentdocument[0]
                          )
                        : select_data.governmentdocument?.url
                    }
                    alt="Hub Image"
                    style={{ maxWidth: "50%", height: "30%" }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Vendor
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
