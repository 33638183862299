import { useState, useEffect, useRef } from 'react'
import { Modal, Button, Form } from "react-bootstrap";
import Swal from 'sweetalert2'
export default function Details_modal({ row, openDetailsModal, modelRender, render }) {

  // re-render state
  const [ren, setRen] = useState("false")
  {/* all data for view */ }
  const [modal_all_data, setModal_all_data] = useState([])
  {/* see all details modal(view) */ }
  const [view_modal_show, set_view_modal_Show] = useState(false);

  // store data;
  useEffect(() => {
    setModal_all_data(row)
  }, [row])

  // show view modal;
  useEffect(() => {
    set_view_modal_Show(openDetailsModal);
  }, [openDetailsModal])

  const handleClose = () => {
    set_view_modal_Show(false);
    modelRender(false)
  }

  // swite alert

  // user Ban
  const permitHandle = () => {

    Swal.fire({
      icon: 'warning',
      title: 'You wont be able to revert this!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        handleClose();
        setRen(!ren);
        render(ren)
        Swal.fire('Permit!. id:' + modal_all_data.id, '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  // user Ban
  const banHandle = () => {

    Swal.fire({
      icon: 'warning',
      title: 'You wont be able to revert this!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        handleClose();
        setRen(!ren);
        render(ren)
        Swal.fire('Baned!. id:' + modal_all_data.id, '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }


  // delete user

  const deleteHandle = () => {

    Swal.fire({
      icon: 'warning',
      title: 'You wont be able to revert this!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        handleClose();
        setRen(!ren);
        render(ren)
        Swal.fire('Deleted!. id:' + modal_all_data.id, '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }


  return (
    <>
      {/* user setting model */}
      <Modal show={view_modal_show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2 my-3">
            {
              modal_all_data.ban == 1 ?
                <button type="button" onClick={permitHandle} className="btn btn-outline-info  btn-sm mx-1">Permit User</button>
                :
                <button type="button" onClick={banHandle} className="btn btn-outline-info  btn-sm mx-1">Ban User</button>
            }

            <button type="button" onClick={deleteHandle} className="btn btn-outline-danger  btn-sm mx-1">Delete</button>
          </div>

          <div className="table-responsive my-3">
            <table className="table align-middle border table-striped table-hover">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>

                {Object.entries(modal_all_data).map((data, i) => {
                  return (<tr key={i}>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                  </tr>)
                })}


              </tbody>
            </table>
          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}