import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_client() {
  const title = "Add Client";
  const navigate = useNavigate();

  const [hubs, setHubs] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    payout: "",
    payoutperpacket: "",
    id_hub: "",
    status: "Active",
    image_url: null,
    clientImage: null,
  });

  const [errors, setErrors] = useState({});

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Client Name is required.";
    }
    if (!formData.payout) {
      newErrors.payout = "Payout is required.";
    }
    if (!formData.payoutperpacket) {
      newErrors.payoutperpacket = "Payouts Per Packet are required.";
    }
    if (!formData.id_hub) {
      newErrors.id_hub = "Hub is required.";
    }
    if (!formData.status) {
      newErrors.status = "status is required.";
    }
    if (!formData.clientImage)
      newErrors.clientImage = "Client Image is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value, files } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "clientImage") {
      const file = files[0];
      if (file.size > 1048576) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          clientImage: "Image size must not exceed 1MB",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          clientImage: "",
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          clientImage: file,
        }));
      }
    }
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    if (errors.clientImage) {
      alert(errors.clientImage);
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("name", formData.name);
    data.append("payout", formData.payout);
    data.append("payoutperpacket", formData.payoutperpacket);
    data.append("id_hub", formData.id_hub);
    data.append("status", formData.status);
    data.append("clientImage", formData.clientImage);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/client/new`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        navigate("/client");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHubs(response.data.hubs);
      } catch (error) {
        console.error("Error fetching hub:", error);
        setHubs([]);
      }
    };
    fetchHubs();
  }, []);

  const hubOptions = hubs.map((hub) => ({
    value: hub._id,
    label: hub.name,
  }));

  const handleHubChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_hub: selectedOption ? selectedOption.value : "",
    }));
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="name">
                  <b>Name *</b>
                </label>
                <input
                  type="text"
                  placeholder="Client Name"
                  className="form-control"
                  name="name"
                  onChange={onChangeHandler}
                  value={formData.name || ""}
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="payout">
                  <b>Payout *</b>
                </label>
                <input
                  type="number"
                  placeholder="Payout"
                  className="form-control"
                  name="payout"
                  onChange={onChangeHandler}
                  value={formData.payout || ""}
                />
                {errors.payout && (
                  <p className="text-danger">{errors.payout}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="payoutperpacket">
                  <b>Payouts Per Packet *</b>
                </label>
                <input
                  type="number"
                  placeholder="Payouts per packet"
                  className="form-control"
                  name="payoutperpacket"
                  onChange={onChangeHandler}
                  value={formData.payoutperpacket || ""}
                />
                {errors.payoutperpacket && (
                  <p className="text-danger">{errors.payoutperpacket}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="hub">
                  <b>Hub *</b>
                </label>
                <Select
                  name="id_hub"
                  options={hubOptions}
                  onChange={handleHubChange}
                  value={hubOptions.find(
                    (option) => option.value === formData.id_hub
                  )}
                  isClearable
                  placeholder="Select Hub"
                  className="basic-single"
                  classNamePrefix="select"
                />
                {errors.id_hub && (
                  <p className="text-danger">{errors.id_hub}</p>
                )}
              </div>
              <div className="col-md-6 mt-2">
                <label htmlFor="clientImage">
                  <b>Client Image * </b>
                </label>
                <input
                  type="file"
                  name="clientImage"
                  placeholder="Client Image"
                  className="form-control"
                  onChange={onChangeHandler}
                  accept="image/*"
                />
                {errors.clientImage && (
                  <small className="text-danger">{errors.clientImage}</small>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b>Client status *</b>
                </label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                {errors.status && (
                  <p className="text-danger">{errors.status}</p>
                )}
              </div>
              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Client
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
