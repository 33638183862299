import { useState, useEffect } from 'react'
import Breadcrumb from '../../components/breadcrumb/index'
import Preview_img  from '../../assets/photo/admin.jpg'
import Swal from 'sweetalert2'
import {Link,useParams} from 'react-router-dom'
export default function Live_preview() {
  
const {id}=useParams()
 

    return (
        <>
            <div class="container-fluid">
    <h1>preview Page id :{id}</h1>
            </div>
        </>
    )
}