import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/DataTable/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Add_data_modal from "../../components/gstypes/update_data_modal/index";

import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import create_generalstaffstype from "./create_generalstafftype";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";
import GeneralStaffDetailModal from "../../components/generalstaffs/GeneralStaff Details";
import { Badge } from "react-bootstrap";

export default function Generalstafftype() {
  const title = "General Types";
  const [apistate, setApiState] = useState([]); // Ensure this is an array initially
  const [apicol, setApiCol] = useState([]); // Ensure this is an array initially
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [generalStafftypeCount, setGeneralStafftypeCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showGeneralStaffModal, setShowGeneralStaffModal] = useState(false);
  const [selectedGeneralStaff, setSelectedGeneralStaff] = useState(null);
  const brad = [{ name: "Home" }, { name: title }];

  const columns = [
    { name: <b>Staff Type</b>, selector: (row) => row.name, sortable: true },
    {
      name: <b>Weekend Policy</b>,
      selector: (row) => row.weekendPolicy,
      sortable: true,
    },
    {
      name: <b>Casual Leave Balance </b>,
      selector: (row) => row.casualLeaveBalance,
      sortable: true,
    },
    {
      name: <b>Holiday Restrictions</b>,
      cell: (row) => (
        <div>
          {row.holidayRestrictions && row.holidayRestrictions.length > 0 ? (
            <div>
              {row.holidayRestrictions.map((holiday) => (
                <Badge key={holiday._id} pill bg="white" text="black">
                  {holiday.name}
                </Badge>
              ))}
            </div>
          ) : (
            <span>N/A</span>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: <b>Description</b>,
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff-types?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.generalStaffTypes)) {
        setApiState(response.data.generalStaffTypes);
        setGeneralStafftypeCount(response.data.generalStaffTypeCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      console.log(row._id);
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff-type/${row._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "General Staff has been deleted.", "success");
            fetchData(); // Refresh the data
          })
          .catch((error) => {
            Swal.fire("Error!", "General Staff could not be deleted.", "error");
          });
      }
    });
  };
  const updateGeneralStaffType = (updatedgst) => {
    setApiState((prev) =>
      prev.map((holiday) =>
        holiday._id === updatedgst._id ? updatedgst : holiday
      )
    );
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateGeneralStaffType={updateGeneralStaffType}
      />
      <GeneralStaffDetailModal
        show={showGeneralStaffModal}
        onHide={() => setShowGeneralStaffModal(false)}
        generalstaff={selectedGeneralStaff}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <Link to="/create-generalstaffstype">
                  <div className="btn btn-info btn-sm text-white">
                    Add Staff
                  </div>
                </Link>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name" // Search based on the vehicle's number plate
                    title_btn={true}
                    title="Staff List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
