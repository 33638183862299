import { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import Breadcrumb from '../../../../components/breadcrumb/index'
import Swal from 'sweetalert2'
export default function Add_api_method() {
    const title = "Add Mobail Bank (Api)"
    const navigate = useNavigate()
    const [rerendarApi, setRerendarApi] = useState(false)

    {/* data receve from store */ }
    useEffect(() => {

        console.log("render from brand")
    }, [rerendarApi])

    const add_mobail_bank_api = () => {
        setRerendarApi(!rerendarApi)
        Swal.fire('Saved!', '', 'success')
    }

const brad = [
    {
        name: "home",
    },
    {
        name: title,
    }
]
return (
    <>

        <div className="container-fluid">
            <Breadcrumb title={title} brad={brad} />

            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                <div className="card-header d-flex justify-content-between border-bottom pb-1">
                    <div className="">{title} </div>
                    <button className="btn btn-primary btn-sm" onClick={() => navigate(-1)}>Back</button>

                </div>
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-md-6 mt-3">
                            <label for="brand"><b>Select brand</ b></label>
                            <select className="form-control">
                                <option value="">onhost</option>
                                <option value="">onhost2</option>

                            </select>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label for="brand"><b>Payment Method type</ b></label>
                            <select className="form-control">
                                <option value="">onhost</option>
                                <option value="">onhost2</option>

                            </select>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label for="brand"><b>Full name</ b></label>
                            <input type="text" placeholder="Full Name" className="form-control" />
                        </div>
                        <div className="col-md-6 mt-3">
                            <label for="brand"><b>Transaction Fee</ b></label>
                            <div className="input-group mb-3">
                                <input type="number" className="form-control " defaultValue="deede" aria-label="apigenerate" readOnly />
                                <span className="input-group-text">$  </span>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label for="brand"><b>Min Amount</ b></label>
                            <input type="number" placeholder="Min Amount" className="form-control" />
                        </div>
                        <div className="col-md-6 mt-3">
                            <label for="brand"><b>Max Amount</ b></label>
                            <input type="number" placeholder="Max Amount" className="form-control" />
                        </div>
                    </div>
                    <button onClick={add_mobail_bank_api} className="btn btn-primary btn-sm my-3">Add</button>
                </div>
            </div>
        </div>

    </>
)
}