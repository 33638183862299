import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/defaulters/Data Table Component";
import { useNavigate } from "react-router-dom";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import { Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faHistory,
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";

export default function Defaulter() {
  const title = "Defaulters";
  const navigate = useNavigate();

  const [history, setHistory] = useState([]);
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const brad = [{ name: "Home" }, { name: title }];

  const columns = [
    {
      name: <b>Number Plate</b>,
      selector: (row) => row.vehicleData?.VehicleNumber,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.vehicleData?.VehicleNumber}
        </div>
      ),
    },
    {
      name: <b>Driver Name</b>,
      selector: (row) => row.vehicleData?.DriverId?.id_user?.name,
      sortable: true,
    },
    {
      name: <b>Phone Number</b>,
      selector: (row) => row.vehicleData?.DriverId?.id_user?.phonenumber,
      sortable: true,
    },
    {
      name: <b>Date</b>,
      selector: (row) => {
        const date = new Date(row.date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
      sortable: true,
    },
    {
      name: <b>Assigned Location</b>,
      cell: (row) => (
        <div
          style={{
            maxWidth: "200px",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          {row.vehicleData?.AssignedLocation || "N/A"}
        </div>
      ),
      sortable: true,
    },

    {
      name: <b>Current Location</b>,
      cell: (row) => (
        <div
          style={{
            maxWidth: "200px",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          {row.vehicleData?.CurrentLocation || "N/A"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <b>Count</b>,
      selector: (row) => row.count,
      sortable: true,
      cell: (row) => (
        <button
          style={{
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "50%", // Makes the button circular
            width: "32px", // Set width to match height
            height: "30px", // Set height to define the circle size
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {row.count}
        </button>
      ),
    },

    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => handleDefaulterHistoryClick(row)}
            className="btn btn-warning btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faHistory} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const handleDefaulterHistoryClick = (row) => {
    const driverId = row.vehicleData?.DriverId?._id;
    if (driverId) {
      navigate(`/defaulterhistory/${driverId}`);
    } else {
      console.error("Not Found");
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      // Format the dates using moment.js
      const formattedFromDate = fromDate
        ? moment(fromDate).format("YYYY-MM-DD")
        : null;
      const formattedToDate = toDate
        ? moment(toDate).format("YYYY-MM-DD")
        : null;

      // Construct query parameters for date range
      const queryParams = [];
      if (formattedFromDate) queryParams.push(`fromDate=${formattedFromDate}`);
      if (formattedToDate) queryParams.push(`toDate=${formattedToDate}`);

      const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/notifications${queryString}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.notifications)) {
        const notifications = response.data.notifications;

        // Frontend filtering based on date range if needed
        const filteredNotifications = notifications.filter((notification) => {
          const notificationDate = moment(notification.date).format(
            "YYYY-MM-DD"
          );
          if (formattedFromDate && notificationDate < formattedFromDate)
            return false;
          if (formattedToDate && notificationDate > formattedToDate)
            return false;
          return true;
        });

        // Group by DriverId and count occurrences
        const driverMap = filteredNotifications.reduce((acc, curr) => {
          const driverId = curr.vehicleData?.DriverId?._id;
          if (!driverId) return acc;

          if (!acc[driverId]) {
            acc[driverId] = { ...curr, count: 1 };
          } else {
            acc[driverId].count += 1;
          }
          return acc;
        }, {});

        const aggregatedNotifications = Object.values(driverMap);
        setApiState(aggregatedNotifications);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [currentPage, fromDate, toDate]);
  useEffect(() => {
    fetchData();
  }, [currentPage, fromDate, toDate]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                <h4>{title}</h4>
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <label>From Date:</label>
                    <input
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                  <div>
                    <label>To Date:</label>
                    <input
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    columns={columns}
                    // data={filteredHistory}
                    searchBy="VehicleNumber"
                    title_btn={true}
                    apidata={apistate}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    // columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
