import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        brand: "onhost",
        invoice_id:654654365436,
        name: "dederd2",
        email: "bkash",
        amount:65352,
        date:654352,
        
        status: "created",

    },
    {
        id:2,
        brand: "onhost",
        invoice_id:654654365436,
        name: "gfdfh",
        email: "bkash",
        amount:65352,
        date:654352,
        status: "pending",

    },
    {
        id:3,
        brand: "onhost",
        invoice_id:654654365436,
        name: "rvhv",
        email: "bkash",
        amount:65352,
        date:654352,
        status: "created",
    },
    {
        id:23,
        brand: "onhost",
        invoice_id:654654365436,
        name: "okloi",
        email: "bkash",
        amount:65352,
        date:654352,
        status: "complete",
    },
   
  

]
