import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        brand:"dothost",
        name: "mobashir",
        email: "23@gmail.com",
        status: "complete",
        invoice_id: 12,
        payment_method:"bkash",
        sender_number: 133,
        transaction_id: 563456,
        amount: 32432,
        date:"1-10-23",
        fee: 4000,
        total:3423,
        refunded_amount:23,
        payment_type: "invoice",
        reference: "payments"

    },
    {
        id:2,
        brand:"dothost",
        name: "mahamudul hasan",
        email: "23@gmail.com",
        status: "refunded",
        invoice_id: 12,
        payment_method:"bkash",
        sender_number: 133,
        transaction_id: 563456,
        amount: 32432,
        date:"1-10-23",
        fee: 4000,
        total:3423,
        refunded_amount:13,
        payment_type: "invoice",
        reference: "payments"
    },
    {
        id:9,
        brand:"dothost",
        name: "rana",
        email: "23@gmail.com",
        status: "pending",
        invoice_id: 12,
        payment_method:"bkash",
        sender_number: 133,
        transaction_id: 563456,
        amount: 32432,
        date:"1-10-23",
        fee: 4000,
        total:3423,
        refunded_amount:43,
        payment_type: "invoice",
        reference: "payments"
    },
    
]
