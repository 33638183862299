import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        brand: "onhost",
        bank_name: "dedwcq2",
        account_name: "decerd2",
        account_number: "dewdcqwdderd2",
        branch_name: "dercdd2",
        routing_number: "wderd2",        
        status: "active",

    },
    {
        id:2,
        brand: "onhost",
        bank_name: "derew2",
        account_name: "dsdfd2",
        account_number: "dewrrd2",
        branch_name: "defcwd2",
        routing_number: "rwrd2",  
        status: "inactive",

    },
  {
        id:21,
        brand: "onhost",
        bank_name: "dewefwd2",
        account_name: "dvcd2",
        account_number: "ddfasfd2",
        branch_name: "dedd2",
        routing_number: "werrd2",  
        status: "active",
    },
  

]
