import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faHistory,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Add_data_modal from "../../components/users/update_data_modal";
import Layouts from "../../layouts/layouts";
import UserDetailModal from "../../components/users/User Details";
import * as XLSX from "xlsx";
import moment from "moment";
import DataTable_Component from "../../components/users/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Switch from "react-switch";

export default function User() {
  const [clientCounts, setClientCounts] = useState({}); // State for client counts

  const navigate = useNavigate();

  const title = "Users Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showUserDetailModal, setShowUserDetailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const formatTime = (datetimeString) => {
    if (!datetimeString) return "";

    const date = new Date(datetimeString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  const isToday = (dateString) => {
    if (!dateString) return false;

    const today = new Date();
    const dateToCheck = new Date(dateString);

    return (
      today.getDate() === dateToCheck.getDate() &&
      today.getMonth() === dateToCheck.getMonth() &&
      today.getFullYear() === dateToCheck.getFullYear()
    );
  };

  const getUserClientCounts = (users) => {
    return users.reduce((acc, user) => {
      const client = user.id_client ? user.id_client.name : "N/A";
      console.log(`Processing user: ${user.name}, client: ${client}`);
      acc[client] = (acc[client] || 0) + 1;
      console.log(`Updated counts: `, acc);
      return acc;
    }, {});
  };
  const userClientCounts = getUserClientCounts(apistate);
  console.log("User Client Counts:", userClientCounts);

  // Columns definition
  const columns = [
    {
      name: <b>Email</b>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <b>Role</b>,
      selector: (row) => (row.id_role ? row.id_role.role_title : "N/A"),
      sortable: true,
    },
    {
      name: <b>Clients</b>,
      selector: (row) => {
        if (Array.isArray(row.id_client)) {
          return row.id_client.map((client) => client.name).join(", ");
        } else {
          return "N/A";
        }
      },
      sortable: true,
    },
    {
      name: <b>Staff Type</b>,
      selector: (row) =>
        row.id_generalStaffType ? row.id_generalStaffType.name : "N/A",
      sortable: true,
    },
    {
      name: <b>Name</b>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <b>Working Hours</b>,
      selector: (row) => row.workinghours,
      sortable: true,
    },
    // {
    //   name: <b>Check In</b>,
    //   selector: (row) => formatTime(row.attendance?.check_in),
    //   sortable: true,
    // },
    // {
    //   name: <b>Check Out</b>,
    //   selector: (row) => formatTime(row.attendance?.check_out),
    //   sortable: true,
    // },
    {
      name: <b>Check In</b>,
      selector: (row) =>
        isToday(row.attendance?.date)
          ? formatTime(row.attendance?.check_in)
          : "-",
      sortable: true,
    },
    {
      name: <b>Check Out</b>,
      selector: (row) =>
        isToday(row.attendance?.date)
          ? formatTime(row.attendance?.check_out)
          : "-",
      sortable: true,
    },
    {
      name: <b>Logged In</b>,
      selector: (row) => (row.isLoggedIn ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: <b>Created At</b>,
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: <b>Modified At</b>,
      selector: (row) =>
        row.updatedAt && new Date(row.updatedAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: <b>GeoFencing</b>,
      selector: (row) => (row.geoFencing ? "True" : "False"),
      cell: (row) => (
        <Switch
          onChange={() => handleToggleGeoFencing(row)}
          checked={row.geoFencing}
        />
      ),
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => (
        <button
          // onClick={() => handleShow(row)}
          className={`btn p-0 px-1 ${
            row.status.toLowerCase() === "active" && "btn-success"
          } ${row.status.toLowerCase() === "inactive" && "btn-danger"}
          `}
        >
          {row.status}
        </button>
      ),
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          {row.status === "created" && (
            <>
              <button
                onClick={() => copyLink()}
                className=" btn btn-success btn-sm"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button className=" btn btn-primary btn-sm ms-1">
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </>
          )}
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className=" btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewUser(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => handleUserHistoryClick(row)}
            className="btn btn-warning btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faHistory} />
          </button>
        </>
      ),
    },
  ];

  const calculateClientCounts = (users) => {
    const counts = users.reduce((acc, user) => {
      const clients = user.id_client;
      if (Array.isArray(clients)) {
        clients.forEach((client) => {
          acc[client.name] = (acc[client.name] || 0) + 1;
        });
      }
      return acc;
    }, {});
    setClientCounts(counts);
  };
  useEffect(() => {
    calculateClientCounts(apistate);
  }, [apistate]);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const handleUserHistoryClick = (row) => {
    navigate(`/userhistory/${row._id}`);
  };

  const fetchUsers = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.users)) {
        console.log(response.data.users);
        setApiState(response.data.users);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };

  const handleToggleGeoFencing = async (row) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const updatedGeoFencing = !row.geoFencing;
      console.log("Sending request to update geoFencing:", updatedGeoFencing);

      // Create FormData and append the updated geoFencing value
      const formData = new FormData();
      formData.append("geoFencing", updatedGeoFencing);

      console.log("FORMDATA", formData);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/${row._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      if (response.status === 200) {
        const updatedUsers = apistate.map((user) =>
          user._id === row._id
            ? { ...user, geoFencing: updatedGeoFencing }
            : user
        );
        setApiState(updatedUsers);
        Swal.fire("Updated!", "GeoFencing status has been updated.", "success");
      } else {
        Swal.fire(
          "Error!",
          "There was an error updating GeoFencing status.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating GeoFencing status:", error);
      Swal.fire(
        "Error!",
        "There was an error updating GeoFencing status.",
        "error"
      );
    }
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/admin/user/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "User has been deleted.", "success");
            fetchUsers(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "User could not be deleted.", "error");
            console.error("Error deleting user:", error);
          });
      }
    });
  };

  const updateUser = (updatedUser) => {
    setApiState((prev) =>
      prev.map((user) => (user._id === updatedUser._id ? updatedUser : user))
    );
  };

  const viewUser = (user) => {
    setSelectedUser(user);
    setShowUserDetailModal(true);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(apistate);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "UsersData.xlsx");
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateUser={updateUser}
      />
      <UserDetailModal
        show={showUserDetailModal}
        onHide={() => setShowUserDetailModal(false)}
        user={selectedUser}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create-user">
                    <div className="btn btn-info btn-sm text-white">
                      Add User
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm ms-2"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="email"
                    title_btn={true}
                    title="User List"
                    apidata={apistate}
                    columns={columns}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
