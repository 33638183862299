import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 30.7067034,
  lng: 76.6877915,
};

function MyMapComponent() {
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedHub, setSelectedHub] = useState(null);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (Array.isArray(response.data.vehicles)) {
          setApiState(response.data.vehicles);
          setVehicles(response.data.vehicles);
        } else {
          console.error("Unexpected data format from API", response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (Array.isArray(response.data.hubs)) {
          setApiState(response.data.hubs);
          setHubs(response.data.hubs);
        } else {
          console.error("Unexpected data format from API", response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchVehicles();
    fetchHubs();

    // Set up a polling mechanism to refresh vehicle and hub data every minute
    const interval = setInterval(() => {
      fetchVehicles();
      fetchHubs();
    }, 60000); // 60000ms = 1 minute

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_BASE_API}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {vehicles.length > 0
          ? vehicles.map((vehicle) => (
              <Marker
                key={vehicle._id}
                position={{
                  lat: vehicle.coordinates?.latitude,
                  lng: vehicle.coordinates?.longitude,
                }}
                onClick={() => setSelectedVehicle(vehicle)}
              />
            ))
          : console.log("No vehicles to display")}

        {hubs.length > 0
          ? hubs.map((hub) => (
              <Marker
                key={hub._id}
                position={{
                  lat: hub.coordinates?.latitude,
                  lng: hub.coordinates?.longitude,
                }}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onClick={() => setSelectedHub(hub)}
              />
            ))
          : console.log("No hubs to display")}

        {selectedVehicle && (
          <InfoWindow
            position={{
              lat: selectedVehicle.coordinates.latitude,
              lng: selectedVehicle.coordinates.longitude,
            }}
            onCloseClick={() => setSelectedVehicle(null)}
          >
            <div>
              <h3>{selectedVehicle.numberplate}</h3>
              <p>
                <b>
                  Driver Assigned :{" "}
                  {selectedVehicle.id_driver[0]?.id_user?.name}
                </b>
              </p>
            </div>
          </InfoWindow>
        )}

        {selectedHub && (
          <InfoWindow
            position={{
              lat: selectedHub.coordinates.latitude,
              lng: selectedHub.coordinates.longitude,
            }}
            onCloseClick={() => setSelectedHub(null)}
          >
            <div>
              <h3>{selectedHub.name}</h3>
              <p>{selectedHub.description}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default MyMapComponent;
