import { useState, useEffect } from 'react'
import Breadcrumb from '../../components/breadcrumb/index'
import Preview_img  from '../../assets/photo/admin.jpg'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
export default function Theme() {
    const[data,setData]=useState([])
    useEffect(()=>{
        setData([
            {
            id:1,
            image:"tyy/tyrt/y/",
            status:'install'
            },
            {
            id:2,
            image:"tyy/tyrt/y/",
            status:'buy'

            },
            {
            id:3,
            image:"tyy/tyrt/y/",
            status:'buy'

            },
            {
            id:4,
            image:"tyy/tyrt/y/",
            status:'install'

            },
            {
            id:5,
            image:"tyy/tyrt/y/",
            status:'buy'

            },
        ])
    },[data])

    const themeBuyHandle=()=>{
        Swal.fire('Theme Buy Success','','success')
    }
    const themeInstallHandle=()=>{
        Swal.fire('Theme Install Success','','success')
    }
    const title = "Theme Market"
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    return (
        <>
            <div class="container-fluid">
            <Breadcrumb title={title} brad={brad} />
                <div class="row">
                    {
                        data.map((data,i)=>(
                       
                    <div class="col-sm-4 col-md-4 col-lg-3" key={i}>
                        <div class="card">
                        <img src={Preview_img} class="card-img-top" style={{height:220}} alt="..." />
                            <div class="card-body d-flex px-1 justify-content-between">
                                {data.status == "buy" ? 
                                <button className="btn btn-outline-info btn-sm " onClick={themeBuyHandle}>Buy now</button>
                                :
                                <button className="btn btn-outline-info btn-sm " onClick={themeInstallHandle}>Install Theme</button>
                                }
                                <Link to={`/live-preview/${data.id}`}><button className="btn btn-primary btn-sm ">live Preview</button></Link>
                            </div>
                        </div>
                    </div>
                         ) )}

                </div>
            </div>
        </>
    )
}