import {useState,useEffect} from 'react'
import Breadcrumb from '../../../../components/breadcrumb/index'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
export default function Edit_api_method() {
    const title = "Edit Mobail Bank (Api)"
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

    const navigate = useNavigate()
    const { id } = useParams()

    const[data,setData]=useState(false)
    const[rerendarApi,setRerendarApi]=useState(false)

    {/* data receve from store */ }
    useEffect(() => {
        // call api
        // setData(apidata)
        console.log("render from brand")
    }, [rerendarApi])



    const update_mobail_bank_api = () => {
       
        setRerendarApi(!rerendarApi)         
        Swal.fire('Saved!. id:', '', 'success')
          
    }
    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />

                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        <button className="btn btn-primary btn-sm" onClick={() => navigate(-1)}>Back</button>
                    </div>
                    <div className="card-body">
                        <div  className="row justify-content-center">
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Select brand</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Payment Method type</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Full name</ b></label>
                                <input type="text" placeholder="Full Name" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Transaction Fee</ b></label>
                                <div  className="input-group mb-3">
                                    <input type="number"  className="form-control " defaultValue="deede" aria-label="apigenerate" readOnly />
                                    <span  className="input-group-text">$  </span>
                                </div>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Min Amount</ b></label>
                                <input type="number" placeholder="Min Amount" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Max Amount</ b></label>
                                <input type="number" placeholder="Max Amount" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Account type</ b></label>
                                <select className="form-control">
                                    <option value="">Checkout Api</option>
                                    <option value="">Tokenized API</option>
                                    <option value="">Live Without API</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Sandbox</ b></label>
                                <select className="form-control">
                                    <option value="on">On</option>
                                    <option value="off">Off</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>User Name</ b></label>
                                <input type="text" placeholder="User Name" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Password</ b></label>
                                <input type="text" placeholder="Full password" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>App key</ b></label>
                                <input type="text" placeholder="App Key" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>App Secret</ b></label>
                                <input type="text" placeholder="App Secret" className="form-control" />
                            </div>
                        </div>
                        <button onClick={update_mobail_bank_api} className="btn btn-primary btn-sm my-3">Update</button>
                    </div>
                </div>
            </div>

        </>
    )
}