import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/vendors/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Add_data_modal from "../../components/vendors/update_data_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";
import VendorDetailModal from "../../components/vendors/Vendor Details";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export

export default function Vendor() {
  const title = "Vendor Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [vendorCount, setVendorCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showVendorDetailModal, setShowVendorDetailModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const brad = [
    {
      name: "home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name: <b>Vendor Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>City</b>,
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: <b>Created At</b>,
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status ? row.status.toLowerCase() : "";
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
    },
    {
      name: <b>Modified At</b>,
      selector: (row) =>
        row.updatedAt && new Date(row.updatedAt).toLocaleDateString(),
      sortable: true,
    },

    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewVendor(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const updateVendor = (updatedVendor) => {
    setApiState((prev) =>
      prev.map((vendor) =>
        vendor._id === updatedVendor._id ? updatedVendor : vendor
      )
    );
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vendors`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.vendors)) {
        setApiState(response.data.vendors);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )

          .then(() => {
            Swal.fire("Deleted!", "Vendor has been deleted.", "success");
            fetchData(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Vendor could not be deleted.", "error");
          });
      }
    });
  };

  const viewVendor = (vendor) => {
    setSelectedVendor(vendor);
    setShowVendorDetailModal(true);
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((vendor) => ({
      "Vendor Name": vendor.vendorname,
      "Phone Number": vendor.phonenumber,
      "Home Number": vendor.homenumber,
      "Temporary Address": vendor.tempaddress,
      City: vendor.city,
      State: vendor.state,
      "Permanent Address": vendor.permanentaddress,
      Coordinates: vendor.coordinates,
      Description: vendor.description,
      "User Id": vendor.id_user ? vendor.id_user.email : "N/A",
      "Created By": vendor.createdby,
      "Created At": formatDate(vendor.createdAt),
      "Modified At": vendor.updatedAt
        ? new Date(vendor.updatedAt).toLocaleDateString()
        : "N/A",
      Status: vendor.status || "Unknown",
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Vendors");
    XLSX.writeFile(workbook, "VendorsData.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateVendor={updateVendor}
      />
      <VendorDetailModal
        show={showVendorDetailModal}
        onHide={() => setShowVendorDetailModal(false)}
        vendor={selectedVendor}
      />
      <div className="container-fluid">
        {/* <Breadcrumb title={title} brad={brad} /> */}
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create-vendor">
                    <div className="btn btn-info btn-sm text-white me-2">
                      Add Vendor
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm text-white"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="city"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
