import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";
import { Badge } from "react-bootstrap";

// Helper function to calculate client counts
const getClientCounts = (data) => {
  return data.reduce((acc, item) => {
    const clients = item.id_client;
    if (Array.isArray(clients)) {
      clients.forEach((client) => {
        acc[client.name] = (acc[client.name] || 0) + 1;
      });
    }
    return acc;
  }, {});
};

export default function DataTable_Component({ apidata, columns, searchBy }) {
  const [dataFilter, setDataFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [staffTypeFilter, setStaffTypeFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    let filtered = apidata;

    if (statusFilter) {
      filtered = filtered.filter(
        (item) =>
          item.status &&
          item.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    if (roleFilter) {
      filtered = filtered.filter(
        (item) => item.role && item.role._id === roleFilter
      );
    }

    if (clientFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_client &&
          item.id_client.name.toLowerCase().includes(clientFilter.toLowerCase())
      );
    }

    if (staffTypeFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_generalStaffType &&
          item.id_generalStaffType.name
            .toLowerCase()
            .includes(staffTypeFilter.toLowerCase())
      );
    }

    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "email") {
          return (
            item.email &&
            item.email.toLowerCase().includes(dataFilter.toLowerCase())
          );
        }

        return false;
      });
    }

    setFilteredData(filtered);
  }, [
    dataFilter,
    statusFilter,
    roleFilter,
    clientFilter,
    staffTypeFilter,
    apidata,
    searchBy,
  ]);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const removeFilters = () => {
    setStatusFilter("");
    setRoleFilter("");
    setClientFilter("");
    setStaffTypeFilter("");

    setShowFilterModal(false);
  };

  // Calculate client counts based on filtered data
  const clientCounts = getClientCounts(filteredData);

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      <div className="my-3">
        {Object.entries(clientCounts).map(([clientName, count]) => (
          <span key={clientName} className="btn btn-success btn-sm me-2">
            {clientName}: {count}
          </span>
        ))}
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        roleFilter={roleFilter}
        setRoleFilter={setRoleFilter}
        clientFilter={clientFilter}
        setClientFilter={setClientFilter}
        staffTypeFilter={staffTypeFilter}
        setStaffTypeFilter={setStaffTypeFilter}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        apidata={apidata}
      />
    </div>
  );
}
