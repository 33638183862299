import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import Breadcrumb from '../../../components/breadcrumb/index'
export default function MailSetting() {
    const title = "Mail Setting"
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

    
    const [rerendarApi, setRerendarApi] = useState(false)

    {/* data receve from store */ }
    useEffect(() => {
       
    //    call api
        console.log("RENDER FROM STORE TRASH")
    }, [rerendarApi])


    const updatemail = () => {

        Swal.fire({
            icon: 'info',
            title: 'Are You Want to take this action',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
             
                Swal.fire('success', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />

                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        <button className="btn btn-primary btn-sm">Send Test Mail</button>
                    </div>
                    <div className="card-body">
                        <div  className="row justify-content-center">
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Email Method</ b></label>
                                <select className="form-control">
                                    <option value="">SMTP</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>From Email Address</ b></label>
                                <input type="text" placeholder="no-repli@gmail.com" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Smtp Connection Type</ b></label>
                                <select className="form-control">
                                    <option value="">SMTP</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Smtp Require Authentication?</ b></label>
                                <select className="form-control">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>SMTP Host</ b></label>
                                <input type="text" placeholder="Email SMTP Host" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>SMTP Port</ b></label>
                                <input type="text" placeholder="Email SMTP Port" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>SMTP Username</ b></label>
                                <input type="text" placeholder="Email SMTP Username" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>SMTP Password</ b></label>
                                <input type="password" placeholder="Email SMTP Password" className="form-control" />
                            </div>
                            
                        </div>
                        <button onClick={updatemail} className="btn btn-primary btn-sm mt-3">Update</button>
                    </div>
                </div>
            </div>

        </>
    )
}