import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import { useNavigate } from "react-router-dom";
import { State, City } from "country-state-city";
import Layouts from "../../layouts/layouts";
import { Autocomplete, LoadScript } from "@react-google-maps/api";

export default function Create_vendor() {
  const title = "Add Vendor";
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    homenumber: null,
    status: "Active",
    id_user: "",
    coordinates: { latitude: "", longitude: "" },
    governmentdocument: null,
  });
  const [users, setUsers] = useState([]);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const countryCode = "IN";

  useEffect(() => {
    const stateData = State.getStatesOfCountry(countryCode).map((state) => ({
      name: state.name,
      code: state.isoCode,
    }));
    setStates(stateData);
    setCities([]);
  }, [countryCode]);

  useEffect(() => {
    if (selectedStateCode) {
      const cityData = City.getCitiesOfState(
        countryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, countryCode]);

  const brad = [
    {
      name: "home",
    },
    {
      name: title,
    },
  ];

  const onChangeHandler = (event) => {
    const { name, value, files } = event.target;
    if (name === "latitude" || name === "longitude") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: {
          ...prevFormData.coordinates,
          [name]: value,
        },
      }));
    } else if (name === "governmentdocument") {
      if (files[0].size > 1024 * 1024) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          governmentdocument: "File size limit not exceed 1MB.",
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: files[0],
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          governmentdocument: null,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      if (name === "state") {
        setSelectedStateCode(value);
      }
    }
  };
  const onSelectLocation = ({ lat, lng }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coordinates: { latitude: lat, longitude: lng },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.tempaddress)
      newErrors.tempaddress = "Temporary Address is required";
    if (!formData.permanentaddress)
      newErrors.permanentaddress = "Permanent Address is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.governmentdocument)
      newErrors.governmentdocument = "Govt. Document is required";
    if (!formData.coordinates.latitude || !formData.coordinates.longitude)
      newErrors.coordinates = "Location is required";
    if (!formData.id_user) newErrors.id_user = "User is required";
    if (!formData.phonenumber || formData.phonenumber.length !== 10)
      newErrors.phonenumber = "Phone Number must be 10 digits";
    if (!formData.homenumber || formData.homenumber.length !== 10)
      newErrors.homenumber = "Home Number must be 10 digits";
    if (!formData.description)
      newErrors.description = "Description is required";
    return newErrors;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (errors.governmentdocument) {
      Swal.fire({
        icon: "error",
        title: "File Error",
        text: errors.governmentdocument,
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const formDataToSubmit = new FormData();
      formDataToSubmit.append("homenumber", formData.homenumber);
      formDataToSubmit.append("tempaddress", formData.tempaddress);
      formDataToSubmit.append("permanentaddress", formData.permanentaddress);
      formDataToSubmit.append("state", formData.state);
      formDataToSubmit.append("city", formData.city);
      formDataToSubmit.append("description", formData.description);
      formDataToSubmit.append("status", formData.status);
      formDataToSubmit.append(
        "coordinates[latitude]",
        formData.coordinates.latitude
      );
      formDataToSubmit.append(
        "coordinates[longitude]",
        formData.coordinates.longitude
      );

      if (formData.governmentdocument) {
        formDataToSubmit.append(
          "governmentdocument",
          formData.governmentdocument
        );
      }

      if (formData.id_user) {
        formDataToSubmit.append("id_user", formData.id_user);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/new`,
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/vendor");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: { latitude: lat, longitude: lng },
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title} </div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="homenumber">
                  <b>Home Number *</b>
                </label>
                <input
                  type="number"
                  placeholder="Home Number"
                  className="form-control"
                  name="homenumber"
                  onChange={onChangeHandler}
                  value={formData.homenumber || ""}
                />
                {errors.homenumber && (
                  <p className="text-danger">{errors.homenumber}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="tempaddress">
                  <b>Temporary Address *</b>
                </label>
                <input
                  type="text"
                  placeholder="Temporary Address"
                  className="form-control"
                  name="tempaddress"
                  onChange={onChangeHandler}
                  value={formData.tempaddress || ""}
                />
                {errors.tempaddress && (
                  <p className="text-danger">{errors.tempaddress}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="state">
                  <b>State *</b>
                </label>
                <select
                  name="state"
                  className="form-control"
                  value={formData.state || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && <p className="text-danger">{errors.state}</p>}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="city">
                  <b>City *</b>
                </label>
                <select
                  name="city"
                  className="form-control"
                  value={formData.city || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.code} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {errors.city && <p className="text-danger">{errors.city}</p>}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="permanentaddress">
                  <b>Permanent Address *</b>
                </label>
                <input
                  type="text"
                  placeholder="Permanent Address"
                  className="form-control"
                  name="permanentaddress"
                  onChange={onChangeHandler}
                  value={formData.permanentaddress || ""}
                />
                {errors.permanentaddress && (
                  <p className="text-danger">{errors.permanentaddress}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="">
                  <b>Location *</b>
                </label>
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_BASE_API}
                  libraries={["places"]}
                >
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      type="text"
                      placeholder="Search places..."
                      className="form-control"
                    />
                  </Autocomplete>
                </LoadScript>
                {errors.coordinates && (
                  <p className="text-danger">{errors.coordinates}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="coordinates">
                  <b>Coordinates *</b>
                </label>
                <input
                  type="text"
                  name="coordinates"
                  placeholder="Latitude, Longitude"
                  className="form-control"
                  value={`${formData.coordinates.latitude}, ${formData.coordinates.longitude}`}
                  readOnly
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="governmentdocument">
                  <b>Government Document *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="governmentdocument"
                  onChange={onChangeHandler}
                />
                {errors.governmentdocument && (
                  <p className="text-danger">{errors.governmentdocument}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User *</b>
                </label>
                <select
                  name="id_user"
                  className="form-control"
                  value={formData.id_user}
                  onChange={onChangeHandler}
                >
                  <option value="">Select User</option>
                  {Array.isArray(users) &&
                    users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.email}
                      </option>
                    ))}
                </select>
                {errors.id_user && (
                  <p className="text-danger">{errors.id_user}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="description">
                  <b>Description</b>
                </label>
                <input
                  type="text"
                  placeholder="Description"
                  className="form-control"
                  name="description"
                  onChange={onChangeHandler}
                  value={formData.description || ""}
                />
                {errors.description && (
                  <p className="text-danger">{errors.description}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b>Vendor Status </b>
                </label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status || "Active"}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Vendor
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
