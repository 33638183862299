import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function FilterModal({
  show,
  onHide,
  staffTypeFilter,
  setStaffTypeFilter,
  applyFilters,
  removeFilters,

  apidata,
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="staffTypeFilter">
            <Form.Label>Staff Type</Form.Label>
            <Form.Control
              as="select"
              value={staffTypeFilter}
              onChange={(e) => setStaffTypeFilter(e.target.value)}
            >
              <option value="">All Staff Types</option>

              {apidata &&
                Array.from(
                  new Set(
                    apidata.map(
                      (item) => item.id_user?.id_generalStaffType?.name
                    )
                  )
                )
                  .filter(Boolean)
                  .map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
