import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateGeneralStaffType,
}) {
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [errors, setErrors] = useState({});

  console.log("UPDATEDATAMODAL", update_modal_data);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      const selectedHoliday = select_data.holidayRestrictions.map((item) => ({
        value: item._id,
        label: holidays.find((holiday) => holiday._id === item._id).name || "",
      }));
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        holidayRestrictions: [...selectedHoliday],
      }));
    }
  }, [select_data]);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/holidays`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHolidays(response.data.holidays);
      } catch (error) {
        console.error("Error fetching holidays:", error);
        setHolidays([]);
      }
    };
    fetchHolidays();
  }, []);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "holidayRestrictions") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        holidayRestrictions: { ...prevData.holidayRestrictions, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const handleHolidayChange = (selectedOptions) => {
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      holidayRestrictions: selectedOptions || [],
    }));
  };
  const validateForm = () => {
    const newErrors = {};
    if (!update_modal_data.description)
      newErrors.description = "Description is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Convert holidayRestrictions to an array of _id fields if needed
          const formattedHolidayRestrictions =
            update_modal_data.holidayRestrictions.map((item) => item.value);

          // Prepare the data to be sent
          const formattedData = {
            ...update_modal_data,
            holidayRestrictions: formattedHolidayRestrictions.join(","), // Convert to comma-separated string
          };

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff-type/${_id}`,
            formattedData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log("Response from API:", res.data);
          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateGeneralStaffType(update_modal_data);
            set_update_data_modal_Show(false);
            handleClose();
          } else {
            Swal.fire(
              "Update failed",
              res.data.message || "Unknown error",
              "error"
            );
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating General Staff:", error);
        }
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update General Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Staff Type *</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={update_modal_data.name || ""}
                onChange={handleChange}
              />
              {errors.name && (
                <small className="text-danger">{errors.name}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Weekend Policy *</Form.Label>
              <Form.Control
                as="select"
                name="weekendPolicy"
                value={update_modal_data.weekendPolicy || ""}
                onChange={handleChange}
              >
                <option value="None">None</option>
                <option value="Sunday">Sunday</option>
                <option value="Saturday-Sunday">Saturday-Sunday</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.weekendPolicy}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Casual Leave Balance *</Form.Label>
              <input
                type="number"
                className="form-control"
                name="casualLeaveBalance"
                value={update_modal_data.casualLeaveBalance || ""}
                onChange={handleChange}
              />
              {errors.casualLeaveBalance && (
                <p className="text-danger">{errors.casualLeaveBalance}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Holiday Restrictions *</Form.Label>
              <Select
                name="holidayRestrictions"
                defaultValue={update_modal_data.holidayRestrictions || ""}
                options={holidays.map((holiday) => ({
                  value: holiday._id,
                  label: holiday.name,
                }))}
                isMulti
                onChange={handleHolidayChange}
                className={`basic-multi-select ${
                  errors.holidayRestrictions ? "is-invalid" : ""
                }`}
                classNamePrefix="select"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description *</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={update_modal_data.description || ""}
                onChange={handleChange}
              />
              {errors.description && (
                <small className="text-danger">{errors.description}</small>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update GeneralStaff
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
