import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/DataTable/index";
import { useNavigate } from "react-router-dom";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import { Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";

export default function Swap() {
  const title = "Swapped Vehicles";
  const navigate = useNavigate();

  const [history, setHistory] = useState([]);
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const brad = [{ name: "Home" }, { name: title }];

  const columns = [
    {
      name: <b>Previous Number Plate</b>,
      selector: (row) => row.from_vehicle?.numberplate || "N/A",
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.from_vehicle?.numberplate || "N/A"}
        </div>
      ),
    },
    {
      name: <b>Current Number Plate</b>,
      selector: (row) => row.to_vehicle?.numberplate || "N/A",
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.to_vehicle?.numberplate || "N/A"}
        </div>
      ),
    },
    {
      name: <b> Previous Driver</b>,
      selector: (row) => row.from_user?.name,
      sortable: true,
    },
    {
      name: <b> Current Driver</b>,
      selector: (row) => row.to_user?.name,
      sortable: true,
    },
    {
      name: <b> Reason</b>,
      selector: (row) => row.reason,
      sortable: true,
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/swap/all`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.swapRequests)) {
        console.log(response.data.swapRequests);
        setApiState(response.data.swapRequests);
      } else {
        console.error("Unexpected data format from API", response.data);
        setApiState([]);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setApiState([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                <h4>{title}</h4>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    columns={columns}
                    searchBy="VehicleNumber"
                    title_btn={true}
                    apidata={apistate}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    // columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
