import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        brand: "onhost",
        name: "dederd2",
        amounts:34,
        invoice_id:654654365436,
        email: "bkash",
        amount:65352,
        date:654352,
        title:'dfsf',
        description:"fgrgge",
        status: "created",

    },
    {
        id:2,
        brand: "onhost2",
        name: "dedrd2",
        amounts:374,
        invoice_id:65464365436,
        email: "bkash",
        amount:6552,
        date:65352,
        title:'dfsf',
        description:"fgdsfsdrgge",
        status: "pending",

    },
    {
        id:3,
        brand: "aqq2dederd2",
        name: "deded2",
        amounts:324,
        invoice_id:65465435436,
        email: "bkash",
        amount:6552,
        date:65432,
        title:'dfersf',

        description:"fgrggsdafarewe",
        status: "created",
    },
    {
        id:23,
        brand: "a8ederd2",
        name: "6erd2",
        amounts:234,
        invoice_id:65365436,
        email: "bkash",
        amount:6352,
        date:632352,
        title:'derewfsf',

        description:"fgrgerwerwege",
        status: "completed",
    },
    {
        id:21,
        brand: "aqdsferd2",
        name: "deed2",
        amounts:33,
        invoice_id:6523365436,
        email: "bkash",
        amount:6122,
        date:654122,
        title:'d4r43fsf',

        description:"fgerewr4543rgge",
        status: "completed",
    },
  

]
