import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faDatabase,
  faCreditCard,
  faEyeLowVision,
  faBuildingColumns,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import Swal from "sweetalert2";
import axios from "axios";
import { AreaChart, DonutChart } from "./chart/chart";
// import MapComponent from './MapComponent';
import MyMapComponent from "./MyMapComponent";

export default function Hero() {
  const [data, setData] = useState([]);
  const [driverCount, setDriverCount] = useState(0);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [hubCount, setHubCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [generalStaffCount, setGeneralStaffCount] = useState(0);
  const [totalKm, setTotalKm] = useState(0);
  const [dailyKm, setDailykm] = useState(0);
  const [rerendarApi, setRerendarApi] = useState(false);

  const token = localStorage.getItem("token");

  //DRIVER
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/drivers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data); // Log the response to check its structure
        setDriverCount(response.data.driverCount); // Assuming the API returns the count in response.data.driverCount
        console.log("Driver Count:", response.data.driverCount); // Log the driver count
      })
      .catch((error) => {
        console.error("Error fetching driver count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {
    console.log("Driver Count State:", driverCount);
  }, [driverCount]);

  //VEHICLE
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);

        setVehicleCount(response.data.vehicleCount);

        // Calculate total kilometers
        const totalKm = response.data.vehicles.reduce(
          (sum, vehicle) => sum + (vehicle.totalkm || 0),
          0
        );
        setTotalKm(totalKm);

        console.log("Vehicle Count:", response.data.vehicleCount);
      })
      .catch((error) => {
        console.error("Error fetching vehicle count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {
    console.log("Vehicle Count State:", vehicleCount);
  }, [vehicleCount]);

  //USERS COUNT
  //VEHICLE
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);

        setUserCount(response.data.userCount);

        console.log("User Count:", response.data.userCount);
      })
      .catch((error) => {
        console.error("Error fetching user count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {
    console.log("User Count State:", userCount);
  }, [userCount]);

  //HUBS
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/hubs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        setHubCount(response.data.hubCount);
        console.log("Hub Count:", response.data.hubCount);
      })
      .catch((error) => {
        console.error("Error fetching hub count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {
    console.log("Hub Count State:", hubCount);
  }, [hubCount]);

  //CLIENTS
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        setClientCount(response.data.count);
        console.log("Client Count:", response.data.count);
      })
      .catch((error) => {
        console.error("Error fetching client count:", error);
      });
  }, [rerendarApi]);

  // Log vehicleCount to see if it updates
  useEffect(() => {
    console.log("Client Count State:", clientCount);
  }, [clientCount]);

  // Fetch daily kilometers
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response for Dashboard:", response.data);
        setDailykm(response.data.totalDailykmDriven || 0);
        console.log("Daily Kilometers:", response.data.totalDailykmDriven);
      })
      .catch((error) => {
        console.error("Error fetching daily kilometers:", error);
      });
  }, [rerendarApi]);

  //General Staff
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/general-staffs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        setGeneralStaffCount(response.data.generalStaffCount);
        console.log("General Staff Count:", response.data.generalStaffCount);
      })
      .catch((error) => {
        console.error("Error fetching general staff count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {
    console.log("General Count State:", generalStaffCount);
  }, [generalStaffCount]);

  // Log vehicleCount to see if it updates
  useEffect(() => {
    console.log("General Staff Count State:", generalStaffCount);
  }, [generalStaffCount]);

  useEffect(() => {
    setData({ cron1: "cron 1", cron2: "cron 2" });
  }, [rerendarApi]);
  // copy cron
  const copyLink = () => {
    navigator.clipboard.writeText(data.cron1);
    setRerendarApi(!rerendarApi);
    Swal.fire("Copy Cron Url", "", "success");
  };
  const copyLink2 = () => {
    navigator.clipboard.writeText(data.cron2);
    setRerendarApi(!rerendarApi);
    Swal.fire("copy success", "", "success");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div
            className="col d-flex align-items-center"
            style={{ whiteSpace: "nowrap" }}
          >
            <span className="text-xl" style={{ fontSize: "150%" }}>
              Dashboard{" "}
            </span>
          </div>

        </div>
        <div className="row ">
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count">
              <div className="dash-counts">
                <h4>
                  <CountUp end={vehicleCount} />
                </h4>
                <h5>Vehicles</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faCreditCard}
                  size="2xl"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das1">
              <div className="dash-counts">
                <h4>
                  <CountUp end={driverCount} />
                </h4>

                <h5>Drivers</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das2">
              <div className="dash-counts">
                <h4>
                  <CountUp end={hubCount} />
                </h4>

                <h5>Hubs</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={clientCount} />
                </h4>

                <h5>Clients</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={generalStaffCount} />
                </h4>

                <h5>General Staff</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={totalKm} />
                </h4>

                <h5>Total Kilometers</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={dailyKm} />
                </h4>

                <h5>Daily Kilometers</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={userCount} />
                </h4>

                <h5>Total Users</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <MyMapComponent />
          </div>
        </div>
      </div>
    </>
  );
}
