import { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../components/breadcrumb/index'
import DataTable_Component from '../../components/DataTable/index'
import Btn_grp from '../../components/payment/payment_btn_grp/index'
import Status_modal from '../../components/storedData/status_modal/index'
import Details_modal from '../../components/payment/details_modal/index'
import Add_data_modal from '../../components/storedData/add_data_modal/index'
import Update_data_modal from '../../components/storedData/update_data_modal/index'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faEnvelope, faCopy } from '@fortawesome/free-solid-svg-icons'
import { apidata } from './store/store'
import { Link } from 'react-router-dom'
// import {Modal} from 'bootstrap'



export default function PaymentsAll() {

    const title = "Store trash data"
    const [apistate, setApiState] = useState([])
    const [rerendarApi, setRerendarApi] = useState(false)
    const [apicol, setApiCol] = useState([])

    const filter_apistate = apistate.filter((val) => {
        return val.status == "trash"
    })
    {/* data table column name */ }

    const brad = [
        {
            name: "home",
        },
        {
            name: "Stored Data",
        }
    ]
    const columns = [
        {
            name: "Payment Method",
            selector: row => row.payment_method,
            sortable: true
        },
        {
            name: "Amount",
            selector: row => row.amount,
            sortable: true
        },
        {
            name: "Sender Number",
            selector: row => row.sender_number,
            sortable: true
        },
        {
            name: "Transaction_id",
            selector: row => row.transaction_id,
            sortable: true
        },
        {
            name: "Main Balence",
            selector: row => row.main_balence,
            sortable: true
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true
        },

        {
            name: "action",
            cell: (row) => <><button onClick={() => restoreHandle(row)} className="btn btn-warning btn-sm"  >Restore</button><button onClick={() => delete_row(row)} className=" btn btn-danger ms-2 btn-sm"  ><FontAwesomeIcon icon={faTrash} /></button></>,
        }

    ]


    {/* data receve from store */ }
    useEffect(() => {
        setApiState(apidata)
        setApiCol(columns)
        console.log('render from store data')
    }, [rerendarApi])
    const rerender = (e) => {
        setRerendarApi(e)
    }
    // restore resoponse modal
    const restoreHandle = (row) => {
        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to restore this!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                Swal.fire('Restore id:' + row.id, '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    {/* data receve from api */ }
    useEffect(() => {
        // call api and response data set " setApiData(your res.data) " and column setApiCol( columns )

        setApiState(apidata)
        setApiCol(columns)
    }, [rerendarApi])

    // delete resoponse modal
    const delete_row = (row) => {
        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to revert this!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                Swal.fire('Saved!. id:' + row.id, '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }


    return (
        <>


            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Link to="/stored-data" ><button type="button" className="btn btn-outline-success  btn-sm ">All</button></Link>
                <Link to="/stored-data-trash" ><button type="button" className="btn btn-outline-danger active btn-sm ms-1">Trash</button></Link>

                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                            </div>
                            <div className="card-body">
                                <DataTable_Component search="transaction_id" apidata={filter_apistate} columns={apicol} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}