import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import Breadcrumb from '../../components/breadcrumb/index'
import Logo from '../../assets/photo/admin.jpg'
import { useNavigate, useParams } from 'react-router-dom'
export default function Edit_brand() {
    const title = "Edit Brand"
    const navigate = useNavigate()
    const { id } = useParams() // ei id number data update hobe 
    const [rerendarApi, setRerendarApi] = useState(false)
    const [data,setData] = useState('')
    {/* data receve from store */ }
    useEffect(() => {
        // call api and response data set " setApiData(your res.data) " and column setApiCol( columns ).
        setData('set youe data')
        console.log("render from invoice")
    }, [rerendarApi])


    const edit_brand = () => {

        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to revert this!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            setRerendarApi(!rerendarApi)
            if (result.isConfirmed) {
                Swal.fire('Saved!. id:', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    return (
        <>

            <div className="container-fluid">
                <Breadcrumb title={title} brad={brad} />
                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>Back</div>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-md-6 mt-3">
                                <label  ><b>Name</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Main Website</ b></label>
                                <input type="text" placeholder="Full Name" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Allow Pending Payment</ b></label>

                                <input type="text" placeholder="Email or Number" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Validate Session</ b></label>
                                <input type="number" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Default Language</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Support Number</ b></label>
                                <input type="text" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Whatsapp Number</ b></label>
                                <input type="text" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Facebook Number</ b></label>
                                <input type="text" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Support Mail</ b></label>
                                <input type="text" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-12 mt-3">
                                <label  ><b>Meta Description</ b></label>
                                <textarea type="text" placeholder="description" rows="5" className="form-control" ></textarea>
                            </div>
                            {/* logo */}
                            <div className="col-md-6 mt-3">
                                <label  ><b>Logo(512x512)</ b></label><br />
                                <img src={Logo} alt="logo" style={{ width: 120, height: 120 }} />
                                <input type="file" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Favicon</ b></label><br />
                                <img src={Logo} alt="logo" style={{ width: 120, height: 120 }} />
                                <input type="file" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Involce Logo(200x45) pixel</ b></label><br />
                                {/* <img src={Logo} alt="logo"  style={{width: 120,height: 120}} /> */}
                                <input type="file" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Involce Page Banner (266x300) pixel</ b></label><br />
                                <img src={Logo} alt="logo" style={{ width: 120, height: 120 }} />
                                <input type="file" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>FB OG Image</ b></label><br />
                                <img src={Logo} alt="logo" style={{ width: 120, height: 120 }} />
                                <input type="file" placeholder="Amount" className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label  ><b>Twitter Og Image</ b></label><br />
                                <img src={Logo} alt="logo" style={{ width: 120, height: 120 }} />
                                <input type="file" placeholder="Amount" className="form-control" />
                            </div>
                        </div>
                        <button onClick={edit_brand} className="btn btn-primary btn-sm mt-4">Edit Brand</button>
                    </div>
                </div>
            </div>

        </>
    )
}