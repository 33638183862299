import { useState, useEffect, useRef, useContext } from "react";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/DataTable/index";
import Btn_grp from "../../components/payment/payment_btn_grp/index";
import Status_modal from "../../components/payment/status_modal/index";
import Details_modal from "../../components/payment/details_modal/index";
import { btn_link } from "./btn_link/btn_link";
import { apidata } from "./store/store";
// import Context from '../../context'

export default function PaymentsAllq() {
  const title = "Payments";
  // store api data
  const [apistate, setApiData] = useState([]);
  {
    /* all data for view */
  }
  const [selectVal_details, setSelectVal_details] = useState([]);
  {
    /* see all details modal(view) */
  }
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [view_id, setView_id] = useState();
  const [selectVal, setSelectval] = useState([]);
  const [status_id, setStatus_id] = useState();
  // pass status model render
  const [openModal, setOpenModal] = useState(true);
  const [apicol, setApiCol] = useState([]);
  const [rerendarApi, setRerendarApi] = useState(true);

  // filter data for retrive in table
  const filter_apistate = apistate.filter((val) => {
    return val.status !== "trash";
  });

  // status modal handler
  const handleShow = (row) => {
    setStatus_id(row.id);
    setOpenModal((openModal) => !openModal);
    setSelectval(row);
  };

  // show view modal;
  const handleShow_view_modal = (row) => {
    setView_id(row.id);
    setSelectVal_details(row);
    set_details_modal_Show((details_modal_show) => !details_modal_show);
    console.log(row);
  };

  {
    /* data receve from store */
  }
  useEffect(() => {
    // call api and response data set " setApiData(your res.data) " and column setApiCol( columns )
    setApiData(apidata);
    setApiCol(columns);
  }, [rerendarApi]);

  const rerender = (e) => {
    setRerendarApi(!rerendarApi);
  };

  const brad = [
    {
      name: "home",
    },
    {
      name: title,
    },
  ];
  const columns = [
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Full Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.payment_method,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Charged Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Transaction Id",
      selector: (row) => row.transaction_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <>
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              row.status.toLowerCase() == "pending" && "btn-primary"
            } ${row.status.toLowerCase() == "complete" ? "btn-success" : ""}  ${
              row.status.toLowerCase() == "refunded" ? "btn-danger" : ""
            }  ${row.status.toLowerCase() == "void" ? "btn-danger" : ""} ${
              row.status.toLowerCase() == "trash" ? "btn-danger" : ""
            } btn-sm`}
          >
            {row.status}
          </button>
        </>
      ),
    },
    {
      name: "action",
      cell: (row) => (
        <>
          <button
            onClick={() => handleShow_view_modal(row)}
            className=" btn btn-primary btn-sm"
          >
            view
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      {/* status modal component */}
      <Status_modal
        rerendar={(e) => rerender(e)}
        status_id={status_id}
        row={selectVal}
        openModal={openModal}
      />
      {/* view modal */}
      <Details_modal
        rerendar={(e) => rerender(e)}
        view_id={view_id}
        row={selectVal_details}
        openDetailsModal={details_modal_show}
      />

      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <Btn_grp active_btn="all" btn_link={btn_link} />
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
              </div>
              <div className="card-body">
                <DataTable_Component
                  search="name"
                  apidata={filter_apistate}
                  columns={apicol}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
