import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,     
        transaction: 'dsfasdfasdgg',    
        status: "complete",      
        date:"1-10-23",
    },
    {
        id:2,
        transaction: 'dfrewfgsdgg',    
        status: "pending",      
        date:"1-10-23",
    },
    {
        id:9,
        transaction: 'qedfasdgg',    
        status: "complete",      
        date:"1-10-23",
    },
    
]
