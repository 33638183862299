import { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../../components/breadcrumb/index'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faArrowsRetweet, } from '@fortawesome/free-solid-svg-icons'

// import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
// import {Modal} from 'bootstrap'



export default function InvoiceLink() {

    const title = "Custom Css"
    const [apistate, setApiState] = useState([])
    {/* data table column name */ }
    const [apicol, setApiCol] = useState([])

    const [rerendarApi, setRerendarApi] = useState(false)

    {/* data receve from store */ }
    useEffect(() => {
        setApiState('apidata')

        console.log("RENDER FROM STORE TRASH")
    }, [rerendarApi])


    const updatecss = () => {
        setRerendarApi(!rerendarApi)              
        Swal.fire('Saved!', '', 'success')   
    }

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
   
    return (
        <>


            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                            </div>
                            <div className="card-body">
                            <div className="row">
                                    <div  className="col-12 mt-3">
                                        <textarea className="form-control " rows="6"></textarea>
                                    </div>
                                </div>
                                <button onClick={updatecss} className="btn btn-primary btn-sm mt-2">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}