import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import Breadcrumb from '../../components/breadcrumb/index'
import {useNavigate} from 'react-router-dom'

export default function Add_invoice() {
    const title = "Add Invoice"
    const navigate=useNavigate()
    const[rerendarApi,setRerendarApi]=useState(false)
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

    {/* data receve from store */ }
    useEffect(() => {
        console.log("render from invoice")
    }, [rerendarApi])

    const add_invoice=()=>{
        Swal.fire({
            icon:'warning',
            title: 'You wont be able to revert this!',       
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            setRerendarApi(!rerendarApi)
            if (result.isConfirmed) {
              Swal.fire('Saved!', '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }

    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />
                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        <div className="btn btn-info btn-sm" onClick={()=>navigate(-1)}>Back</div>

                    </div>
                    <div className="card-body">
                        <div  className="row justify-content-center">
                            <div  className="col-md-6 mt-3">
                                <label ><b>select brand</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>
                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label ><b>Full name</ b></label>
                                <input type="text" placeholder="Full Name" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label ><b>Email or Number</ b></label>

                                <input type="text" placeholder="Email or Number" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label><b>Amount</ b></label>
                                <input type="number" placeholder="Amount" className="form-control" />
                            </div>
                            <div  className="col-12 mt-3">
                                <label ><b>description</ b></label>
                                <textarea type="text" placeholder="description" rows="5" className="form-control" ></textarea>
                            </div>
                        </div>
                        <button onClick={add_invoice} className="btn btn-primary btn-sm mt-3">Add Invoice</button>
                    </div>
                </div>
            </div>

        </>
    )
}