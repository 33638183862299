import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function FilterModal({
  show,
  onHide,
  statusFilter,
  setStatusFilter,
 
  applyFilters,
  removeFilters,

}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="statusFilter">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All Leaves</option>
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
            </Form.Control>
          </Form.Group>

        

        
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
