import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Layouts from "../../layouts/layouts";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Defaulter() {
  const { driverId } = useParams();
  const [history, setHistory] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [plateNumber, setPlateNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);

  const formatTime = (dateString) => {
    if (!dateString) return "N/A";
    return moment(dateString).format("HH:mm:ss");
  };

  const resetFilters = () => {
    setFromDate(null);
    setToDate(null);
  };

  const filteredHistory = history.filter((record) => {
    const recordDate = moment(record.date); // Use the actual field name for the date in your data
    const fromDateValid = fromDate ? moment(fromDate) : null;
    const toDateValid = toDate ? moment(toDate).endOf("day") : null; // Use .endOf("day") to include the entire day

    const withinDateRange =
      (!fromDateValid && !toDateValid) || // No filter applied
      (fromDateValid &&
        !toDateValid &&
        recordDate.isSameOrAfter(fromDateValid)) || // Only fromDate is applied
      (!fromDateValid &&
        toDateValid &&
        recordDate.isSameOrBefore(toDateValid)) || // Only toDate is applied
      (fromDateValid &&
        toDateValid &&
        recordDate.isBetween(fromDateValid, toDateValid, "day", "[]")); // Both dates applied

    return withinDateRange; // Return true if record falls within the date range
  });

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/notifications/driver/${driverId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("API Response:", response.data); // Log API response
      if (Array.isArray(response.data.notifications)) {
        setHistory(response.data.notifications);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "fromDate") {
      setFromDate(value ? moment(value).toDate() : null);
    } else if (name === "toDate") {
      setToDate(value ? moment(value).toDate() : null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [driverId, fromDate, toDate]);

  const columns = [
    {
      name: <b>Number Plate</b>,
      selector: (row) => row.vehicleData?.VehicleNumber,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.vehicleData?.VehicleNumber}
        </div>
      ),
    },
    {
      name: <b>Phone Number</b>,
      selector: (row) => row.vehicleData?.DriverId?.id_user?.phonenumber,
      sortable: true,
    },
    {
      name: <b>Date</b>,
      selector: (row) => {
        const date = new Date(row.date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
      sortable: true,
    },
    {
      name: <b>Assigned Location</b>,
      selector: (row) => row.vehicleData?.AssignedLocation,
      sortable: true,
    },
    {
      name: <b>Current Location</b>,
      cell: (row) => (
        <div
          style={{
            maxWidth: "200px",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          {row.vehicleData?.CurrentLocation || "N/A"}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                {plateNumber ? `${plateNumber}` : "Default History"}
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <label>From Date:</label>
                    <input
                      type="date"
                      name="fromDate"
                      value={
                        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
                      }
                      onChange={handleDateChange}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                  <div>
                    <label>To Date:</label>
                    <input
                      type="date"
                      name="toDate"
                      value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
                      onChange={handleDateChange}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={filteredHistory}
                  pagination
                  fixedHeader
                  highlightOnHover
                  responsive
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
