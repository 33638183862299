import {useState} from 'react'
import Hero from './hero'
import '../../App.css'
import './dashboard.css'
import Layouts from '../../layouts/layouts'

export default function Dashboard() {

    return (  
        <>
        <Layouts/>
        < Hero />

        </>    
        
    )
}