import React from "react";
import { Modal, Button } from "react-bootstrap";

const GeneralStaffDetailModal = ({ show, onHide, generalstaff }) => {
  if (!generalstaff) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>General Staff Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Vendor Name:</strong>{" "}
          {generalstaff.id_user ? generalstaff.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Phone Number:</strong>{" "}
          {generalstaff.id_user ? generalstaff.id_user.phonenumber : "N/A"}
        </p>
        <p>
          <strong>Home Number:</strong> {generalstaff.homenumber}
        </p>
        <p>
          <strong>Temporary Address:</strong> {generalstaff.tempaddress}
        </p>
        <p>
          <strong>State:</strong> {generalstaff.state}
        </p>
        <p>
          <strong>City:</strong> {generalstaff.city}
        </p>
        <p>
          <strong>Permanent Address:</strong> {generalstaff.permanentaddress}
        </p>
        <p>
          <strong>User Email</strong>{" "}
          {generalstaff.id_user ? generalstaff.id_user.email : "N/A"}
        </p>
        <p>
          <strong>Per Packet:</strong> {generalstaff.perpacket}
        </p>
        <p>
          <strong>Salary:</strong> {generalstaff.salary}
        </p>
        <p>
          <strong>Description:</strong> {generalstaff.description}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GeneralStaffDetailModal;
