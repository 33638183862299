import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/DataTable/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Update_data_modal from "../../components/manageInvoice/update_data_modal/index";
import Swal from "sweetalert2";
import { apidata } from "./store/store";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";

export default function Utilization() {
  const title = "Utilization Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const brad = [
    {
      name: "home",
    },
    {
      name: title,
    },
  ];
  const columns = [
    {
      name: "Utilization ID",
      selector: (row) => row.id_utilization,
      sortable: true,
    },
    {
      name: "Vehicle ID ",
      selector: (row) => row.id_vehicle,
      sortable: true,
    },
    {
      name: "Driver ID",
      selector: (row) => row.id_driver,
      sortable: true,
    },
    {
      name: "Total Km",
      selector: (row) => row.totalKm,
      sortable: true,
    },
    {
      name: "Earnings",
      selector: (row) => row.earnings,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "CreatedDate",
      selector: (row) => row.createddate,
      sortable: true,
    },
    {
      name: "Last Latitude ",
      selector: (row) => row.lastlatitude,
      sortable: true,
    },
    {
      name: "Last Longitude",
      selector: (row) => row.lastlongitude,
      sortable: true,
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  useEffect(() => {
    setApiState(apidata);
    setApiCol(columns);
    console.log("render from Utilization");
  }, []);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/${row._id}`)
          .then(() => {
            Swal.fire("Deleted!", "Vendor has been deleted.", "success");
          })
          .catch((error) => {
            Swal.fire("Error!", "Vendor could not be deleted.", "error");
          });
      }
    });
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Update_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                {/* <Link to="/create-vendor">
                  <div className="btn btn-info btn-sm text-white">
                    Add Vendor
                  </div>
                </Link> */}
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    title="Utilization"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
