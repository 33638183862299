import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Layouts from "../../layouts/layouts";
import moment from "moment";
import DataTable from 'react-data-table-component';

export default function LeaveHistory() {
  const { id } = useParams();

  const [history, setHistory] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [userName, setUserName] = useState("");
  const [statusCounts, setStatusCounts] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
    total: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState(null);

  const fetchHistory = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/leave-requests/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const leaveRequests = response.data.leave?.leaveRequests;
      const userName = response.data.leave?.id_user?.name || "Unknown User";
      setUserName(userName);

      if (Array.isArray(leaveRequests)) {
        const leaveRequestsWithUserName = leaveRequests.map((leave) => ({
          ...leave,
          userName,
        }));
        setHistory(leaveRequestsWithUserName);

        // Calculate status counts
        const counts = leaveRequests.reduce(
          (acc, leave) => {
            if (leave.status === "Pending") acc.pending += 1;
            if (leave.status === "Approved") acc.approved += 1;
            if (leave.status === "Rejected") acc.rejected += 1;
            acc.total += 1;
            return acc;
          },
          { pending: 0, approved: 0, rejected: 0, total: 0 }
        );

        setStatusCounts(counts);
      } else {
        console.error("No leave requests found in response");
        setHistory([]);
        setStatusCounts({ pending: 0, approved: 0, rejected: 0, total: 0 });
      }
    } catch (error) {
      console.error("Error fetching leave history: ", error);
      setHistory([]);
      setStatusCounts({ pending: 0, approved: 0, rejected: 0, total: 0 });
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [id]);

  const filteredHistory = history.filter((record) => {
    const recordDate = moment(record.startDate);
    const fromDateValid = fromDate ? moment(fromDate) : null;
    const toDateValid = toDate ? moment(toDate) : null;
    const statusMatch = selectedStatus ? record.status === selectedStatus : true;

    const withinDateRange = (
      (!fromDateValid && !toDateValid) ||
      (fromDateValid && toDateValid && recordDate.isBetween(fromDateValid, toDateValid, 'days', '[]')) ||
      (fromDateValid && !toDateValid && recordDate.isSameOrAfter(fromDateValid)) ||
      (!fromDateValid && toDateValid && recordDate.isSameOrBefore(toDateValid))
    );

    return statusMatch && withinDateRange;
  });

  const resetFilters = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedStatus(null);
  };

  // DataTable columns
  const columns = [
    {
      name: <b>Start Date </b>,
      selector: row => moment(row.startDate).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>End Date</b>,
      selector: row => moment(row.endDate).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>Leave Type</b>,
      selector: row => row.id_leaveType?.name || "N/A",
      sortable: true,
    },
    {
      name: <b>Reason</b>,
      selector: row => row.reason || "N/A",
      sortable: true,
    },
    {
      name: <b>Status</b>,
      selector: row => row.status || "N/A",
      sortable: true,
    },
    {
      name: <b>Total Days</b>,
      selector: row => row.daysRequested || "N/A",
      sortable: true,
    },
  ];

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                <h4>
                  {userName ? `${userName}'s Leave History` : "Leave History"}
                </h4>

                <div className="d-flex align-items-center">
                 
                  <div className="status-badges d-flex me-3">
                    <div
                      className=" leave-badge badge bg-warning text-dark d-flex align-items-center justify-content-center"
                      onClick={() => setSelectedStatus("Pending")}
                      style={{ cursor: "pointer" }}
                    >
                      Pending{" "}
                      <span className="leave-badge  badge ms-2">
                        {statusCounts.pending}
                      </span>
                    </div>
                    <div
                      className="leave-badge badge bg-success text-light d-flex align-items-center justify-content-center ms-2"
                      onClick={() => setSelectedStatus("Approved")}
                      style={{ cursor: "pointer" }}
                    >
                      Approved{" "}
                      <span className="leave-badge badge ms-2">
                        {statusCounts.approved}
                      </span>
                    </div>
                    <div
                      className="leave-badge badge bg-danger text-light d-flex align-items-center justify-content-center ms-2"
                      onClick={() => setSelectedStatus("Rejected")}
                      style={{ cursor: "pointer" }}
                    >
                      Rejected{" "}
                      <span className="leave-badge badge ms-2">
                        {statusCounts.rejected}
                      </span>
                    </div>
                    <div
                      className="leave-badge badge bg-primary text-light d-flex align-items-center justify-content-center ms-2"
                      onClick={resetFilters}
                      style={{ cursor: "pointer" }}
                    >
                      Total{" "}
                      <span className="leave-badge badge ms-2">
                        {statusCounts.total}
                      </span>
                    </div>
                  </div>
                  <div className="me-2">
                    <label>From Date:</label>
                    <input
                      type="date"
                      value={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
                      onChange={(e) => setFromDate(e.target.value)}
                      className="form-control"
                      style={{ width: '150px' }}
                    />
                  </div>
                  <div>
                    <label>To Date:</label>
                    <input
                      type="date"
                      value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
                      onChange={(e) => setToDate(e.target.value)}
                      className="form-control"
                      style={{ width: '150px' }}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={filteredHistory}
                  pagination
                  fixedHeader
                  highlightOnHover
                  responsive
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
