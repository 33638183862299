import Breadcrumb from '../../../components/breadcrumb/index'
export default function MailSetting() {
    const title = "Lincese"
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />

                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        
                    </div>
                    <div className="card-body">
                       
                          <div className="jumbotron my-2">
                              <span>License Activated</span><br />
                              <span><b>Panel:</b>License Activated</span><br />
                              <span><b>License Code:</b>License Activated</span><br />
                              <span><b>Domains:</b>License Activated</span><br />
                              <span><b>Domain Limit:</b>License Activated</span><br />
                              <span><b>Expire Date:</b>License Activated</span>
                              
                          </div>
                      
                    </div>
                </div>
            </div>

        </>
    )
}