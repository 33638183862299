import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Layouts from "../../layouts/layouts";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEnvelope,
  faCopy,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

export default function UserHistory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [userid, setUserid] = useState("");

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const formatTime = (dateString) => {
    if (!dateString) {
      return "N/A";
    }
    const formattedTime = moment(dateString).format("HH:mm:ss");
    return formattedTime;
  };
  const fetchHistory = async (from, to) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const params = {};
      if (from) params.from = moment(from).format("YYYY-MM-DD");
      if (to) params.to = moment(to).format("YYYY-MM-DD");

      console.log("Fetching data with params:", params); // Debugging point

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/history/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      );

      console.log("Full API response:", response); // Debugging point

      if (!response.data) {
        console.error("Response does not contain data:", response); // Debugging point
        setHistory([]);
        return;
      }

      const userHistory = response.data.history; // Correct key is 'history'
      if (!userHistory || !Array.isArray(userHistory)) {
        console.error("Invalid data format in response:", response.data); // Debugging point
        setHistory([]);
        return;
      }

      // Filter data based on dates
      const filteredHistory = userHistory.filter((record) => {
        const recordDate = moment(record.date); // Assuming 'date' field in the record
        if (from && to) {
          return recordDate.isBetween(moment(from), moment(to), "days", "[]");
        } else if (from) {
          return recordDate.isSameOrAfter(moment(from), "day");
        } else if (to) {
          return recordDate.isSameOrBefore(moment(to), "day");
        }
        return true; // If no dates are provided, return all records
      });

      console.log("Filtered history:", filteredHistory); // Debugging point

      const userid =
        filteredHistory.length > 0
          ? filteredHistory[0]?.id_user?._id
          : "User Id";
      setUserid(userid);
      setHistory(filteredHistory);

      console.log("State updated with filtered history:", filteredHistory); // Debugging point
    } catch (error) {
      console.error("Error fetching user history:", error); // Debugging point
      setHistory([]);
    }
  };

  // const fetchHistory = async (from, to) => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     if (!token) {
  //       console.error("No token found");
  //       return;
  //     }

  //     const params = {};
  //     if (from) params.from = moment(from).format("YYYY-MM-DD");
  //     if (to) params.to = moment(to).format("YYYY-MM-DD");

  //     console.log("Fetching data with params:", params); // Debugging point

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/user/history/${id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         params,
  //       }
  //     );

  //     console.log("Full API response:", response); // Debugging point

  //     // Check if response has a 'data' field
  //     if (!response.data) {
  //       console.error("Response does not contain data:", response); // Debugging point
  //       setHistory([]);
  //       return;
  //     }

  //     // Check if 'history' exists and is an array
  //     const userHistory = response.data.history; // Correct key is 'history'
  //     if (!userHistory || !Array.isArray(userHistory)) {
  //       console.error("Invalid data format in response:", response.data); // Debugging point
  //       setHistory([]);
  //       return;
  //     }

  //     console.log("User history fetched:", userHistory); // Debugging point

  //     // Safely check for user ID and set state
  //     const userid =
  //       userHistory.length > 0 ? userHistory[0]?.id_user?._id : "User Id";
  //     setUserid(userid);

  //     // Set the user history to state
  //     setHistory(userHistory);
  //     console.log("State updated with user history:", userHistory); // Debugging point
  //   } catch (error) {
  //     console.error("Error fetching vehicle history:", error); // Debugging point
  //     setHistory([]);
  //   }
  // };

  useEffect(() => {
    fetchHistory(fromDate, toDate);
  }, [id, fromDate, toDate]);

  const columns = [
    {
      name: <b>Date</b>,
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>Check-IN</b>,
      selector: (row) => formatTime(row.check_in),
      sortable: true,
    },
    {
      name: <b>Check-OUT</b>,
      selector: (row) => formatTime(row.check_out),
      sortable: true,
    },
    {
      name: <b>Total Hours</b>,
      selector: (row) =>
        row.total_hours ? Math.round(row.total_hours) : "N/A",
      sortable: true,
    },
    {
      name: <b>Is Absent</b>,
      selector: (row) => (row.isAbsent ? "Yes" : "No"),
      sortable: true,
    },
  ];

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                {userid ? `${userid}` : "User History"}
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <label>From Date:</label>
                    <input
                      type="date"
                      value={
                        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
                      }
                      onChange={(e) => setFromDate(e.target.value)}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                  <div>
                    <label>To Date:</label>
                    <input
                      type="date"
                      value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
                      onChange={(e) => setToDate(e.target.value)}
                      className="form-control"
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={history}
                  pagination
                  fixedHeader
                  highlightOnHover
                  responsive
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
