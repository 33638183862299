// // import { useState, useContext } from "react";
// // import { Link, useNavigate } from "react-router-dom";
// // import Swal from "sweetalert2";
// // import axios from "axios";
// // import { AuthContext } from "./AuthContext";

// // export default function Login() {
// //   const [email, setEmail] = useState("");
// //   const [otp, setOtp] = useState("");
// //   const [isOtpGenerated, setIsOtpGenerated] = useState(false);
// //   const navigate = useNavigate();
// //   const { login } = useContext(AuthContext);

// //   const generateOtpHandle = async () => {
// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_BASE_URL}/api/v1/user/login`,
// //         {
// //           email,
// //         }
// //       );

// //       if (response.status === 200) {
// //         Swal.fire("OTP sent", "Please check your email for the OTP", "success");
// //         setIsOtpGenerated(true);
// //       } else {
// //         Swal.fire("Failed to send OTP", "Please try again", "error");
// //       }
// //     } catch (error) {
// //       console.error("Error generating OTP:", error);
// //       Swal.fire(
// //         "Failed to send OTP",
// //         "An error occurred. Please try again later.",
// //         "error"
// //       );
// //     }
// //   };

// //   const loginHandle = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_BASE_URL}/api/v1/user/verifyLoginOTP`,
// //         {
// //           email,
// //           otp,
// //         }
// //       );

// //       if (response.status === 200) {
// //         Swal.fire("Login success", "", "success");
// //         login(response.data.token);
// //         localStorage.setItem("token", response.data.token);
// //         navigate("/");
// //       } else {
// //         Swal.fire("Login failed", "Please check your credentials", "error");
// //       }
// //     } catch (error) {
// //       console.error("Error logging in:", error);
// //       Swal.fire(
// //         "Login failed",
// //         "An error occurred. Please try again later.",
// //         "error"
// //       );
// //     }
// //   };

// //   return (
// //     <div className="container">
// //       <div
// //         className="row justify-content-center align-items-center jumbotron"
// //         style={{ height: "65vh" }}
// //       >
// //         <div className="col-md-5">
// //           <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
// //             <div className="card-header border-bottom text-center">
// //               <h2>Login</h2>
// //             </div>
// //             <div className="card-body">
// //               <input
// //                 type="text"
// //                 placeholder="Enter Your Email"
// //                 className="form-control"
// //                 value={email}
// //                 onChange={(e) => setEmail(e.target.value)}
// //               />
// //               <button
// //                 type="button"
// //                 onClick={generateOtpHandle}
// //                 className="btn btn-secondary mt-2"
// //               >
// //                 Generate OTP
// //               </button>
// //               {isOtpGenerated && (
// //                 <>
// //                   <input
// //                     type="text"
// //                     placeholder="Enter OTP"
// //                     className="form-control my-3"
// //                     value={otp}
// //                     onChange={(e) => setOtp(e.target.value)}
// //                   />
// //                 </>
// //               )}
// //               <div>
// //                 <button
// //                   type="submit"
// //                   onClick={loginHandle}
// //                   className="btn btn-primary mt-2"
// //                 >
// //                   Login
// //                 </button>
// //               </div>

// //               <Link to="/forgot-password">
// //                 <p>Forgot Password</p>
// //               </Link>
// //               <Link to="/register">
// //                 <p> Register </p>
// //               </Link>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// import { useState, useContext } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { AuthContext } from "./AuthContext";

// export default function Login() {
//   const [email, setEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [isOtpGenerated, setIsOtpGenerated] = useState(false);
//   const navigate = useNavigate();
//   const { login } = useContext(AuthContext);

//   const generateOtpHandle = async () => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/user/login`,
//         {
//           email,
//         }
//       );

//       if (response.status === 200) {
//         Swal.fire("OTP sent", "Please check your email for the OTP", "success");
//         setIsOtpGenerated(true);
//       } else {
//         Swal.fire("Failed to send OTP", "Please try again", "error");
//       }
//     } catch (error) {
//       console.error("Error generating OTP:", error);
//       Swal.fire(
//         "Failed to send OTP",
//         "An error occurred. Please try again later.",
//         "error"
//       );
//     }
//   };

//   const loginHandle = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/user/verifyLoginOTP`,
//         {
//           email,
//           otp,
//         }
//       );

//       if (response.status === 200) {
//         Swal.fire("Login success", "", "success");
//         login(response.data.token);
//         localStorage.setItem("token", response.data.token);
//         navigate("/");
//       } else {
//         Swal.fire("Login failed", "Please check your credentials", "error");
//       }
//     } catch (error) {
//       console.error("Error logging in:", error);
//       Swal.fire(
//         "Login failed",
//         "An error occurred. Please try again later.",
//         "error"
//       );
//     }
//   };

//   return (
//     <div
//       className="container"

//     >
//       <div
//         className="row justify-content-center align-items-center jumbotron"
//         style={{ height: "65vh" }}
//       >
//         <div className="col-md-5">
//           <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
//             <div className="card-header border-bottom text-center">
//               <h2>Login</h2>
//             </div>
//             <div className="card-body">
//               <input
//                 type="text"
//                 placeholder="Enter Your Email"
//                 className="form-control"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <button
//                 type="button"
//                 onClick={generateOtpHandle}
//                 className="btn btn-secondary mt-2"
//               >
//                 Generate OTP
//               </button>
//               {isOtpGenerated && (
//                 <>
//                   <input
//                     type="text"
//                     placeholder="Enter OTP"
//                     className="form-control my-3"
//                     value={otp}
//                     onChange={(e) => setOtp(e.target.value)}
//                   />
//                 </>
//               )}
//               <div>
//                 <button
//                   type="submit"
//                   onClick={loginHandle}
//                   className="btn btn-primary mt-2"
//                 >
//                   Login
//                 </button>
//               </div>

//               <Link to="/forgot-password">
//                 <p>Forgot Password</p>
//               </Link>
//               <Link to="/register">
//                 <p> Register </p>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// import { useState, useContext } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { AuthContext } from "./AuthContext";

// export default function Login() {
//   const [email, setEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [isOtpGenerated, setIsOtpGenerated] = useState(false);
//   const navigate = useNavigate();
//   const { login } = useContext(AuthContext);

//   const generateOtpHandle = async () => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/user/login`,
//         {
//           email,
//         }
//       );

//       if (response.status === 200) {
//         Swal.fire("OTP sent", "Please check your email for the OTP", "success");
//         setIsOtpGenerated(true);
//       } else {
//         Swal.fire("Failed to send OTP", "Please try again", "error");
//       }
//     } catch (error) {
//       console.error("Error generating OTP:", error);
//       Swal.fire(
//         "Failed to send OTP",
//         "An error occurred. Please try again later.",
//         "error"
//       );
//     }
//   };

//   const loginHandle = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/user/verifyLoginOTP`,
//         {
//           email,
//           otp,
//         }
//       );

//       if (response.status === 200) {
//         Swal.fire("Login success", "", "success");
//         login(response.data.token);
//         localStorage.setItem("token", response.data.token);
//         navigate("/");
//       } else {
//         Swal.fire("Login failed", "Please check your credentials", "error");
//       }
//     } catch (error) {
//       console.error("Error logging in:", error);
//       Swal.fire(
//         "Login failed",
//         "An error occurred. Please try again later.",
//         "error"
//       );
//     }
//   };

//   return (
//     <div
//       style={{
//         backgroundImage: `url('/imgpickkup.png')`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         height: '100vh',
//         display: 'flex',
//         justifyContent: 'flex-end',
//         alignItems: 'center'
//       }}
//     >
//       <div style={{ width: '30%', marginRight: '5%' }}>
//         <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
//           <div className="card-header border-bottom text-center">
//             <h2>Login</h2>
//           </div>
//           <div className="card-body">
//             <input
//               type="text"
//               placeholder="Enter Your Email"
//               className="form-control"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <button
//               type="button"
//               onClick={generateOtpHandle}
//               className="btn btn-secondary mt-2"
//             >
//               Generate OTP
//             </button>
//             {isOtpGenerated && (
//               <>
//                 <input
//                   type="text"
//                   placeholder="Enter OTP"
//                   className="form-control my-3"
//                   value={otp}
//                   onChange={(e) => setOtp(e.target.value)}
//                 />
//               </>
//             )}
//             <div>
//               <button
//                 type="submit"
//                 onClick={loginHandle}
//                 className="btn btn-primary mt-2"
//               >
//                 Login
//               </button>
//             </div>

//             <Link to="/forgot-password">
//               <p>Forgot Password</p>
//             </Link>
//             <Link to="/register">
//               <p> Register </p>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { AuthContext } from "./AuthContext";

export default function Login() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const generateOtpHandle = async () => {
    try {
	console.log("${process.env.REACT_APP_BASE_URL}", `${process.env.REACT_APP_BASE_URL}`)    
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/login`,
        {
          email,
        }
      );
      
      if (response.status === 200) {
	console.log("response", response)      
        Swal.fire("OTP sent", "Please check your email for the OTP", "success");
        setIsOtpGenerated(true);
      } else {
        Swal.fire("Failed to send OTP", "Please try again", "error");
      }
    } catch (error) {
      console.error("Error generating OTP:", error);
      Swal.fire(
        "Failed to send OTP",
        "An error occurred. Please try again later.",
        "error"
      );
    }
  };

  const loginHandle = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/verifyLoginOTP`,
        {
          email,
          otp,
        }
      );

      if (response.status === 200) {
        Swal.fire("Login success", "", "success");
        login(response.data.token);
        localStorage.setItem("token", response.data.token);
        navigate("/");
      } else {
        Swal.fire("Login failed", "Please check your credentials", "error");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      Swal.fire(
        "Login failed",
        "An error occurred. Please try again later.",
        "error"
      );
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url('/imgpickkup.png')`,
        backgroundSize: "cover",
       
        height: "100vh",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
       
      }}
    >
      <div style={{ width: "30%", marginRight: "5%" }}>
        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
          <div className="card-header border-bottom text-center">
            <h1>
              <b>Login</b>
            </h1>
          </div>
          <div className="card-body">
            <input
              type="text"
              placeholder="Enter Your Email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              <button
                type="button"
                onClick={generateOtpHandle}
                className="btn btn-secondary"
              >
                Generate OTP
              </button>
              <button
                type="submit"
                onClick={loginHandle}
                className="btn btn-primary"
              >
                Login
              </button>
            </div>
            {isOtpGenerated && (
              <>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="form-control my-3"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </>
            )}
            <div>
              {/* <button
                type="submit"
                onClick={loginHandle}
                className="btn btn-primary mt-2"
              >
                Login
              </button> */}
            </div>

            {/* <Link to="/forgot-password">
              <p>Forgot Password</p>
            </Link>
            <Link to="/register">
              <p> Register </p> */}
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
