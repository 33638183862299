import React from "react";
import { Modal, Button } from "react-bootstrap";

const DriverDetailModal = ({ show, onHide, driver }) => {
  if (!driver) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Driver Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <p>
          <strong>Driver Name:</strong> {driver.drivername}
        </p>
        <p>
          <strong>Phone Number:</strong> {driver.phonenumber}
        </p> */}
        <p>
          <strong>Driver Name:</strong>{" "}
          {driver.id_user ? driver.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Phone Number:</strong>{" "}
          {driver.id_user ? driver.id_user.phonenumber : "N/A"}
        </p>
        <p>
          <strong>Home Number:</strong> {driver.homephonenumber}
        </p>
        <p>
          <strong>Temporary Address:</strong> {driver.tempaddress}
        </p>
        <p>
          <strong>Country:</strong> {driver.country}
        </p>
        <p>
          <strong>State:</strong> {driver.state}
        </p>
        <p>
          <strong>City:</strong> {driver.city}
        </p>
        <p>
          <strong>Joining Date:</strong> {driver.joiningDate}
        </p>
        <p>
          <strong>Permanent Address:</strong> {driver.permanentaddress}
        </p>
        <p>
          <strong>Vendor Assigned:</strong>{" "}
          {driver.id_vendor ? driver.id_vendor.vendorname : "N/A"}
        </p>
        <p>
          <strong>Status:</strong> {driver.status}
        </p>

        <p>
          <strong>Coordinates:</strong>{" "}
          {driver.coordinates ? (
            <>
              <span>
                <strong>Latitude:</strong> {driver.coordinates.latitude}
              </span>
              <span>, </span>
              <span>
                <strong>Longitude:</strong> {driver.coordinates.longitude}
              </span>
            </>
          ) : (
            <span>N/A</span>
          )}
        </p>
        <p>
          <strong>Created By:</strong>{" "}
          {driver.createdBy && (
            <>
              <span>
                <strong>Name:</strong> {driver.createdBy.user_email}
              </span>
              <span>, </span>
              <span>
                <strong>Role:</strong> {driver.createdBy.user_role}
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Created Date:</strong>{" "}
          {driver.createdAt && (
            <>{new Date(driver.createdAt).toLocaleDateString()}</>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DriverDetailModal;
