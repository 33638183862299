import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import DataTable_Component from "../../components/leaves/Data Table Component";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layouts from "../../layouts/layouts";

import {

  faHistory,

} from "@fortawesome/free-solid-svg-icons";


export default function Leaves() {
  const navigate = useNavigate();
  const title = "User Leaves";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
 
  const [currentPage, setCurrentPage] = useState(1);


  
 

  const handleStatusChange = async (leaveId, status) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/approve-leave/${leaveId}`,
        { status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchData(currentPage); // Refresh data after status change
    } catch (error) {
      console.error("Error updating leave status: ", error);
    }
  };

  const columns = [
    {
      name: <b>User Name</b>,
      selector: (row) => row.userName,
      sortable: true,
    },
    {
      name: <b>Phone Number</b>,
      selector: (row) => row.userPhone || "N/A",
      sortable: true,
    },
    {
      name: <b>Leave Type</b>,
      selector: (row) => row
      ?.id_leaveType
      ?.name ||"N/A",
      sortable: true,
    },
    {
      name: <b>From</b>,
      selector: (row) => moment(row.startDate).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>To</b>,
      selector: (row) => moment(row.endDate).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>Total Days</b>,
      selector: (row) => row.daysRequested || "N/A",
      sortable: true,
    },
    {
      name: <b>Reason</b>,
      selector: (row) => row.reason || "N/A",
      sortable: true,
    },
 
    {
        name: <b>Status</b>,
        cell: (row) => {
          const getStatusStyle = (status) => {
            switch (status) {
              case 'Pending':
                return { backgroundColor: 'yellow', color: 'black' };
              case 'Approved':
                return { backgroundColor: 'green', color: 'white' };
              case 'Rejected':
                return { backgroundColor: 'red', color: 'white' };
              default:
                return {};
            }
          };
      
          const handleChange = (e) => {
            handleStatusChange(row._id, e.target.value);
          };
      
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <select
                value={row.status}
                onChange={handleChange}
                disabled={row.status !== 'Pending'}
                style={{
                  border: 'none',
                  backgroundColor: getStatusStyle(row.status).backgroundColor,
                  color: getStatusStyle(row.status).color,
                  padding: '6px',
                  borderRadius: '4px',
                  width: '80px',
                  cursor: row.status === 'Pending' ? 'pointer' : 'not-allowed',
                  appearance: 'none', 
                  WebkitAppearance: 'none', 
                  MozAppearance: 'none',
                  textAlign:"center",
                  fontWeight:"bold"
                }}
              >
                {row.status === 'Pending' && (
                  <>
                    <option value="Pending" style={{ backgroundColor: 'white' }}>Pending</option>
                    <option value="Approved" style={{ backgroundColor: 'white' }}>Approved</option>
                    <option value="Rejected" style={{ backgroundColor: 'white' }}>Rejected</option>
                  </>
                )}
                {row.status !== 'Pending' && (
                  <option value={row.status}>{row.status}</option>
                )}
              </select>
            </div>
          );
        },
        sortable: true,
      }
   
    
,      
    {
      name: <b>History</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => handleLeaveHistory(row)}
            className="btn btn-warning btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faHistory} />
          </button>
        </>
      ),
    },
  ];


  


const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
  
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/leave-requests`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (Array.isArray(response.data.leaveRequests)) {
        const latestLeaves = response.data.leaveRequests
        .filter(user => user.id_user !== null) // Filter out leave requests with null id_user
        .map(user => {
          const latestLeave = user.leaveRequests.reduce((latest, current) => 
            new Date(latest.updatedAt) > new Date(current.updatedAt) ? latest : current
          );
          return {
            ...latestLeave,
            userName: user.id_user.name,
            userId: user.id_user._id,
            userPhone: user.id_user.phonenumber,
          };
        });
      
        setApiState(latestLeaves);
      } else {
        console.error("Unexpected data format from API", response.data);
        setApiState([]); // Set empty array on unexpected data format
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setApiState([]); // Set empty array on error
    }
  };
  
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

 

 

  
 

  const handleLeaveHistory = (row) => {
  
    navigate(`/leavehistory/${row.userId}`);
    // navigate(`/leavehistory/${row.userId}`);
  };

  

  return (
    <>
      <Layouts />
     
    
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                <h4>{title}</h4>
                
              </div>
              <div className="card-body">
                {apistate && apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    title="Leave List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
