import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/DataTable/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Add_data_modal from "../../components/vehicles/update_data_modal/index";
import Email_sender_data_modal from "../../components/manageInvoice/send_email/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

export default function VehicleType() {
  const title = "Vehicle Type List";
  const [apistate, setApiState] = useState([]); // Ensure this is an array initially
  const [apicol, setApiCol] = useState([]); // Ensure this is an array initially
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  // const resultPerPage = 5;

  const brad = [{ name: "Home" }, { name: title }];

  const columns = [
    { name: "Vehicle ID", selector: (row) => row.id_type, sortable: true },
    {
      name: "Vehicle Name",
      selector: (row) => row.vehiclename,
      sortable: true,
    },
    { name: "Company", selector: (row) => row.company, sortable: true },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle-types?page=${page}`
      );
      console.log(response);
      if (Array.isArray(response.data.vehicletype)) {
        setApiState(response.data.vehicletype);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const updateVehicle = (updatedVehicle) => {
    setApiState((prev) =>
      prev.map((vehicle) =>
        vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle
      )
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <>
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateVehicle={updateVehicle}
      />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <Link to="/stored-data">
          <button
            type="button"
            className="btn btn-outline-success active btn-sm"
          >
            All
          </button>
        </Link>
        <Link to="/invoice-link">
          <button type="button" className="btn btn-outline-primary btn-sm ms-1">
            VehicleType Link
          </button>
        </Link>
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                {/* <Link to="/create-vehicle">
                  <div className="btn btn-info btn-sm text-white">
                    Add Vehicle
                  </div>
                </Link> */}
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="numberplate" // Search based on the vehicle's number plate
                    title_btn={true}
                    title="Vehicle Type List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
