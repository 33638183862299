import { useState, useEffect, useRef } from 'react'

import Breadcrumb from '../../components/breadcrumb/index'
import DataTable_Component from '../../components/DataTable/index'
import View_modal from '../../components/manageBrand/view_modal/index'
import Update_data_modal from '../../components/manageBrand/update_data_modal/index'
import Swal from 'sweetalert2'

import { apidata } from './store/store'
import { Link } from 'react-router-dom'
// import {Modal} from 'bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare,faTrash,faEnvelope,faCopy} from '@fortawesome/free-solid-svg-icons'



export default function ManageBrand() {

    const title="Manage Brand"
    const [apistate, setApiState] = useState([])
    {/* data table column name */ }
    const [apicol, setApiCol] = useState([])
    const [selectVal, setSelectval] = useState([])
    // pass status model render
    const [openModal, setOpenModal] = useState(true)
    const[rerendarApi,setRerendarApi]=useState(false)
    const[apikeys,setApiKeys]=useState([])

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    const columns = [
        {
            name: "Name",
            selector: row => row.name,
            sortable: true
        },
        
        {
            name: "Api Details",
            cell: (row) => <><button onClick={() => handleShow(row)} className="btn btn-primary btn-sm" >view</button></>,
        },
        {
            name: "Action",
            cell: (row) => <><Link to={`/edit-brand/${row.id}`}> <button  className="btn btn-primary btn-sm" ><FontAwesomeIcon icon={faPenToSquare} /></button></Link></>,
        }

    ]

    const handleShow = (row) => {
        // call api and response data set " setApiData(your res.data) " and column setApiCol( columns )
        //status model e pass data 
        setOpenModal(openModal => !openModal)
        setSelectval(row)
    }

    {/* data receve from store */ }
    useEffect(() => {
        setApiState(apidata)
        setApiCol(columns)
        setApiKeys({api1:"api1",api2:"api2",api3:"api3",})
        console.log("render from brand")
    }, [rerendarApi])

    const rerender=(e)=>{
        setRerendarApi(e)
    }

    return (
        <>
            {/* status modal component */}
            <View_modal rerendar={(e)=>rerender(e)} apikeys={apikeys} row={selectVal} openModal={openModal} />         
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Link to="/manage-brand" ><button type="button" className="btn mt-2 btn-outline-success active btn-sm ">Manage Brand</button></Link>
                <Link to="/manageFaq" ><button type="button" className="btn mt-2 btn-outline-primary btn-sm ms-1">Manage FAQ</button></Link>
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                                <Link to="/add-brand"><div className="btn btn-info btn-sm text-white">Add Brand</div></Link>
                            </div>
                            <div className="card-body">                        
                                <DataTable_Component search="name"  title_btn="Add stored Data" title={title} apidata={apistate} columns={apicol} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}