import { useState, useEffect, useRef } from 'react'
import { Modal, Button, Form } from "react-bootstrap";
import Swal from 'sweetalert2'

export default function Status_modal({ row, openDetailsModal, modelRender, render }) {

    // re-render state
    const [ren, setRen] = useState("false")
    {/* all data for view */ }
    const [modal_all_data, setModal_all_data] = useState([])
    {/* see all details modal(view) */ }
    const [view_modal_show, set_view_modal_Show] = useState(false);

    // store data;
    useEffect(() => {
        setModal_all_data(row)
    }, [row])

    // show view modal;
    useEffect(() => {
        set_view_modal_Show(openDetailsModal);
    }, [openDetailsModal])

    const handleClose = () => {
        set_view_modal_Show(false);
        modelRender(false)
    }

    // swite alert

    // delete user

    const handleUpdate = () => {

        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to revert this!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                handleClose();
                setRen(!ren);
                render(ren)
                Swal.fire('Verify Changed!. id:' + modal_all_data.id, '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }


    return (
        <>
            {/* status update modal */}
            <Modal show={view_modal_show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select defaultValue={modal_all_data.verified} aria-label="trash">
                        <option value="0">False</option>
                        <option value="1">True</option>

                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="btn btn-sm" onClick={handleUpdate}>
                        Update Status
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}