import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

export default function DataTable_Component({ apidata, columns, searchBy }) {
  const [dataFilter, setDataFilter] = useState("");
  const [filteredData, setFilteredData] = useState(apidata);

  useEffect(() => {
    const filterData = () => {
      if (dataFilter) {
        const filtered = apidata.filter((item) => {
          const searchValue = dataFilter.toLowerCase();
          switch (searchBy) {
            case "city":
              return item.city && item.city.toLowerCase().includes(searchValue);
            case "numberplate":
              return (
                item.numberplate &&
                item.numberplate.toLowerCase().includes(searchValue)
              );
            case "VehicleNumber":
              return (
                item.VehicleNumber &&
                item.VehicleNumber.toLowerCase().includes(searchValue)
              );
            case "name":
              return item.name && item.name.toLowerCase().includes(searchValue);
            case "vendorname":
              return (
                item.vendorname &&
                item.vendorname.toLowerCase().includes(searchValue)
              );
            case "email":
              return (
                item.email && item.email.toLowerCase().includes(searchValue)
              );
            default:
              return false;
          }
        });
        setFilteredData(filtered);
      } else {
        setFilteredData(apidata);
      }
    };

    filterData();
  }, [dataFilter, apidata, searchBy]);

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
    </div>
  );
}
