import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumb from "../../components/breadcrumb/index";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

export default function CreateGeneralstaffstype() {
  const title = "Add Staff Type";
  const [holidays, setHolidays] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    weekendPolicy: "",
    casualLeaveBalance: null,
    holidayRestrictions: [],
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleHoliday = (selectedOptions) => {
    const holidayIds = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData((prevFormData) => ({
      ...prevFormData,
      holidayRestrictions: holidayIds,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.description) {
      newErrors.description = "Description is required";
    }
    if (!formData.weekendPolicy) {
      newErrors.weekendPolicy = "Weekend Policy is required";
    }
    if (!formData.casualLeaveBalance) {
      newErrors.casualLeaveBalance = "Casual Leave Balance is required";
    }
    return newErrors;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Convert holidayRestrictions array to a comma-separated string
    const formattedFormData = {
      ...formData,
      holidayRestrictions: formData.holidayRestrictions.join(","),
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff-type/new`,
        formattedFormData, // Use formattedFormData
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        navigate("/generalstafftype");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/holidays`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHolidays(response.data.holidays);
      } catch (error) {
        console.error("Error fetching holidays:", error);
        setHolidays([]);
      }
    };
    fetchHolidays();
  }, []);

  const holidayOptions = holidays.map((holidays) => ({
    value: holidays._id,
    label: holidays.name,
  }));

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title} </div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="name">
                  <b>
                    Staff Type <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  onChange={onChangeHandler}
                  value={formData.name}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="weekendPolicy">
                  <b>
                    Weekend Policy <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <select
                  name="weekendPolicy"
                  className="form-control"
                  value={formData.weekendPolicy}
                  onChange={onChangeHandler}
                >
                  <option value="None">None</option>
                  <option value="Sunday">Sunday</option>
                  <option value="Saturday-Sunday">Saturday-Sunday</option>
                </select>
                {errors.weekendPolicy && (
                  <p className="text-danger">{errors.weekendPolicy}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="casualLeaveBalance">
                  <b>
                    Casual Leave Balance <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <input
                  type="number"
                  name="casualLeaveBalance"
                  placeholder="Casual Leave Balance"
                  className={`form-control ${
                    errors.casualLeaveBalance ? "is-invalid" : ""
                  }`}
                  value={formData.casualLeaveBalance}
                  onChange={onChangeHandler}
                />
                {errors.casualLeaveBalance && (
                  <div className="invalid-feedback">
                    {errors.casualLeaveBalance}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="holidayRestrictions">
                  <b>Holiday Restrictions *</b>
                </label>
                <Select
                  name="holidayRestrictions"
                  options={holidayOptions}
                  onChange={handleHoliday}
                  value={holidayOptions.filter((option) =>
                    formData.holidayRestrictions.includes(option.value)
                  )}
                  isMulti
                  placeholder="Select Holidays"
                  className="basic-single"
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="description">
                  <b>
                    Description <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <input
                  type="text"
                  name="description"
                  placeholder="Description"
                  className={`form-control ${
                    errors.description ? "is-invalid" : ""
                  }`}
                  value={formData.description}
                  onChange={onChangeHandler}
                />
                {errors.description && (
                  <div className="invalid-feedback">{errors.description}</div>
                )}
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Staff Type
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
