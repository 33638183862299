import { useState, useEffect, useRef } from 'react'

import Breadcrumb from '../../components/breadcrumb/index'
import DataTable_Component from '../../components/DataTable/index'
import Swal from 'sweetalert2'
import { apidata } from './store/store'
import { Link } from 'react-router-dom'
// import {Modal} from 'bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare,faTrash,faEnvelope,faCopy} from '@fortawesome/free-solid-svg-icons'

// import toastr from 'reactjs-toastr';
// import 'reactjs-toastr/lib/toast.css';


export default function ManageBrand() {

    const title="Manage FAQ"
    const [apistate, setApiState] = useState([])
    {/* data table column name */ }
    const [apicol, setApiCol] = useState([])
    {/* all data for view */ }
    const [selectVal_details, setSelectVal_details] = useState([])
    {/* see all details modal(view) */ }
    const [details_modal_show, set_details_modal_Show] = useState(false);
    const[rerendarApi,setRerendarApi]=useState(false)
    const [selectVal, setSelectval] = useState([])
    // pass status model render
    const [openModal, setOpenModal] = useState(true)

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    const columns = [
        {
            name: "Brand",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable: true
        },
        
        {
            name: "Status",
        cell: (row) => <><button className={`btn  p-0 px-1 ${row.status.toLowerCase() == "pending" && "btn-warning"} ${row.status.toLowerCase() == "completed" ? "btn-primary" : ""}  ${row.status.toLowerCase() == "created" ? "btn-success" : ""} btn-sm`} >{row.status}</button></>,
        },
        {
            name: "Action",
            cell: (row) => <> <Link to={`/editFaq/${row.id}`}><button  className="btn btn-primary btn-sm" ><FontAwesomeIcon icon={faPenToSquare} /></button></Link><button onClick={()=>deleteFaq(row)} className="btn btn-danger btn-sm ms-1 "><FontAwesomeIcon icon={faTrash} /></button></>,
        }

    ]

    {/* data receve from store */ }
    useEffect(() => {
        // call api and response data set " setApiData(your res.data) " and column setApiCol( columns )

        setApiState(apidata)
        setApiCol(columns)
        console.log("render from brand")
    }, [rerendarApi])

    const deleteFaq=(row)=>{
        console.log(row)
        Swal.fire({
            icon:'warning',
            title: 'You wont be able to revert this!',       
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            setRerendarApi(!rerendarApi)
            if (result.isConfirmed) {
              Swal.fire('Saved!. id:'+row.id, '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }

    const rerender=(e)=>{
        setRerendarApi(e)
    }

    return (
        <>
          
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Link to="/manage-brand" ><button type="button" className="btn btn-outline-success  btn-sm ">Manage Brand</button></Link>
                <Link to="/manageFaq" ><button type="button" className="btn btn-outline-primary active btn-sm ms-1 active">Manage FAQ</button></Link>
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                                <Link to="/addFaq"><div className="btn btn-info btn-sm text-white">Add FAQ</div></Link>
                            </div>
                            <div className="card-body">
                                <DataTable_Component search="title" title_btn="Add stored Data" title={title} apidata={apistate} columns={apicol} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}