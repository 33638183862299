import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/clients/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Update_data_modal from "../../components/manageInvoice/update_data_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Add_data_modal from "../../components/clients/update_data_modal";
import Layouts from "../../layouts/layouts";
import ClientDetailModal from "../../components/clients/Client Details";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export

export default function Client() {
  const title = "Client Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [clientCount, setClientCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showClientDetailModal, setShowClientDetailModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name: <b>Name</b>,
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: <b>Payout</b>,
      selector: (row) => row.payout,
      sortable: true,
    },
    {
      name: <b>Hub Name</b>,
      selector: (row) => (row.id_hub ? row.id_hub.name : "No Hub"),
      sortable: true,
    },
    {
      name: <b>Payout Per Packet</b>,
      selector: (row) => row.payoutperpacket,
      sortable: true,
    },
    {
      name: <b>Created At</b>,
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: <b>Modified At</b>,
      selector: (row) =>
        row.updatedAt && new Date(row.updatedAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          {row.status === "created" && (
            <>
              <button
                onClick={() => copyLink()}
                className=" btn btn-success btn-sm"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button className=" btn btn-primary btn-sm ms-1">
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </>
          )}
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className=" btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewClient(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchClients = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.clients)) {
        setApiState(response.data.clients);
        setClientCount(response.data.clientCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.log("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/client/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Client has been deleted.", "success");
            fetchClients(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Client could not be deleted.", "error");
            console.error("Error deleting client:", error);
          });
      }
    });
  };

  const updateClient = (updatedClient) => {
    setApiState((prev) =>
      prev.map((client) =>
        client._id === updatedClient._id ? updatedClient : client
      )
    );
  };

  const handleUpdateClient = (updatedClientData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/client/${updatedClientData._id}`,
        updatedClientData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        Swal.fire("Updated!", "Driver details have been updated.", "success");
        updateClient(response.data.client);
      })
      .catch((error) => {
        Swal.fire("Error!", "Driver details could not be updated.", "error");
        console.error(
          "Error updating driver:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const viewClient = (client) => {
    setSelectedClient(client);
    setShowClientDetailModal(true);
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((client) => ({
      "Client Name": client.name,
      Email: client.email, // Add email field for export
      Payout: client.payout,
      "Hub Name": client.id_hub ? client.id_hub.name : "No Hub",
      "Payout Per Packet": client.payoutperpacket,
      "Created At": formatDate(client.createdAt),
      "Modified At": client.updatedAt
        ? new Date(client.updatedAt).toLocaleDateString()
        : "N/A",
      Status: client.status,
      "Phone Number": client.phoneNumber,
      "Home Number": client.homeNumber,
      "Temporary Address": client.tempAddress,
      State: client.state,
      "Permanent Address": client.permanentAddress,
      Coordinates: client.coordinates,
      Description: client.description,
      "Created By": client.createdBy,
      "Created Date": client.createdDate,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");

    XLSX.writeFile(workbook, "clients.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateClient={updateClient}
      />
      <ClientDetailModal
        show={showClientDetailModal}
        onHide={() => setShowClientDetailModal(false)}
        client={selectedClient}
      />

      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create-client">
                    <div className="btn btn-info btn-sm text-white me-2">
                      Add Client
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm text-white"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
