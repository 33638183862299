import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

export default function DataTable_Component({ apidata, columns }) {
  const [dataFilter, setDataFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let filtered = apidata;

    if (dataFilter) {
      filtered = filtered.filter(
        (item) =>
          item.numberplate &&
          item.numberplate.toLowerCase().includes(dataFilter.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [dataFilter, apidata]);

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder="Search by numberplate"
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
    </div>
  );
}
