import React, { useState, useEffect } from "react";
import axios from "axios";
import Layouts from "../../layouts/layouts";
import FilterModal from "../../components/allusers/Filter Modal/FilterModal";

export default function AllUsers() {
  const title = "AllUsers Details";
  const [apistate, setApiState] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [stateFilter, setStateFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.users)) {
        setApiState(response.data.users);
        setFilteredData(response.data.users); // Set initial filtered data
      } else {
        console.error("Unexpected data format from API", response.data);
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };

  const applyFilters = () => {
    const newFilteredData = apistate.filter((user) => {
      const matchesState = stateFilter
        ? user.tempaddress?.state === stateFilter
        : true;
      const matchesCity = cityFilter
        ? user.tempaddress?.city === cityFilter
        : true;
      return matchesState && matchesCity;
    });

    setFilteredData(newFilteredData);
  };

  const removeFilters = () => {
    setStateFilter("");
    setCityFilter("");
    setFilteredData(apistate); // Reset to the original data
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12"></div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <button
                  className="btn btn-primary"
                  onClick={() => setOpenModal(true)}
                >
                  Filter
                </button>
                <FilterModal
                  show={openModal}
                  onHide={() => setOpenModal(false)}
                  cityFilter={cityFilter}
                  setCityFilter={setCityFilter}
                  stateFilter={stateFilter}
                  setStateFilter={setStateFilter}
                  applyFilters={applyFilters}
                  removeFilters={removeFilters}
                  apidata={apistate}
                />
              </div>
              <div className="card-body">
                <div className="row">
                  {filteredData.length > 0 ? (
                    filteredData.map((user) => (
                      <div className="col-md-4 mb-3" key={user._id}>
                        <div className="card h-100">
                          <div className="card-body text-center">
                            {user.avatar && (
                              <div className="text-center mb-3">
                                <div
                                  style={{
                                    backgroundColor: "#f3f2ef",
                                    borderRadius: "10px",
                                    padding: "10px 10px",
                                    display: "inline-block",
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    src={user.avatar?.url}
                                    alt="User Avatar"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "50%",
                                      border: "3px solid #0077b5",
                                      position: "relative",
                                      zIndex: 1,
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            <h5 className="card-text">
                              {" "}
                              <b>{user.name}</b>
                            </h5>
                            <h6
                              className="card-text"
                              style={{ color: "Green" }}
                            >
                              {user.email}
                            </h6>
                            <h6 className="card-text">
                              {user.tempaddress?.state}
                            </h6>
                            <h6 className="card-text"> {user.phonenumber}</h6>
                            <h6 className="card-text">
                              {user.bank_details?.bank_name}
                            </h6>
                            <h6 className="card-text">
                              {user.bank_details?.account_number
                                ? `****${user.bank_details.account_number.slice(
                                    -4
                                  )}`
                                : ""}
                            </h6>
                            <h6 className="card-text">
                              {user.tempaddress?.address}
                            </h6>
                            <h6 className="card-text" style={{ color: "Blue" }}>
                              {user.id_generalStaffType?.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No users found.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
