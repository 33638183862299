import { useState, useEffect } from 'react'
import Breadcrumb from '../../../components/breadcrumb/index'
import { pay_setting_btn_link } from './btn_link/btn_link'
import Swal from 'sweetalert2'
import Pay_setting_Btn_grp from '../../../components/paymentSetting/mobailBank/payment_btn_grp/index'
export default function PaymentSetting() {

    const title = "Cellfin Payment"
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    const [data, setData] = useState([])
    const [rerender, setRerender] = useState(false)
    useEffect(() => {
        console.log("render from bkash setting")
    }, [rerender])

    // type means personal =1 or marchent=2 or agent=3
    const cellfinSettingUpdate = (type) => {
        Swal.fire('Cellfin Setting saved', '', 'success')
        setRerender(!rerender)
        console.log(type)
    }
    return (
        <>
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Pay_setting_Btn_grp active_btn="cellfin" btn_link={pay_setting_btn_link} />
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <nav className="paymentSetting">
                                <div className="nav nav-tabs paymentsetting" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-bkash-personal-tab" data-bs-toggle="tab" data-bs-target="#nav-bkash-personal" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Personal</button>
                                    <button className="nav-link" id="nav-bkash-marchent-tab" data-bs-toggle="tab" data-bs-target="#nav-bkash-marchent" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Marchent</button>
                                    <button className="nav-link" id="nav-bkash-agent-tab" data-bs-toggle="tab" data-bs-target="#nav-bkash-agent" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Agent</button>
                                </div>
                            </nav>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <label ><b>select brand</ b></label>
                                        <select className="form-control">
                                            <option value="">onhost</option>
                                            <option value="">onhost2</option>

                                        </select>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label ><b>Status</ b></label>
                                        <select className="form-control">
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label  ><b>Number</ b></label>
                                        <input type="number" placeholder="Your number" className="form-control" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label><b>Transaction Fee (%)</b></label>
                                        <div className="input-group mb-3">
                                            <input type="number" placeholder="Transaction Fee" className="form-control " defaultValue="deede" aria-label="apigenerate" />
                                            <span className="input-group-text">$  </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label  ><b>Min Amount</ b></label>
                                        <input type="number" placeholder="Min Amount" className="form-control" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label  ><b>Max Amount</ b></label>
                                        <input type="number" placeholder="Max Amount" className="form-control" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label  ><b>Enable</ b></label>
                                        <select className="form-control">
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label  ><b>Status</ b></label>
                                        <select className="form-control">
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <button onClick={() => cellfinSettingUpdate('1')} className="btn btn-primary btn-sm my-3">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}