import { useState, useEffect, useRef } from 'react'

import Breadcrumb from '../../../../components/breadcrumb/index'
import DataTable_Component from '../../../../components/DataTable/index'

// import View_modal from '../../../components/manageBrand/view_modal/index'
// import Details_modal from '../../components/payment/details_modal/index'
// import Add_data_modal from '../../components/storedData/add_data_modal/index'
// import Update_data_modal from '../../components/manageBrand/update_data_modal/index'

import Swal from 'sweetalert2'
import Pay_setting_Btn_grp from '../../../../components/paymentSetting/international/payment_btn_grp/index'
import { apidata } from './store/store'
import { Link } from 'react-router-dom'
// import {Modal} from 'bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare,faTrash,faEnvelope,faCopy} from '@fortawesome/free-solid-svg-icons'

// import toastr from 'reactjs-toastr';
// import 'reactjs-toastr/lib/toast.css';
import { pay_setting_btn_link } from '../btn_link/btn_link'

export default function ManageInternationalMethod() {

    const title="Manage International Bank Api"
    const [apistate, setApiState] = useState([])
    {/* data table column name */ }
    const [apicol, setApiCol] = useState([])
    const[rerendarApi,setRerendarApi]=useState(false)


    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    const columns = [
        {
            name: "Brand",
            selector: row => row.brand,
            sortable: true
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Min Amount",
            selector: row => row.min_amount,
            sortable: true
        },
        {
            name: "Max Amount",
            selector: row => row.max_amount,
            sortable: true
        },
        
        {
            name: "Status",
            cell: (row) => <><button className={`btn p-0 px-1 ${row.status.toLowerCase() == "inactive" && "btn-danger"} ${row.status.toLowerCase() == "active" ? "btn-success" : ""}   btn-sm`}  >{row.status}</button></>,

        },
        {
            name: "Action",
            cell: (row) => <><Link to={`/edit-mobailBankApi/${row.id}`}> <button  className="btn btn-primary btn-sm" ><FontAwesomeIcon icon={faPenToSquare} /></button></Link><button onClick={()=>delete_row(row)} className="btn btn-danger ms-1 btn-sm" ><FontAwesomeIcon icon={faTrash} /></button></>,
        }

    ]

    {/* data receve from store */ }
    useEffect(() => {
        setApiState(apidata)
        setApiCol(columns)
        console.log("render from api method")
    }, [rerendarApi])

    const delete_row=(row)=>{
        console.log(row)
        Swal.fire({
            icon:'warning',
            title: 'You wont be able to revert this!',       
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            setRerendarApi(!rerendarApi)
            if (result.isConfirmed) {
              Swal.fire('Saved!. id:'+row.id, '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }
  

    return (
        <>
            
           
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Pay_setting_Btn_grp active_btn="manual" btn_link={pay_setting_btn_link} />
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                                <Link to="/add-mobailBankApi"><div className="btn btn-info btn-sm text-white">Add Mobail Bank(Api)</div></Link>
                            </div>
                            <div className="card-body">
                               
                                <DataTable_Component search="name"  title_btn="Add stored Data" title={title} apidata={apistate} columns={apicol} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}