export const pay_setting_btn_link={
    bkash: "/mobailBank/bKash",
    rocket: "/mobailBank/rocket",
    nagad: "/mobailBank/nagad",
    upay: "/mobailBank/upay",
    cellfin: "/mobailBank/cellfin",
    tap: "/mobailBank/tap",
    okwallet: "/mobailBank/okWallet",
    ipay: "/mobailBank/ipay",
    apimethod: "/mobail-bank-api",
}