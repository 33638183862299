// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import Breadcrumb from "../../components/breadcrumb/index";
// import { Country, State, City } from "country-state-city";
// import Modal from "react-modal";
// import MapModal from "../../components/map/MapPicker";
// import Layouts from "../../layouts/layouts";
// import { Autocomplete, LoadScript } from "@react-google-maps/api";

// Modal.setAppElement("#root");

// export default function Create_driver() {
//   const title = "Add Driver";
//   const navigate = useNavigate();
//   const [selectedCountryCode, setSelectedCountryCode] = useState("");
//   const [selectedStateCode, setSelectedStateCode] = useState("");
//   const [vendors, setVendors] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [states, setStates] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [isMapOpen, setIsMapOpen] = useState(false);
//   const [formData, setFormData] = useState({
//     homephonenumber: "",
//     tempaddress: "",
//     permanentaddress: "",
//     country: "",
//     state: "",
//     city: "",
//     image_url: null,
//     driverlicense: null,
//     governmentidentification: null,
//     coordinates: { latitude: "", longitude: "" },
//     id_vendor: "",
//     status: "Active",
//     joiningDate: null,
//     exitDate: "",
//     id_user: "",
//   });
//   const [errors, setErrors] = useState({});
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     const fetchCountries = async () => {
//       const countryData = Country.getAllCountries().map((country) => ({
//         name: country.name,
//         code: country.isoCode,
//       }));
//       setCountries(countryData);
//     };
//     fetchCountries();
//   }, []);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           console.error("No token found");
//           return;
//         }
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setUsers(response.data.users);
//       } catch (error) {
//         console.error("Error fetching users:", error);
//         setUsers([]);
//       }
//     };
//     fetchUsers();
//   }, []);

//   useEffect(() => {
//     if (selectedCountryCode) {
//       const stateData = State.getStatesOfCountry(selectedCountryCode).map(
//         (state) => ({
//           name: state.name,
//           code: state.isoCode,
//         })
//       );
//       setStates(stateData);
//       setCities([]);
//     }
//   }, [selectedCountryCode]);

//   useEffect(() => {
//     if (selectedStateCode && selectedCountryCode) {
//       const cityData = City.getCitiesOfState(
//         selectedCountryCode,
//         selectedStateCode
//       ).map((city) => ({
//         name: city.name,
//         code: city.name,
//       }));
//       setCities(cityData);
//     }
//   }, [selectedStateCode, selectedCountryCode]);

//   const brad = [
//     {
//       name: "Home",
//     },
//     {
//       name: title,
//     },
//   ];

//   const onChangeHandler = (event) => {
//     const { name, value, files } = event.target;
//     if (name === "latitude" || name === "longitude") {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         coordinates: {
//           ...prevFormData.coordinates,
//           [name]: value,
//         },
//       }));
//     } else if (files) {
//       if (files[0].size > 1024 * 1024) {
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           [name]: "File size exceeds 1MB limit",
//         }));
//       } else {
//         setFormData((prevFormData) => ({
//           ...prevFormData,
//           [name]: files[0],
//         }));
//       }
//     } else {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         [name]: value,
//       }));
//       if (name === "country") {
//         setSelectedCountryCode(value);
//       }
//       if (name === "state") {
//         setSelectedStateCode(value);
//       }
//     }
//   };

//   const onLoad = (autoComp) => {
//     setAutocomplete(autoComp);
//   };

//   const onPlaceChanged = () => {
//     if (autocomplete !== null) {
//       const place = autocomplete.getPlace();
//       const lat = place.geometry.location.lat();
//       const lng = place.geometry.location.lng();
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         coordinates: { latitude: lat, longitude: lng },
//       }));
//     } else {
//       console.log("Autocomplete is not loaded yet!");
//     }
//   };

//   const onSelectLocation = ({ lat, lng }) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       coordinates: { latitude: lat, longitude: lng },
//     }));
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formData.tempaddress)
//       newErrors.tempaddress = "Temporary Address is required";
//     if (!formData.permanentaddress)
//       newErrors.permanentaddress = "Permanent Address is required";
//     if (!formData.country) newErrors.country = "Country is required";
//     if (!formData.driverlicense)
//       newErrors.driverlicense = "Driver License is required";
//     if (!formData.governmentidentification)
//       newErrors.governmentidentification = "Govt. Identification is required";
//     if (!formData.coordinates.latitude || !formData.coordinates.longitude)
//       newErrors.coordinates = "Location is required";
//     // if (!formData.id_vendor) newErrors.id_vendor = "Vendor is required";
//     if (!formData.homephonenumber || formData.homephonenumber.length !== 10)
//       newErrors.homephonenumber = "Home Number must be 10 digits";
//     if (!formData.joiningDate)
//       newErrors.joiningDate = "Joining date is required.";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const onSubmitHandler = async (event) => {
//     event.preventDefault();
//     if (!validateForm()) {
//       return;
//     }
//     const formDataToSend = new FormData();

//     for (const key in formData) {
//       if (key === "coordinates") {
//         formDataToSend.append(
//           "coordinates[latitude]",
//           formData.coordinates.latitude
//         );
//         formDataToSend.append(
//           "coordinates[longitude]",
//           formData.coordinates.longitude
//         );
//       }
//        else {
//         formDataToSend.append(key, formData[key]);
//       }
//     }
//     try {
//       const token = localStorage.getItem("token");
//       if (!token) {
//         console.error("No token found");
//         return;
//       }
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/driver/new`,
//         formDataToSend,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 201) {
//         navigate("/driver");
//       } else {
//         console.error("Submission failed:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   useEffect(() => {
//     const fetchVendors = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           console.error("No token found");
//           return;
//         }
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}/api/v1/vendors`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setVendors(response.data.vendors);
//         console.log("Vendors fetched:", response.data.vendors);
//       } catch (error) {
//         console.error("Error fetching vendor:", error);
//         setVendors([]);
//       }
//     };
//     fetchVendors();
//   }, []);

//   const handleDateChange = (date, fieldName) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [fieldName]: date,
//     }));
//   };

//   return (
//     <>
//       <Layouts />
//       <div className="container-fluid">
//         <Breadcrumb title={title} brad={brad} />
//         <form
//           className="card"
//           style={{ borderTop: "2px solid #4723d9" }}
//           onSubmit={onSubmitHandler}
//         >
//           <div className="card-header d-flex justify-content-between border-bottom pb-1">
//             <div className="">{title}</div>
//             <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
//               Back
//             </div>
//           </div>
//           <div className="card-body">
//             <div className="row justify-content-center">
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="homephonenumber">
//                   <b>Home Number *</b>
//                 </label>
//                 <input
//                   type="text"
//                   placeholder="Home Number"
//                   className="form-control"
//                   name="homephonenumber"
//                   onChange={onChangeHandler}
//                   value={formData.homephonenumber || ""}
//                 />
//                 {errors.homephonenumber && (
//                   <p className="text-danger">{errors.homephonenumber}</p>
//                 )}
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="id_user">
//                   <b>User </b>
//                 </label>
//                 <select
//                   name="id_user"
//                   className="form-control"
//                   value={formData.id_user}
//                   onChange={onChangeHandler}
//                 >
//                   <option value="">Select User</option>
//                   {Array.isArray(users) &&
//                     users.map((user) => (
//                       <option key={user._id} value={user._id}>
//                         {user.email}
//                       </option>
//                     ))}
//                 </select>
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="tempaddress">
//                   <b>Temporary Address *</b>
//                 </label>
//                 <input
//                   type="text"
//                   placeholder="Temporary Address"
//                   className="form-control"
//                   name="tempaddress"
//                   onChange={onChangeHandler}
//                   value={formData.tempaddress || ""}
//                 />
//                 {errors.tempaddress && (
//                   <p className="text-danger">{errors.tempaddress}</p>
//                 )}
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="country">
//                   <b>Country *</b>
//                 </label>
//                 <select
//                   name="country"
//                   className="form-control"
//                   value={formData.country || ""}
//                   onChange={onChangeHandler}
//                 >
//                   <option value="">Select Country</option>
//                   {countries.map((country) => (
//                     <option key={country.code} value={country.code}>
//                       {country.name}
//                     </option>
//                   ))}
//                 </select>
//                 {errors.country && (
//                   <p className="text-danger">{errors.country}</p>
//                 )}
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="state">
//                   <b>State</b>
//                 </label>
//                 <select
//                   name="state"
//                   className="form-control"
//                   value={formData.state || ""}
//                   onChange={onChangeHandler}
//                 >
//                   <option value="">Select State</option>
//                   {states.map((state) => (
//                     <option key={state.code} value={state.code}>
//                       {state.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="city">
//                   <b>City</b>
//                 </label>
//                 <select
//                   name="city"
//                   className="form-control"
//                   value={formData.city || ""}
//                   onChange={onChangeHandler}
//                 >
//                   <option value="">Select City</option>
//                   {cities.map((city) => (
//                     <option key={city.code} value={city.name}>
//                       {city.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>

//               <div className="col-md-6 mt-3">
//                 <label htmlFor="joiningDate">
//                   <b>Joining Date *</b>
//                 </label>
//                 <input
//                   name="joiningDate"
//                   type="date"
//                   onChange={onChangeHandler}
//                   className="form-control"
//                   value={formData.joiningDate || ""}
//                 />
//                 {errors.joiningDate && (
//                   <p className="text-danger">{errors.joiningDate}</p>
//                 )}
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="exitDate">
//                   <b>Exit Date </b>
//                 </label>
//                 <input
//                   name="exitDate"
//                   type="date"
//                   onChange={onChangeHandler}
//                   className="form-control"
//                   value={formData.exitDate || ""}
//                 />
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="permanentaddress">
//                   <b>Permanent Address *</b>
//                 </label>
//                 <input
//                   type="text"
//                   placeholder="Permanent Address"
//                   className="form-control"
//                   name="permanentaddress"
//                   onChange={onChangeHandler}
//                   value={formData.permanentaddress || ""}
//                 />
//                 {errors.permanentaddress && (
//                   <p className="text-danger">{errors.permanentaddress}</p>
//                 )}
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="driverlicense">
//                   <b>Driver License *</b>
//                 </label>
//                 <input
//                   type="file"
//                   className="form-control"
//                   name="driverlicense"
//                   onChange={onChangeHandler}
//                 />
//                 {errors.driverlicense && (
//                   <p className="text-danger">{errors.driverlicense}</p>
//                 )}
//               </div>

//               <div className="col-md-6 mt-3">
//                 <label htmlFor="governmentidentification">
//                   <b>Govt. Identification *</b>
//                 </label>
//                 <input
//                   type="file"
//                   className="form-control"
//                   name="governmentidentification"
//                   onChange={onChangeHandler}
//                 />
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="coordinates">
//                   <b>Coordinates *</b>
//                 </label>
//                 <input
//                   type="text"
//                   name="coordinates"
//                   placeholder="Latitude, Longitude"
//                   className="form-control"
//                   value={`${formData.coordinates.latitude}, ${formData.coordinates.longitude}`}
//                   readOnly
//                 />
//                 {errors.coordinates && (
//                   <p className="text-danger">{errors.coordinates}</p>
//                 )}
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="">
//                   <b>Location *</b>
//                 </label>
//                 <LoadScript
//                   googleMapsApiKey={process.env.REACT_APP_BASE_API}
//                   libraries={["places"]}
//                 >
//                   <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
//                     <input
//                       type="text"
//                       placeholder="Search places..."
//                       className="form-control"
//                     />
//                   </Autocomplete>
//                 </LoadScript>
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="vendor">
//                   <b>Vendor </b>
//                 </label>
//                 <select
//                   name="id_vendor"
//                   className="form-control"
//                   value={formData.id_vendor || ""}
//                   onChange={onChangeHandler}
//                 >
//                   <option value="">Select Vendor</option>
//                   {Array.isArray(vendors) &&
//                     vendors.map((vendor) => (
//                       <option key={vendor._id} value={vendor._id}>
//                         {vendor?.id_user?.name}
//                       </option>
//                     ))}
//                 </select>
//                 {/* {errors.id_vendor && (
//                   <p className="text-danger">{errors.id_vendor}</p>
//                 )} */}
//               </div>
//               <div className="col-md-6 mt-3">
//                 <label htmlFor="status">
//                   <b> Driver Status</b>
//                 </label>
//                 <select
//                   name="status"
//                   className="form-control"
//                   value={formData.status || "Active"}
//                   onChange={onChangeHandler}
//                 >
//                   <option value="Active">Active</option>
//                   <option value="Inactive">Inactive</option>
//                 </select>
//               </div>
//               <div className="col-md-6 mt-3"></div>
//             </div>
//             <button type="submit" className="btn btn-primary btn-sm mt-3">
//               Add Driver
//             </button>
//           </div>
//         </form>
//       </div>
//       <Modal
//         isOpen={isMapOpen}
//         onRequestClose={() => setIsMapOpen(false)}
//         contentLabel="Map Modal"
//       >
//         <MapModal onSelectLocation={onSelectLocation} />
//       </Modal>
//     </>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import { Country, State, City } from "country-state-city";
import Modal from "react-modal";
import MapModal from "../../components/map/MapPicker";
import Layouts from "../../layouts/layouts";
import { Autocomplete, LoadScript } from "@react-google-maps/api";

Modal.setAppElement("#root");

export default function Create_driver() {
  const title = "Add Driver";
  const navigate = useNavigate();
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [vendors, setVendors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [formData, setFormData] = useState({
    homephonenumber: "",
    tempaddress: "",
    permanentaddress: "",
    country: "",
    state: "",
    city: "",
    image_url: null,
    driverlicense: null,
    governmentidentification: null,
    coordinates: { latitude: "", longitude: "" },
    id_vendor: "",
    status: "Active",
    joiningDate: null,
    exitDate: "",
    id_user: "",
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = Country.getAllCountries().map((country) => ({
        name: country.name,
        code: country.isoCode,
      }));
      setCountries(countryData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      const stateData = State.getStatesOfCountry(selectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setStates(stateData);
      setCities([]);
    }
  }, [selectedCountryCode]);

  useEffect(() => {
    if (selectedStateCode && selectedCountryCode) {
      const cityData = City.getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, selectedCountryCode]);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const onChangeHandler = (event) => {
    const { name, value, files } = event.target;
    if (name === "latitude" || name === "longitude") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: {
          ...prevFormData.coordinates,
          [name]: value,
        },
      }));
    } else if (files) {
      if (files[0].size > 1024 * 1024) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size exceeds 1MB limit",
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: files[0],
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      if (name === "country") {
        setSelectedCountryCode(value);
      }
      if (name === "state") {
        setSelectedStateCode(value);
      }
    }
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: { latitude: lat, longitude: lng },
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onSelectLocation = ({ lat, lng }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coordinates: { latitude: lat, longitude: lng },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.tempaddress)
      newErrors.tempaddress = "Temporary Address is required";
    if (!formData.permanentaddress)
      newErrors.permanentaddress = "Permanent Address is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.driverlicense)
      newErrors.driverlicense = "Driver License is required";
    if (!formData.governmentidentification)
      newErrors.governmentidentification = "Govt. Identification is required";
    if (!formData.coordinates.latitude || !formData.coordinates.longitude)
      newErrors.coordinates = "Location is required";
    // if (!formData.id_vendor) newErrors.id_vendor = "Vendor is required";
    if (!formData.homephonenumber || formData.homephonenumber.length !== 10)
      newErrors.homephonenumber = "Home Number must be 10 digits";
    if (!formData.joiningDate)
      newErrors.joiningDate = "Joining date is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    const formDataToSend = new FormData();

    for (const key in formData) {
      if (key === "coordinates") {
        formDataToSend.append(
          "coordinates[latitude]",
          formData.coordinates.latitude
        );
        formDataToSend.append(
          "coordinates[longitude]",
          formData.coordinates.longitude
        );
      } else if (key === "id_vendor" && !formData[key]) {
        // Skip adding id_vendor if it's an empty string
        continue;
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/driver/new`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/driver");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vendors`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVendors(response.data.vendors);
        console.log("Vendors fetched:", response.data.vendors);
      } catch (error) {
        console.error("Error fetching vendor:", error);
        setVendors([]);
      }
    };
    fetchVendors();
  }, []);

  const handleDateChange = (date, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: date,
    }));
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="homephonenumber">
                  <b>Home Number *</b>
                </label>
                <input
                  type="text"
                  placeholder="Home Number"
                  className="form-control"
                  name="homephonenumber"
                  onChange={onChangeHandler}
                  value={formData.homephonenumber || ""}
                />
                {errors.homephonenumber && (
                  <p className="text-danger">{errors.homephonenumber}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User </b>
                </label>
                <select
                  name="id_user"
                  className="form-control"
                  value={formData.id_user}
                  onChange={onChangeHandler}
                >
                  <option value="">Select User</option>
                  {Array.isArray(users) &&
                    users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.email}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="tempaddress">
                  <b>Temporary Address *</b>
                </label>
                <input
                  type="text"
                  placeholder="Temporary Address"
                  className="form-control"
                  name="tempaddress"
                  onChange={onChangeHandler}
                  value={formData.tempaddress || ""}
                />
                {errors.tempaddress && (
                  <p className="text-danger">{errors.tempaddress}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="country">
                  <b>Country *</b>
                </label>
                <select
                  name="country"
                  className="form-control"
                  value={formData.country || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <p className="text-danger">{errors.country}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="state">
                  <b>State</b>
                </label>
                <select
                  name="state"
                  className="form-control"
                  value={formData.state || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="city">
                  <b>City</b>
                </label>
                <select
                  name="city"
                  className="form-control"
                  value={formData.city || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.code} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="joiningDate">
                  <b>Joining Date *</b>
                </label>
                <input
                  name="joiningDate"
                  type="date"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.joiningDate || ""}
                />
                {errors.joiningDate && (
                  <p className="text-danger">{errors.joiningDate}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="exitDate">
                  <b>Exit Date </b>
                </label>
                <input
                  name="exitDate"
                  type="date"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.exitDate || ""}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="permanentaddress">
                  <b>Permanent Address *</b>
                </label>
                <input
                  type="text"
                  placeholder="Permanent Address"
                  className="form-control"
                  name="permanentaddress"
                  onChange={onChangeHandler}
                  value={formData.permanentaddress || ""}
                />
                {errors.permanentaddress && (
                  <p className="text-danger">{errors.permanentaddress}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="driverlicense">
                  <b>Driver License *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="driverlicense"
                  onChange={onChangeHandler}
                />
                {errors.driverlicense && (
                  <p className="text-danger">{errors.driverlicense}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="governmentidentification">
                  <b>Govt. Identification *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="governmentidentification"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="coordinates">
                  <b>Coordinates *</b>
                </label>
                <input
                  type="text"
                  name="coordinates"
                  placeholder="Latitude, Longitude"
                  className="form-control"
                  value={`${formData.coordinates.latitude}, ${formData.coordinates.longitude}`}
                  readOnly
                />
                {errors.coordinates && (
                  <p className="text-danger">{errors.coordinates}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="">
                  <b>Location *</b>
                </label>
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_BASE_API}
                  libraries={["places"]}
                >
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      type="text"
                      placeholder="Search places..."
                      className="form-control"
                    />
                  </Autocomplete>
                </LoadScript>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="vendor">
                  <b>Vendor </b>
                </label>
                <select
                  name="id_vendor"
                  className="form-control"
                  value={formData.id_vendor || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Vendor</option>
                  {Array.isArray(vendors) &&
                    vendors.map((vendor) => (
                      <option key={vendor._id} value={vendor._id}>
                        {vendor?.id_user?.name}
                      </option>
                    ))}
                </select>
                {/* {errors.id_vendor && (
                  <p className="text-danger">{errors.id_vendor}</p>
                )} */}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b> Driver Status</b>
                </label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status || "Active"}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Driver
            </button>
          </div>
        </form>
      </div>
      <Modal
        isOpen={isMapOpen}
        onRequestClose={() => setIsMapOpen(false)}
        contentLabel="Map Modal"
      >
        <MapModal onSelectLocation={onSelectLocation} />
      </Modal>
    </>
  );
}
