import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import Breadcrumb from '../../../components/breadcrumb/index'
import {useNavigate} from 'react-router-dom' 
export default function Edit_api_method() {
    const title = "Edit Bank"
    const navigate = useNavigate();
    const[rerendarApi,setRerendarApi]=useState(false)

    {/* data receve from store */ }
    useEffect(() => {
    //  call api 
        console.log("render from invoice")
    }, [rerendarApi])


    const edit_bank=()=>{
        
        Swal.fire({
            icon:'warning',
            title: 'You wont be able to revert this!',       
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            setRerendarApi(!rerendarApi)
            if (result.isConfirmed) {
              Swal.fire('Edit success', '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />

                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        <button className="btn btn-info btn-sm" onClick={()=>navigate(-1)}>Go Back</button>
                    </div>
                    <div className="card-body">
                        <div  className="row justify-content-center">
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Select brand</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Payment Method type</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Full name</ b></label>
                                <input type="text" placeholder="Full Name" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Transaction Fee</ b></label>
                                <div  className="input-group mb-3">
                                    <input type="number"  className="form-control " defaultValue="deede" aria-label="apigenerate" readOnly />
                                    <span  className="input-group-text">$  </span>                                                                                              
                                </div>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Min Amount</ b></label>
                                <input type="number" placeholder="Min Amount" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Max Amount</ b></label>
                                <input type="number" placeholder="Max Amount" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Account type</ b></label>
                                <select className="form-control">
                                    <option value="">Checkout Api</option>
                                    <option value="">Tokenized API</option>
                                    <option value="">Live Without API</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Sandbox</ b></label>
                                <select className="form-control">
                                    <option value="on">On</option>
                                    <option value="off">Off</option>
                                
                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>User Name</ b></label>
                                <input type="text" placeholder="User Name" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Password</ b></label>
                                <input type="text" placeholder="Full password" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>App key</ b></label>
                                <input type="text" placeholder="App Key" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>App Secret</ b></label>
                                <input type="text" placeholder="App Secret" className="form-control" />
                            </div>
                        </div>
                        <button onClick={edit_bank} className="btn btn-primary btn-sm my-3">Update</button>
                    </div>
                </div>
            </div>

        </>
    )
}