import React from "react";
import { Modal, Button } from "react-bootstrap";

const ClientDetailModal = ({ show, onHide, client, user }) => {
  if (!client) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Client Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong> {client.name}
        </p>
        <p>
          <strong>Payout:</strong> {client.payout}
        </p>
        <p>
          <strong>Payouts Per Packet:</strong> {client.payoutperpacket}
        </p>
        <p>
          <strong>Hub:</strong> {client.id_hub ? client.id_hub.name : "N/A"}
        </p>
        <p>
          <strong>Status:</strong> {client.status}
        </p>
        <p>
          <strong>Created Date:</strong>{" "}
          {client.createdAt && (
            <>{new Date(client.createdAt).toLocaleDateString()}</>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClientDetailModal;
