import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/vehicles/Data Table Component";
import Add_data_modal from "../../components/vehicles/update_data_modal/index";
import VehicleDetailModal from "../../components/vehicles/DisplayData"; // Import the new modal component
import Swal from "sweetalert2";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faCopy,
  faEye,
} from "@fortawesome/free-solid-svg-icons"; // Import the eye icon
import Layouts from "../../layouts/layouts";

export default function Vehicle() {
  const title = "Vehicles List";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [vehicleCount, setVehicleCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showVehicleDetailModal, setShowVehicleDetailModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [data, setData] = useState([]);
  const brad = [{ name: "Home" }, { name: title }];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name: "Number Plate",
      selector: (row) => row.numberplate,
      sortable: true,
    },
    {
      name: "Vehicle Type",
      selector: (row) => (row.id_type ? row.id_type.vehiclename : "N/A"),
      sortable: true,
    },
    {
      name: "Vehicle Company",
      selector: (row) => (row.id_type ? row.id_type.company : "N/A"),
      sortable: true,
    },
    {
      name: "Driver Name",
      cell: (row) => (
        <div>
          {row.id_driver && row.id_driver.length > 0 ? (
            <div>
              {row.id_driver.map((driver) => (
                <Badge key={driver._id} pill bg="white" text="black">
                  {driver.drivername}
                </Badge>
              ))}
            </div>
          ) : (
            <span>N/A</span>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Hub Name",
      selector: (row) => (row.id_hub ? row.id_hub.name : "N/A"),
      sortable: true,
    },
    { name: "Model", selector: (row) => row.model, sortable: true },
    { name: "Total KM", selector: (row) => row.totalkm, sortable: true },
    { name: "Capacity", selector: (row) => row.capacity, sortable: true },
    {
      name: "Created At",
      selector: (row) =>
        row.createdAt && new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Modified At",
      selector: (row) =>
        row.updatedAt && new Date(row.updatedAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          onClick={() => handleShow(row)}
          className={`btn p-0 px-1 ${
            row.status.toLowerCase() === "active" && "btn-success"
          } ${row.status.toLowerCase() === "inactive" && "btn-danger"}
          `}
        >
          {row.status}
        </button>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewVehicle(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.vehicles)) {
        setApiState(response.data.vehicles);
        setVehicleCount(response.data.vehicleCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const delete_row = (row) => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle/${row._id} `,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Vehicle has been deleted.", "success");
            fetchData();
          })
          .catch((error) => {
            Swal.fire("Error!", "Vehicle could not be deleted.", "error");
          });
      }
    });
  };

  const updateVehicle = (updatedVehicle) => {
    setApiState((prev) =>
      prev.map((vehicle) =>
        vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle
      )
    );
  };

  const viewVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowVehicleDetailModal(true);
  };

  return (
    <>
      <Layouts />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateVehicle={updateVehicle}
      />
      <VehicleDetailModal
        show={showVehicleDetailModal}
        onHide={() => setShowVehicleDetailModal(false)}
        vehicle={selectedVehicle}
      />
      <div className="container-fluid">
        {/* <Breadcrumb title={title} brad={brad} /> */}

        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <Link to="/create-vehicle">
                  <div className="btn btn-info btn-sm text-white">
                    Add Vehicle
                  </div>
                </Link>
                
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="numberplate"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
