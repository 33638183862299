import { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../components/breadcrumb/index'
import DataTable_Component from '../../components/DataTable/index'
import Btn_grp from '../../components/payment/payment_btn_grp/index'
import Status_modal from '../../components/storedData/status_modal/index'
import Details_modal from '../../components/payment/details_modal/index'
import Add_data_modal from '../../components/storedData/add_data_modal/index'
import Update_data_modal from '../../components/storedData/update_data_modal/index'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faEnvelope, faCopy } from '@fortawesome/free-solid-svg-icons'
import { apidata } from './store/store'
import { Link } from 'react-router-dom'
// import {Modal} from 'bootstrap'



export default function PaymentsAll() {

    const title = "Store data"
    const [apistate, setApiState] = useState([])
    const [selectVal, setSelectval] = useState([])
    // pass status model render
    const [openModal, setOpenModal] = useState(true)
    // open update data modal
    const [open_update_modal, setOpen_update_modal] = useState({ render: true, modal_open: false })
    const [update_modal_data, setUpdate_modal_data] = useState('')
    const [rerendarApi, setRerendarApi] = useState(false)
    {/* all data for view */ }
    const [selectVal_details, setSelectVal_details] = useState([])
    {/* see all details modal(view) */ }
    const [details_modal_show, set_details_modal_Show] = useState(false);
    // open add data modal
    const [open_add_modal, setOpen_add_modal] = useState({ render: true, modal_open: false })
    const [apicol, setApiCol] = useState([])


    const filter_apistate = apistate.filter((val) => {
        return val.status !== "trash"
    })
   

    const brad = [
        {
            name: "home",
        },
        {
            name: "Stored Data",
        }
    ]
    const columns = [
        {
            name: "Payment Method",
            selector: row => row.payment_method,
            sortable: true
        },
        {
            name: "Amount",
            selector: row => row.amount,
            sortable: true
        },
        {
            name: "Sender Number",
            selector: row => row.sender_number,
            sortable: true
        },
        {
            name: "Transaction_id",
            selector: row => row.transaction_id,
            sortable: true
        },
        {
            name: "Main Balence",
            selector: row => row.main_balence,
            sortable: true
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true
        },

        {
            name: "Status",
            cell: (row) => <><button onClick={() => handleShow(row)} className={`btn py-0 px-1 ${row.status.toLowerCase() == "active" && "btn-success"}    ${row.status.toLowerCase() == "pending" ? "btn-primary" : ""}  btn-sm`}  >{row.status}</button></>,
        },
        {
            name: "action",
            cell: (row) => <><button onClick={() => setUpdateStoreBtn(row)} className=" btn btn-primary btn-sm"  >view</button><button onClick={() => delete_row(row)} className=" btn btn-danger btn-sm ms-2"  ><FontAwesomeIcon icon={faTrash} /></button></>,
        }

    ]

    const handleShow = (row) => {
        //status model e pass data 
        setOpenModal(openModal => !openModal)
        setSelectval(row)
    }

    const setStoreBtn = () => {
        //    return alert(qw)
        setOpen_add_modal({ render: !open_add_modal.render, modal_open: true })
    }

    const setUpdateStoreBtn = (row) => {
        setOpen_update_modal({ render: !open_update_modal.render, modal_open: true })
        setUpdate_modal_data(row)
    }

    {/* data receve from store */ }
    useEffect(() => {
        // call api and response data set " setApiData(your res.data) " and column setApiCol( columns )

        setApiState(apidata)
        setApiCol(columns)
        console.log('render from store data')
    }, [rerendarApi])

    const rerender_status = (e) => {
        setRerendarApi(!rerendarApi)   
    }
    const rerender = (e) => {
        setRerendarApi(!rerendarApi)
        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to revert add!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                Swal.fire('Saved!. id:', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    const rerender_update = (e) => {
        setRerendarApi(e)
        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to revert update!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                Swal.fire('Saved!. id:', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const delete_row = (row) => {

        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to revert this!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                Swal.fire('Saved!. id:' + row.id, '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    return (
        <>
            {/* status modal component */}
            <Status_modal rerendar={(e) => rerender_status(e)} row={selectVal} openModal={openModal} />
            {/* add data modal */}
            <Add_data_modal rerendar={(e) => rerender(e)} openAddDataModal={open_add_modal} />
            {/* add data modal */}
            <Update_data_modal rerendar={(e) => rerender_update(e)} select_data={update_modal_data} open_update_data_modal={open_update_modal} />

            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Link to="/stored-data" ><button type="button" className="btn btn-outline-success active btn-sm ">All</button></Link>
                <Link to="/stored-data-trash" ><button type="button" className="btn btn-outline-danger btn-sm ms-1">Trash</button></Link>

                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                                <div className="btn btn-info btn-sm " onClick={setStoreBtn}>Add store data</div>
                            </div>
                            <div className="card-body">
                                <div className="card-title text-center bg-warning py-2 rounded">All Data stored from the APK</div>

                                <DataTable_Component search="transaction_id" title_btn="Add stored Data" title={title} apidata={filter_apistate} columns={apicol} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}