import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import { Country, State, City } from "country-state-city";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_user() {
  const title = "Add Users";
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [tempstates, setTempstates] = useState([]);
  const [tempcities, setTempcities] = useState([]);
  const [tempselectedCountryCode, setTempselectedCountryCode] = useState("");
  const [tempselectedStateCode, setTempSelectedStateCode] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    avatar: null,
    status: "Active",
    id_client: [],
    joiningDate: null,
    coordinates: { latitude: "", longitude: "" },
    shift_hours: { check_in: "", check_out: "" },
    id_role: "",
    id_generalStaffType: "",
    phonenumber: null,
    name: "",
    bank_details: {
      account_number: "",
      bank_name: "",
      branch_name: "",
      ifsc_code: "",
      account_holder_name: "",
    },
    tempaddress: {
      address: "",
      city: "",
      state: "",
      country: "",
    },
    permanentaddress: {
      address: "",
      city: "",
      state: "",
      country: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [generalstafftypes, setGeneralstafftypes] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = Country.getAllCountries().map((country) => ({
        name: country.name,
        code: country.isoCode,
      }));
      setCountries(countryData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      const stateData = State.getStatesOfCountry(selectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setStates(stateData);
      setCities([]);
    }
  }, [selectedCountryCode]);

  useEffect(() => {
    if (selectedStateCode && selectedCountryCode) {
      const cityData = City.getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, selectedCountryCode]);

  useEffect(() => {
    if (tempselectedCountryCode) {
      const stateData = State.getStatesOfCountry(tempselectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setTempstates(stateData);
      setTempcities([]);
    }
  }, [tempselectedCountryCode]);

  useEffect(() => {
    if (tempselectedStateCode && tempselectedCountryCode) {
      const cityData = City.getCitiesOfState(
        tempselectedCountryCode,
        tempselectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setTempcities(cityData);
    }
  }, [tempselectedStateCode, tempselectedCountryCode]);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const validateForm = () => {
    const newErrors = {};
    if (!formData.phonenumber) {
      newErrors.phonenumber = "Phone Number is required.";
    }
    if (!formData.coordinates.latitude || !formData.coordinates.longitude)
      newErrors.coordinates = "Location is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value, type, files } = event.target;

    if (name === "latitude" || name === "longitude") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: {
          ...prevFormData.coordinates,
          [name]: value,
        },
      }));
    } else if (name === "tempcountry") {
      setTempselectedCountryCode(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        tempaddress: {
          ...prevFormData.tempaddress,
          country: value,
          state: "", // Reset state when country changes
          city: "", // Reset city when country changes
        },
      }));
    } else if (name === "tempstate") {
      setTempSelectedStateCode(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        tempaddress: {
          ...prevFormData.tempaddress,
          state: value,
          city: "", // Reset city when state changes
        },
      }));
    } else if (name === "tempcity") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        tempaddress: {
          ...prevFormData.tempaddress,
          city: value,
        },
      }));
    } else if (name === "tempaddress") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        tempaddress: {
          ...prevFormData.tempaddress,
          address: value,
        },
      }));
    } else if (name === "country") {
      setSelectedCountryCode(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        permanentaddress: {
          ...prevFormData.permanentaddress,
          country: value,
          state: "", // Reset state when country changes
          city: "", // Reset city when country changes
        },
      }));
    } else if (name === "state") {
      setSelectedStateCode(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        permanentaddress: {
          ...prevFormData.permanentaddress,
          state: value,
          city: "", // Reset city when state changes
        },
      }));
    } else if (name === "city") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        permanentaddress: {
          ...prevFormData.permanentaddress,
          city: value,
        },
      }));
    } else if (name === "address") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        permanentaddress: {
          ...prevFormData.permanentaddress,
          [name]: value,
        },
      }));
    } else if (
      name === "account_number" ||
      name === "bank_name" ||
      name === "branch_name" ||
      name === "ifsc_code" ||
      name === "account_holder_name"
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        bank_details: {
          ...prevFormData.bank_details,
          [name]: value,
        },
      }));
    } else if (name === "check_in" || name === "check_out") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        shift_hours: {
          ...prevFormData.shift_hours,
          [name]: value,
        },
      }));
    } else if (name === "avatar") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        avatar: files[0], // Store the file object
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "file" ? files[0] : value,
      }));
    }
  };

  const handleClientChange = (selectedOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_client: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: { latitude: lat, longitude: lng },
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("status", formData.status);
    formDataToSend.append("joiningDate", formData.joiningDate);
    formDataToSend.append(
      "bank_details[account_number]",
      formData.bank_details.account_number
    );
    formDataToSend.append(
      "bank_details[bank_name]",
      formData.bank_details.bank_name
    );
    formDataToSend.append(
      "bank_details[branch_name]",
      formData.bank_details.branch_name
    );
    formDataToSend.append(
      "bank_details[ifsc_code]",
      formData.bank_details.ifsc_code
    );
    formDataToSend.append(
      "bank_details[account_holder_name]",
      formData.bank_details.account_holder_name
    );
    formDataToSend.append("tempaddress[address]", formData.tempaddress.address);
    formDataToSend.append("tempaddress[country]", formData.tempaddress.country);
    formDataToSend.append("tempaddress[state]", formData.tempaddress.state);
    formDataToSend.append("tempaddress[city]", formData.tempaddress.city);

    formDataToSend.append(
      "permanentaddress[address]",
      formData.permanentaddress.address
    );
    formDataToSend.append(
      "permanentaddress[country]",
      formData.permanentaddress.country
    );
    formDataToSend.append(
      "permanentaddress[state]",
      formData.permanentaddress.state
    );
    formDataToSend.append(
      "permanentaddress[city]",
      formData.permanentaddress.city
    );

    formDataToSend.append(
      "coordinates[latitude]",
      formData.coordinates.latitude
    );
    formDataToSend.append(
      "coordinates[longitude]",
      formData.coordinates.longitude
    );
    formDataToSend.append(
      "shift_hours[check_in]",
      formData.shift_hours.check_in
    );
    formDataToSend.append(
      "shift_hours[check_out]",
      formData.shift_hours.check_out
    );
    formDataToSend.append("id_role", formData.id_role);
    formDataToSend.append("id_generalStaffType", formData.id_generalStaffType);
    formDataToSend.append("phonenumber", formData.phonenumber);
    formDataToSend.append("name", formData.name);

    if (formData.avatar) {
      formDataToSend.append("avatar", formData.avatar);
    }

    if (Array.isArray(formData.id_client)) {
      formData.id_client.forEach((clientId) => {
        formDataToSend.append("id_client[]", clientId);
      });
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/register`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      if (response.status === 201) {
        navigate("/user");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchGenralstafftypes = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff-types`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.generalStaffTypes) {
          setGeneralstafftypes(response.data.generalStaffTypes);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching staff types:", error);
      }
    };

    fetchGenralstafftypes();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/roles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRoles(response.data.roles);
        console.log("Roles fetched:", response.data.roles);
      } catch (error) {
        console.error("Error fetching role:", error);
        setRoles([]);
      }
    };
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.clients);
        setClients(response.data.clients);
        console.log("Clients fetched:", response.data.clients);
      } catch (error) {
        console.error("Error fetching client:", error);
        setClients([]);
      }
    };
    fetchClients();
  }, []);
  console.log(formData);
  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
          encType="multipart/form-data"
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="name">
                  <b>Name *</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  name="name"
                  onChange={onChangeHandler}
                  value={formData.name || ""}
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="email">
                  <b>Email </b>
                </label>
                <input
                  type="text"
                  placeholder=" Enter Your Email"
                  className="form-control"
                  name="email"
                  onChange={onChangeHandler}
                  value={formData.email || ""}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="password">
                  <b>Password </b>
                </label>
                <input
                  type="text"
                  placeholder="Enter your password here"
                  className="form-control"
                  name="password"
                  onChange={onChangeHandler}
                  value={formData.password || ""}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="workinghours">
                  <b>Working Hours </b>
                </label>
                <input
                  type="number"
                  placeholder="Enter Working Hours"
                  className="form-control"
                  name="workinghours"
                  onChange={onChangeHandler}
                  value={formData.workinghours || ""}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_role">
                  <b>Role *</b>
                </label>
                <select
                  name="id_role"
                  id="id_role"
                  className="form-control mt-2"
                  onChange={onChangeHandler}
                  value={formData.id_role}
                >
                  <option value="">Select Role</option>
                  {Array.isArray(roles) &&
                    roles.map((role) => (
                      <option key={role.id} value={role._id}>
                        {role.role_title}
                      </option>
                    ))}
                </select>
                {errors.role_title && (
                  <span className="text-danger">{errors.role_title}</span>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="coordinates">
                  <b>Coordinates *</b>
                </label>
                <input
                  type="text"
                  name="coordinates"
                  placeholder="Latitude, Longitude"
                  className="form-control"
                  value={`${formData.coordinates.latitude}, ${formData.coordinates.longitude}`}
                  readOnly
                />
                {errors.coordinates && (
                  <p className="text-danger">{errors.coordinates}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="">
                  <b>Location *</b>
                </label>
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_BASE_API}
                  libraries={["places"]}
                >
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      type="text"
                      placeholder="Search places..."
                      className="form-control"
                    />
                  </Autocomplete>
                </LoadScript>
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="joiningDate">
                  <b>Joining Date *</b>
                </label>
                <input
                  type="date"
                  name="joiningDate"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.joiningDate || ""}
                />
                {errors.joiningDate && (
                  <p className="text-danger">{errors.joiningDate}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_generalStaffType">
                  <b>Staff Type *</b>
                </label>
                <select
                  name="id_generalStaffType"
                  className="form-control"
                  value={formData.id_generalStaffType}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Staff Type</option>
                  {Array.isArray(generalstafftypes) &&
                    generalstafftypes.map((generalstafftype) => (
                      <option
                        key={generalstafftype._id}
                        value={generalstafftype._id}
                      >
                        {generalstafftype.name}
                      </option>
                    ))}
                </select>
                {errors.id_generalStaffType && (
                  <p className="text-danger">{errors.id_generalStaffType}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="bank_details.account_number">
                  <b>Account Number*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account_number"
                  onChange={onChangeHandler}
                  value={formData.bank_details.account_number}
                />
                {errors.account_number && (
                  <p className="text-danger">{errors.account_number}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="bank_details.bank_name">
                  <b>Bank Name*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="bank_name"
                  onChange={onChangeHandler}
                  value={formData.bank_details.bank_name}
                />
                {errors.bank_name && (
                  <p className="text-danger">{errors.bank_name}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="bank_details.branch_name">
                  <b>Branch Name*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="branch_name"
                  onChange={onChangeHandler}
                  value={formData.bank_details.branch_name}
                />
                {errors.branch_name && (
                  <p className="text-danger">{errors.branch_name}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="bank_details.ifsc_code">
                  <b>IFSC Code*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="ifsc_code"
                  onChange={onChangeHandler}
                  value={formData.bank_details.ifsc_code}
                />
                {errors.ifsc_code && (
                  <p className="text-danger">{errors.ifsc_code}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="bank_details.account_holder_name">
                  <b>Account Holder Name *</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account_holder_name"
                  onChange={onChangeHandler}
                  value={formData.bank_details.account_holder_name}
                />
                {errors.account_holder_name && (
                  <p className="text-danger">{errors.account_holder_name}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="tempaddress.address">
                  <b>Temporary Address*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="tempaddress"
                  onChange={onChangeHandler}
                  value={formData.tempaddress.address}
                />
                {/* {errors.address && (
                  <p className="text-danger">{errors.tempaddressaddress}</p>
                )} */}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="tempaddress.country">
                  <b>Temporary Country</b>
                </label>
                <select
                  name="tempcountry"
                  className="form-control"
                  value={formData.tempaddress.country || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="tempaddress.state">
                  <b>Temporary State</b>
                </label>
                <select
                  name="tempstate"
                  className="form-control"
                  value={formData.tempaddress.state || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select State</option>
                  {tempstates.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="tempaddress.city">
                  <b>Temporary City *</b>
                </label>
                <select
                  name="tempcity"
                  className="form-control"
                  value={formData.tempaddress.city || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select City</option>
                  {tempcities.map((city) => (
                    <option key={city.code} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {errors.city && (
                  <small className="text-danger">{errors.city}</small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="permanentaddress.address">
                  <b>Permanent Address*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  onChange={onChangeHandler}
                  value={formData.permanentaddress.address}
                />
                {errors.address && (
                  <p className="text-danger">{errors.address}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="permanentaddress.country">
                  <b>Permanent Country</b>
                </label>
                <select
                  name="country"
                  className="form-control"
                  value={formData.permanentaddress.country || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="permanentaddress.state">
                  <b>Permanent State</b>
                </label>
                <select
                  name="state"
                  className="form-control"
                  value={formData.permanentaddress.state || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="permanentaddress.city">
                  <b>Permanent City *</b>
                </label>
                <select
                  name="city"
                  className="form-control"
                  value={formData.permanentaddress.city || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.code} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {errors.city && (
                  <small className="text-danger">{errors.city}</small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="avatar">
                  <b>Avatar *</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="avatar"
                  onChange={onChangeHandler}
                />
                {errors.avatar && (
                  <p className="text-danger">{errors.avatar}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="phonenumber">
                  <b>Phone Number *</b>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="phonenumber"
                  onChange={onChangeHandler}
                  value={formData.phonenumber}
                />
                {errors.phonenumber && (
                  <p className="text-danger">{errors.phonenumber}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="shift_hours.check_in">
                  <b>Shift-in Time *</b>
                </label>
                <input
                  type="time"
                  className="form-control"
                  name="check_in"
                  onChange={onChangeHandler}
                  value={formData.shift_hours.check_in}
                />
                {errors.check_in && (
                  <p className="text-danger">{errors.check_in}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="shift_hours.check_out">
                  <b>Shift-out Time *</b>
                </label>
                <input
                  type="time"
                  className="form-control"
                  name="check_out"
                  onChange={onChangeHandler}
                  value={formData.shift_hours.check_out}
                />
                {errors.check_out && (
                  <p className="text-danger">{errors.check_out}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_client">
                  <b>Client </b>
                </label>
                <Select
                  name="id_client"
                  options={clients.map((client) => ({
                    value: client._id,
                    label: `${client.name}`,
                  }))}
                  isMulti
                  onChange={handleClientChange}
                  className={`basic-multi-select ${
                    errors.id_client ? "is-invalid" : ""
                  }`}
                  classNamePrefix="select"
                />
                {errors.id_client && (
                  <p className="text-danger">{errors.id_client}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b>User Status *</b>
                </label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status || "Active"}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                {errors.status && (
                  <p className="text-danger">{errors.status}</p>
                )}
              </div>
              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Users
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
