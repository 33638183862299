// import React, { useState, useEffect } from "react";
// import Layouts from "../../layouts/layouts";

// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
// import axios from "axios";

// const containerStyle = {
//   width: "100%",
//   height: "600px",
// };

// const center = {
//   lat: 30.7067034,
//   lng: 76.6877915,
// };

// function MyMapComponent() {
//   const [apistate, setApiState] = useState([]);
//   const [apicol, setApiCol] = useState([]);

//   return (
//     <>
//       <Layouts />
//       <LoadScript googleMapsApiKey={process.env.REACT_APP_BASE_API}>
//         <GoogleMap
//           mapContainerStyle={containerStyle}
//           center={center}
//           zoom={10}
//         ></GoogleMap>
//       </LoadScript>
//     </>
//   );
// }

// export default MyMapComponent;
import React, { useState, useEffect } from "react";
import Layouts from "../../layouts/layouts";
import { GoogleMap, LoadScript, Polyline } from "@react-google-maps/api";
import axios from "axios";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 30.7067034, // Replace with dynamic center based on fetched data
  lng: 76.6877915,
};

function MyMapComponent() {
  const [pathCoordinates, setPathCoordinates] = useState([]); // State to store the vehicle path coordinates
  const [loading, setLoading] = useState(true); // State to handle loading status

  // Function to fetch data from the Blackbox API
  const fetchVehicleData = async () => {
    try {
      const response = await axios.get(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/vehicleHistory/vehicle/${id}`, // Replace with actual vehicle ID and API endpoint
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include authorization token if needed
          },
        }
      );

      // Assuming the response contains an array of location points
      const locationData = response.data.locations;

      // Convert the location data into Google Maps-compatible coordinates
      const coordinates = locationData.map((location) => ({
        lat: location.latitude,
        lng: location.longitude,
      }));

      setPathCoordinates(coordinates); // Store the coordinates in state
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchVehicleData(); // Fetch vehicle data when the component mounts
  }, []);

  return (
    <>
      <Layouts />
      <LoadScript googleMapsApiKey={process.env.REACT_APP_BASE_API}>
        {" "}
        {/* Ensure you use the correct environment variable */}
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          {/* Render the Polyline if data is available */}
          {pathCoordinates.length > 0 && (
            <Polyline
              path={pathCoordinates}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
              }}
            />
          )}

          {/* Show loading message while fetching data */}
          {loading && <div>Loading vehicle data...</div>}
        </GoogleMap>
      </LoadScript>
    </>
  );
}

export default MyMapComponent;
