import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { State, City } from "country-state-city";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateGeneralStaff,
}) {
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [formData, setFormData] = useState(new FormData());
  const [errors, setErrors] = useState({});

  const countryCode = "IN";

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setSelectedUser(select_data.id_user || "");
      setSelectedStateCode(select_data.state || "");
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        //set here
        id_user: select_data.id_user._id,
      }));
    }
  }, [select_data, users]);

  useEffect(() => {
    const stateData = State.getStatesOfCountry(countryCode).map((state) => ({
      name: state.name,
      code: state.isoCode,
    }));
    setStates(stateData);
    setCities([]);
  }, [countryCode]);

  useEffect(() => {
    if (selectedStateCode) {
      const cityData = City.getCitiesOfState(
        countryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, countryCode]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "latitude" || name === "longitude") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        coordinates: { ...prevData.coordinates, [name]: value },
      }));
    } else if (name === "id_user") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: { ...prevData.id_user, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }

    if (name === "state") {
      setSelectedStateCode(value);
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => {
      const newFormData = new FormData();
      newFormData.append("governmentid", file);
      for (const key of prevFormData.keys()) {
        if (key !== "governmentid") {
          newFormData.append(key, prevFormData.get(key));
        }
      }
      return newFormData;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!update_modal_data.homenumber)
      newErrors.homenumber = "Home Number is required";
    if (!update_modal_data.joiningDate)
      newErrors.joiningDate = "Joining Date is required";
    if (!update_modal_data.tempaddress)
      newErrors.tempaddress = "Temporary Address is required";
    if (!update_modal_data.state) newErrors.state = "State is required";
    if (!update_modal_data.city) newErrors.city = "City is required";
    if (!update_modal_data.permanentaddress)
      newErrors.permanentaddress = "Permanent Address is required";
    if (!update_modal_data.perpacket)
      newErrors.perpacket = "Per Packet is required";
    if (!update_modal_data.id_user) newErrors.id_user = "User is required";
    if (!update_modal_data.salary) newErrors.salary = "Salary is required";
    if (!update_modal_data.description)
      newErrors.description = "Description is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Prepare FormData with all fields
          const formDataToSend = new FormData();

          Object.entries(update_modal_data).forEach(([key, value]) => {
            if (value) {
              formDataToSend.append(key, value);
            }
          });

          // Add the file to FormData if it exists
          const governmentIdFile = formData.get("governmentid");
          if (governmentIdFile) {
            formDataToSend.append("governmentid", governmentIdFile);
          }

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff/${_id}`,
            formDataToSend,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateGeneralStaff(update_modal_data);
            handleClose();
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating General Staff:", error);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update General Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Home Number *</Form.Label>
              <Form.Control
                type="text"
                name="homenumber"
                value={update_modal_data.homenumber || ""}
                onChange={handleChange}
              />
              {errors.homenumber && (
                <small className="text-danger">{errors.homenumber}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Joining Date *</Form.Label>
              <Form.Control
                type="date"
                name="joiningDate"
                value={update_modal_data.joiningDate || ""}
                onChange={handleChange}
              />
              {errors.joiningDate && (
                <small className="text-danger">{errors.joiningDate}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Temporary Address *</Form.Label>
              <Form.Control
                type="text"
                name="tempaddress"
                value={update_modal_data.tempaddress || ""}
                onChange={handleChange}
              />
              {errors.tempaddress && (
                <small className="text-danger">{errors.tempaddress}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State *</Form.Label>
              <Form.Control
                as="select"
                name="state"
                value={update_modal_data.state || ""}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
              {errors.state && (
                <small className="text-danger">{errors.state}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City *</Form.Label>
              <Form.Control
                as="select"
                name="city"
                value={update_modal_data.city || ""}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.code} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
              {errors.city && (
                <small className="text-danger">{errors.city}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Permanent Address *</Form.Label>
              <Form.Control
                type="text"
                name="permanentaddress"
                value={update_modal_data.permanentaddress || ""}
                onChange={handleChange}
              />
              {errors.permanentaddress && (
                <small className="text-danger">{errors.permanentaddress}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Per Packet *</Form.Label>
              <Form.Control
                type="number"
                name="perpacket"
                value={update_modal_data.perpacket || ""}
                onChange={handleChange}
              />
              {errors.perpacket && (
                <small className="text-danger">{errors.perpacket}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.email}
                  </option>
                ))}
              </Form.Control>
              {errors.id_user && (
                <small className="text-danger">{errors.id_user}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Salary *</Form.Label>
              <Form.Control
                type="text"
                name="salary"
                value={update_modal_data.salary || ""}
                onChange={handleChange}
              />
              {errors.salary && (
                <small className="text-danger">{errors.salary}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description *</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={update_modal_data.description || ""}
                onChange={handleChange}
              />
              {errors.description && (
                <small className="text-danger">{errors.description}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Government ID </Form.Label>
              <Form.Control
                type="file"
                name="governmentid"
                onChange={handleFileChange}
              />
            </Form.Group>
            {(update_modal_data.governmentid &&
              update_modal_data.governmentid[0]) ||
            select_data.governmentid?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.governmentid &&
                      update_modal_data.governmentid[0]
                        ? URL.createObjectURL(update_modal_data.governmentid[0])
                        : select_data.governmentid?.url
                    }
                    alt="Adhar Card Image"
                    style={{ maxWidth: "50%", height: "30%" }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update GeneralStaff
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
